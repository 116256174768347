import { DataCache, IDataCommand, injectDataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IObservableQueryDispatcher } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import {
  CourseData,
  LessonData,
  PublicationStatusEnum,
  UnitData,
} from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { findLessonSource, updateCourses } from './utils';
import { FindActiveLessonById } from '../queries/FindActiveLessonById';
import { FindDraftByLessonId } from '../queries/FindDraftByLesson';

function getChangedFields(original: LessonData, updated: LessonData): Partial<LessonData> {
  const changedFields: Partial<LessonData> = {};

  if (typeof updated.lessonId !== 'undefined') {
    changedFields.lessonId = updated.lessonId;
  }

  for (const key in updated) {
    if (
      updated.hasOwnProperty(key) &&
      original[key as keyof LessonData] !== updated[key as keyof LessonData]
    ) {
      changedFields[key as keyof LessonData] = updated[key as keyof LessonData] as any;
    }
  }

  return changedFields;
}

export class AddOrUpdateLesson implements IDataCommand<LessonData, LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
    @inject(ScriniumServices.QueryDispatcher) private readonly queries: IObservableQueryDispatcher,
  ) {}

  async execute(lesson: LessonData): Promise<LessonData> {
    let response: LessonData | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex } = findLessonSource(lesson, courses);
      if (courseIndex !== -1 && typeof unitIndex !== 'undefined') {
        if (lesson.lessonId) {
          const newUnits = [...(courses[courseIndex].units as UnitData[])];
          const newUnit = newUnits[unitIndex];

          // if lesson is published, explicitly create a draft
          if (lesson.status === PublicationStatusEnum.PUBLISHED && lesson.lessonId) {
            const currentLesson = await this.queries.execute(FindActiveLessonById, {
              unitId: lesson.unitId ?? 0,
              originalLessonId: lesson.originalLessonId,
            });
            const payload = getChangedFields(currentLesson ?? {}, lesson);
            const { data: draftLesson } = await this.api.createDraft(lesson.lessonId);
            const { data: updatedLesson } = await this.api.updateLesson({
              ...payload,
              lessonId: draftLesson.lessonId,
            });

            // updateLesson does not return contents or lesson, but draft does
            // set contents and quiz to draft contents and quiz
            updatedLesson.contents = draftLesson.contents;
            updatedLesson.quiz = draftLesson.quiz;
            // push updated draft into lesson list
            newUnit.lessons?.push(updatedLesson);
            response = updatedLesson;
          } else {
            const currentLesson = await this.queries.execute(FindDraftByLessonId, {
              unitId: lesson.unitId ?? 0,
              originalLessonId: lesson.originalLessonId,
            });
            const payload = getChangedFields(currentLesson ?? {}, lesson);
            const { data: updatedLesson } = await this.api.updateLesson(payload);
            updatedLesson.contents = lesson.contents;
            updatedLesson.quiz = lesson.quiz;
            const lessonIndex = newUnit.lessons?.findIndex(x => x.lessonId === lesson.lessonId);
            if (newUnit.lessons && typeof lessonIndex !== 'undefined' && lessonIndex !== -1) {
              const newLessons = [...newUnit.lessons];
              newLessons[lessonIndex] = updatedLesson;
              newUnit.lessons = newLessons;
            }
            response = updatedLesson;
          }

          const newCourses = updateCourses(newUnit, courses);

          return newCourses;
        }

        // remove position logic, allow backend to handle
        const course = courses[courseIndex];
        const units = course.units ?? [];
        const { data } = await this.api.createLesson(lesson);
        const newUnits = [...units];
        const newUnit = newUnits[unitIndex];
        const newLessons = [...(newUnit.lessons ?? []), data];
        newUnit.lessons = newLessons;
        response = data;

        const newCourses = updateCourses(newUnit, courses);
        return newCourses;
      }

      return courses;
    });

    return response as LessonData;
  }
}
