import { Button, Grid } from '@mui/material';
import { RightDrawer, Spacer, Row, Column } from '../../../components';
import OutstandingInvoice from './OutstandingInvoice';
import AutoPayDetails from './AutoPayDetails';
import SubscriptionDetails from './SubscriptionDetails';
import { PaymentMethodData } from '../../../api/apis/AdminPaymentApi';
import { LicenseAgreementData } from '../../../api/apis/LicenseAgreementApi';
import AutoPayAgreement from './AutoPayAgreement';
import { InvoiceData, InvoiceStatusEnum } from '../../../api/apis/InvoiceApi';
import { useEffect, useState } from 'react';

interface AutoPayDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invoices: InvoiceData[] | undefined;
  invoiceTotal: number | undefined;
  activeOrgPricebooks: LicenseAgreementData[] | undefined;
  defaultPaymentMethod: PaymentMethodData | undefined;
  handleSubmit: () => void;
}

const AutoPayDrawer: React.FC<AutoPayDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  invoices,
  invoiceTotal,
  activeOrgPricebooks,
  defaultPaymentMethod,
  handleSubmit,
}) => {
  const hasOutstanding =
    (invoices && invoices?.some(invoice => invoice.status === InvoiceStatusEnum.Open)) ?? false;
  const hasActivePricebooks = (activeOrgPricebooks?.length ?? 0) > 0;
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!drawerOpen) setChecked(false);
  }, [drawerOpen]);

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} title='Set up auto-pay?'>
      <Grid container flexDirection='column' margin='5px 0px 0px 10px'>
        <AutoPayDetails hasOutstanding={hasOutstanding} />
        <Spacer height='sm' />
        <Row style={{ justifyContent: 'center' }}>
          <Column style={{ alignItems: 'center' }}>
            {hasOutstanding && (
              <>
                <OutstandingInvoice invoiceTotal={invoiceTotal} />
                <Spacer height='md' />
              </>
            )}
            {hasActivePricebooks && (
              <>
                <SubscriptionDetails
                  activeOrgPricebooks={activeOrgPricebooks}
                  defaultPaymentMethod={defaultPaymentMethod}
                />
                <Spacer height='sm' />
              </>
            )}
            <AutoPayAgreement setChecked={setChecked} />
          </Column>
        </Row>
        <Spacer height='lg' />

        <Row style={{ justifyContent: 'space-between' }}>
          <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
            Close
          </Button>
          <Button color='primary' disabled={!checked} onClick={handleSubmit}>
            Set up auto-pay
          </Button>
        </Row>
      </Grid>
    </RightDrawer>
  );
};

export default AutoPayDrawer;
