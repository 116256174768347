import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Spacer, Row, Page } from '../../components';
import PayrollDeductionCard from '../organization/PayrollDeductionCard';
import { PlanData } from '../../models/PlanData';
import { TrustFamilyData } from '../../api/apis/TrustFamilyApi';
import { Typography, Grid } from '@mui/material';
import PlanTodoCard from './PlanTodoCard';

export type StepStatus = 'inactive' | 'active' | 'complete';

interface PlanTodoStep {
  title: string;
  icon: React.ReactNode;
  path: string;
  isComplete: () => boolean;
}

interface UnfinishedPlanProps {
  trustFamily?: TrustFamilyData;
  plan?: PlanData;
}

const UnfinishedPlan = ({ trustFamily, plan }: UnfinishedPlanProps) => {
  const navigate = useNavigate();

  const isInvestmentLineupComplete = () => {
    return trustFamily !== undefined;
  };

  const isPlanRulesComplete = () => {
    if (!plan) return false;

    const requiredFields = [
      plan.planName,
      plan.planType,
      plan.planSponsorName,
      plan.deferralChoiceAllowed,
    ];

    return requiredFields.every(field => field !== undefined);
  };

  const steps: PlanTodoStep[] = [
    {
      title: "Enter the plan's investment line-up",
      icon: <Svgs.BulletInvestment />,
      path: trustFamily ? '/plan-info/edit-investment-lineup' : '/plan-info/investment-lineup',
      isComplete: isInvestmentLineupComplete,
    },
    {
      title: "Enter the plan's rules",
      icon: <Svgs.BulletDocument />,
      path: '/plan-info/plan-basics',
      isComplete: isPlanRulesComplete,
    },
  ];

  const getStepStatus = (index: number): StepStatus => {
    const currentStep = steps[index];
    const previousStep = steps[index - 1];

    if (currentStep.isComplete()) {
      return 'complete';
    }

    if (previousStep?.isComplete()) {
      return 'active';
    }

    return index === 0 ? 'active' : 'inactive';
  };

  return (
    <Page title='Plan info'>
      <Spacer height='xxs' />
      <Row style={{ maxWidth: 895, justifyContent: 'space-between' }}>
        <Typography variant='p18Light' color='info.main'>
          Make it easier for your members to get advice! Enter your retirement plan information
          here.
        </Typography>
      </Row>
      <Spacer height='xs' />
      <Grid container spacing={5}>
        {steps.map((step, index) => {
          const status = getStepStatus(index);
          return (
            <PlanTodoCard
              key={step.title}
              title={step.title}
              icon={step.icon}
              status={status}
              stepNumber={index + 1}
              onClick={() => navigate(step.path)}
            />
          );
        })}
      </Grid>
      <Spacer height='xs' />
      <PayrollDeductionCard />
    </Page>
  );
};

export default UnfinishedPlan;
