import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { LessonData, PublicationStatusEnum, UnitData } from '../../../../models/CourseData';
import { Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { Spacer } from '../../../../components';
import UnitMenu from './UnitMenu';
import { DragDropContext } from '@hello-pangea/dnd';
import { useLoading } from '../../../../hooks/useLoading';
import { useCommands } from '../../../../helpers/useCommands';
import DraggableItemProps from '../../../../components/DraggableItemProps';
import UnitLessonList from './UnitLessonList';
import { UpdateLessonPosition } from '../../../../data/learning/commands/UpdateLessonPosition';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { PublicationStatusFilterOptions } from '../courses/CourseSummary';
import dayjs from 'dayjs';
import { SemiBold } from '../../../../components/SemiBold';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { FindLessonsByUnitId } from '../../../../data/learning/queries/FindLessonsByUnitId';

export interface UnitItemProps extends DraggableItemProps {
  unit: UnitData;
  originalCourseId: number;
  filter?: PublicationStatusFilterOptions;
}

const UnitItem = forwardRef<HTMLElement, UnitItemProps>(
  ({ unit, originalCourseId, filter, dragHandleProps, dragProps }, ref) => {
    const { setToast } = useContext(ToastbarContext);
    const { setLoading } = useLoading();
    const commands = useCommands();
    const lessons = useObservableQuery(FindLessonsByUnitId, unit.unitId);
    const [orderedLessons, setOrderedLessons] = useState<LessonData[]>([]);
    const disabled = useMemo(
      () => unit.sourceCourseId !== originalCourseId,
      [originalCourseId, unit.sourceCourseId],
    );
    const isPublished = unit.status === PublicationStatusEnum.PUBLISHED;

    useEffect(() => {
      if (lessons) {
        setOrderedLessons(lessons);
      }
    }, [lessons]);

    const dragEnded = async (param: any) => {
      const { source, destination } = param;
      const currentLessons = [...orderedLessons];

      try {
        setLoading(true);
        const newLessons = [...orderedLessons];
        // extracting the source item from the list
        const lesson = newLessons.splice(source.index, 1)[0];
        newLessons.splice(destination.index, 0, lesson);
        const sorted = newLessons.map((x, i) => ({ ...x, position: i + 1 }));
        setOrderedLessons(sorted);

        await commands.execute(UpdateLessonPosition, {
          lesson,
          orderedLessons: sorted,
        });
      } catch (err) {
        setToast(new Toast({ severity: 'error', open: true }));
        setOrderedLessons(currentLessons);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Grid ref={ref as any} {...dragProps}>
        <Card className={disabled ? 'disabled-menu' : ''} variant='ghost' color='primary'>
          <CardContent className='pad20'>
            <Grid display='flex' justifyContent='space-between' columnGap='20px'>
              <Grid display='flex' columnGap='20px' alignItems='center' minWidth={0}>
                <IconButton {...dragHandleProps} disableRipple>
                  <Svgs.IconGrip className='grip' style={{ opacity: '100 !important' }} />
                </IconButton>
                <Typography
                  variant='p24SemiBold'
                  color='secondary'
                  flexGrow={1}
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'>
                  {unit.name}
                </Typography>
                {isPublished && <Svgs.IconCheckmarkGrey style={{ flexShrink: 0 }} />}
              </Grid>
              <Grid display='flex' columnGap='20px' alignItems='center'>
                <Typography variant='p16' color='secondary'>
                  Unit{' '}
                  {isPublished ? (
                    <>
                      <SemiBold>published</SemiBold> on{' '}
                      {dayjs(unit?.publishedTimestamp).format('MM/DD/YY')}
                    </>
                  ) : (
                    'unpublished'
                  )}
                </Typography>

                <UnitMenu unit={unit} disabled={disabled} />
              </Grid>
            </Grid>
            <Spacer height='xs' />
            <DragDropContext onDragEnd={dragEnded}>
              <UnitLessonList
                unit={unit}
                lessons={orderedLessons}
                originalCourseId={originalCourseId}
                filter={filter}
              />
            </DragDropContext>
          </CardContent>
        </Card>
      </Grid>
    );
  },
);

export default UnitItem;
