import {
  TableCell,
  IconButton,
  Button,
  TableRow,
  Link,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { Svgs } from '../../assets/svg';
import Row from '../Row';
import Spacer from '../Spacer';
import { DataTableHeader, DataTableRowData, DataTableType, LastColumnElement } from './types';
import { isPrimitive } from '../../helpers/utilityFunctions';
import EllipsesMenu, { EllipsesItem } from '../EllipsesMenu';

interface DataTableRowProps {
  headers: DataTableHeader[];
  row: DataTableRowData;
  index: number;
  cellVariant?: 'outlined';
  type?: DataTableType;
  firstColumnStyles?: SxProps<Theme>;
  lastColumnStyles?: SxProps<Theme>;
  lastColumnElement?: LastColumnElement | ((row: any) => LastColumnElement);
  onDeleteClick?: (row: any) => void;
  onButton1Click?: (row: any) => void;
  onButton2Click?: (row: any) => void;
  button1Text?: string;
  button2Text?: string;
  button1Disabled?: boolean;
  button2Disabled?: boolean;
  action2?: (entry: any) => void;
  setActiveEntry?: (row: any) => void;
  setActiveEntry2?: (row: any) => void;
  atLeastOneRowIsDeletable: boolean;
  ellipsesItems?: EllipsesItem[];
}

export const DataTableRow = ({
  headers,
  row,
  index,
  cellVariant,
  type,
  firstColumnStyles,
  lastColumnStyles,
  lastColumnElement,
  onDeleteClick,
  onButton1Click,
  onButton2Click,
  button1Text,
  button2Text,
  button1Disabled,
  button2Disabled,
  action2,
  setActiveEntry,
  setActiveEntry2,
  atLeastOneRowIsDeletable,
  ellipsesItems,
}: DataTableRowProps) => {
  const elementType =
    typeof lastColumnElement === 'function' ? lastColumnElement(row) : lastColumnElement;

  const getLicenseArt = (entry: any) => {
    const productName = entry?.toString();
    if (productName.includes('GuidedChoice Advisory Service')) return <Svgs.PackageGcAdviceSmall />;
    if (productName.includes('3Nickels Advice')) return <Svgs.Package3nAdviceSmall />;
    if (productName.includes('3Nickels Free')) return <Svgs.Package3nFreeSmall />;
    if (productName.includes('Advice + 3NickelsU')) return <Svgs.Package3nUSmall />;
    return null;
  };

  return (
    <TableRow key={index}>
      {atLeastOneRowIsDeletable && (
        <TableCell
          sx={{ width: '50px', padding: row.deletableRow && !row.isDefault ? 0 : undefined }}>
          {row.deletableRow && !row.isDefault && (
            <IconButton color='primary' onClick={() => onDeleteClick?.(row)}>
              <Svgs.IconDelete />
            </IconButton>
          )}
        </TableCell>
      )}

      {Object.entries(row)
        .filter(([key]) => headers.some(header => header.label === key)) // only include fields that match column headers
        .map(([key, entry], j) => {
          if (typeof entry === 'boolean') return null;

          return (
            <TableCell key={j} variant={cellVariant} sx={j === 0 ? firstColumnStyles : undefined}>
              {j === 0 ? (
                <Row style={{ alignItems: 'center' }}>
                  {type === 'licenseAgreement' && action2 && setActiveEntry ? (
                    <>
                      {entry && getLicenseArt(entry)}
                      <Spacer width='xxxs' />
                      <Link
                        onClick={() => {
                          setActiveEntry(row);
                          action2(true);
                        }}>
                        {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                      </Link>
                    </>
                  ) : isPrimitive(entry) ? (
                    entry
                  ) : (
                    'Error Reading Table Entry'
                  )}
                </Row>
              ) : isPrimitive(entry) ? (
                entry
              ) : (
                'Error Reading Table Entry'
              )}
            </TableCell>
          );
        })}

      {elementType !== 'none' && (
        <TableCell variant='hasButton'>
          {row.isDefault ? (
            <Row style={{ display: 'inline-block', minWidth: '120px', justifySelf: 'flex-end' }}>
              <Row style={{ justifyContent: 'center' }}>
                <Typography variant='p16Bold' color='primary'>
                  Default
                </Typography>
              </Row>
            </Row>
          ) : (
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                ...lastColumnStyles,
              }}>
              {elementType === 'button' && (
                <>
                  {onButton1Click && button1Text && (
                    <Button
                      onClick={() => onButton1Click(row)}
                      disabled={button1Disabled}
                      sx={{ typography: { fontSize: 14 }, minWidth: 'fit-content' }}
                      variant='outlined'
                      color='primary'>
                      {button1Text}
                    </Button>
                  )}

                  {onButton1Click && onButton2Click && button2Text && <Spacer width='xxs' />}

                  {onButton2Click && button2Text && setActiveEntry2 && (
                    <Button
                      onClick={() => {
                        setActiveEntry2(row);
                        onButton2Click(true);
                      }}
                      disabled={button2Disabled}
                      sx={{ typography: { fontSize: 14 }, minWidth: 'fit-content' }}
                      color='primary'>
                      {button2Text}
                    </Button>
                  )}
                </>
              )}

              {elementType === 'ellipsis' && ellipsesItems && (
                <EllipsesMenu items={ellipsesItems} row={row} />
              )}
            </Row>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};
