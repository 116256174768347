import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonContent, UnitData } from '../../../models/CourseData';
import { findLessonContentSource, updateCourses } from './utils';
import { LessonContentApi } from '../../../api/apis/LessonContentApi';
import { LessonApi } from '../../../api/apis/LessonApi';

export class RemoveLessonContent implements IDataCommand<LessonContent, void> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.LessonContent) private readonly lessonContentApi: LessonContentApi,
    @inject(ApiKeys.Lesson) private readonly lessonApi: LessonApi,
  ) {}

  async execute(content: LessonContent): Promise<void> {
    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex, lessonIndex } = findLessonContentSource(content, courses);
      if (
        typeof courseIndex !== 'undefined' &&
        typeof unitIndex !== 'undefined' &&
        typeof lessonIndex !== 'undefined'
      ) {
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        if (newUnit.lessons) {
          const newLessons = [...newUnit.lessons];
          const newLesson = newLessons[lessonIndex];
          if (content.contentId && newLesson.lessonId) {
            await this.lessonContentApi.deleteLessonContent(content.contentId);
            const { data } = await this.lessonApi.getLesson(newLesson.lessonId);
            const updatedContents = newLesson.contents?.filter(
              x => x.contentId !== content.contentId,
            );
            newLesson.contents = updatedContents;
            newLesson.lastEditedTimestamp = data.lastEditedTimestamp;
            newLessons[lessonIndex] = newLesson;

            newUnit.lessons = newLessons;

            const newCourses = updateCourses(newUnit, courses);
            return newCourses;
          }
        }
      }
      return courses;
    });
  }
}
