import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { UserData, UserRole } from '../../models/UserData';
import { ApiServices } from '../ApiServices';
import { ApiKeys } from './ApiKeys';

export class UserApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<UserData>> {
    return this.axios.get('user');
  }

  getRole(): Promise<AxiosResponse<UserRole[]>> {
    return this.axios.get('user/role');
  }

  getOrgUsers(): Promise<AxiosResponse<UserData[]>> {
    return this.axios.get('user/orgId');
  }

  getTeamUsers(): Promise<AxiosResponse<UserData[]>> {
    return this.axios.get('user/userId-and-groupId-and-teamId');
  }

  getGroupUsers(): Promise<AxiosResponse<UserData[]>> {
    return this.axios.get('user/groupId');
  }

  addOrgUser(user: UserData): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post('user/email/org', user);
  }

  addTeamUser(user: UserData): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post('user/email/team', user);
  }

  addGroupUser(user: UserData): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post('user/email/group', user);
  }

  resendOrgEmail(id: number): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post(`user/resend-email/org?targetUserId=${id}`);
  }

  resendTeamEmail(id: number): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post(`user/resend-email/team?targetUserId=${id}`);
  }

  resendGroupEmail(id: number): Promise<AxiosResponse<UserData[]>> {
    return this.axios.post(`user/resend-email/group?targetUserId=${id}`);
  }

  removeOrgUser(userId: number): Promise<AxiosResponse> {
    return this.axios.delete(`user/${userId}/org`);
  }

  removeTeamUser(userId: number): Promise<AxiosResponse> {
    return this.axios.delete(`user/${userId}/team`);
  }

  removeGroupUser(userId: number): Promise<AxiosResponse> {
    return this.axios.delete(`user/${userId}/group`);
  }

  changePrimaryBillingUser(userId: number): Promise<AxiosResponse> {
    return this.axios.put(`stripe-customer/set-primary-customer/${userId}`);
  }
}

export interface IAuthUserApi {
  getUser(): Promise<AxiosResponse<UserData>>;
}

// a separate version of UserApi used exclusively in Auth Context to determine auth status
// uses a unique axios instance that has no interceptors attached
export class AuthUserApi {
  constructor(@inject(ApiKeys.AuthUserAxios) private readonly axios: AxiosInstance) {}

  getUser(): Promise<AxiosResponse<UserData>> {
    return this.axios.get<UserData>('user');
  }
}
