import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import ClickableList from '../../components/ClickableList';
import { UserData } from '../../models/UserData';
import _ from 'lodash';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { ToastbarContext } from '../../App';
import { ChangePrimaryBillingAdmin } from '../../data/customerOrganization/mutations';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { useLoading } from '../../hooks/useLoading';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { Toast } from '../../models/Toast';

interface BillingAdminManagerProps {
  admins: UserData[];
  currentUser: UserData | undefined;
}

export const BillingAdminManager: React.FC<BillingAdminManagerProps> = ({
  admins,
  currentUser,
}) => {
  const appStorage = useAppStorage();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const changePrimaryBillingAdmin = useMutation(new ChangePrimaryBillingAdmin());

  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const primaryBillingAdmin = useMemo(
    () => (admins.length > 0 ? admins.find(admin => admin.primaryBillingUser) : undefined),
    [admins],
  );

  const showLoading = useMemo(
    () => groupFeatureMapData?.organizationAdminView && admins.length <= 0,
    [groupFeatureMapData?.organizationAdminView, admins.length],
  );

  const showBillingAdmin = useMemo(
    () => primaryBillingAdmin && groupFeatureMapData?.organizationAdminView,
    [primaryBillingAdmin, groupFeatureMapData?.organizationAdminView],
  );

  const handleAdminSelect = async (userId: number): Promise<boolean> => {
    try {
      await changePrimaryBillingAdmin.action(userId);
      const updatedAdmin = admins.find(admin => admin.id === userId);

      if (!updatedAdmin) {
        throw new Error('Admin not found');
      }

      setIsVisible(false);
      setToast(
        new Toast({
          message: 'Billing admin updated!',
          severity: 'success',
          open: true,
          autoHideDuration: 2000,
        }),
      );

      return true;
    } catch (error) {
      setIsVisible(false);
      setToast(
        new Toast({
          message: 'Failed to update billing admin',
          severity: 'error',
          open: true,
          autoHideDuration: 2000,
        }),
      );

      return false;
    }
  };

  useEffect(() => {
    setLoading(showLoading ?? false);
  }, [showLoading, setLoading]);

  if (!showBillingAdmin) {
    return null;
  }

  return (
    <BillingAdminView
      primaryBillingAdmin={primaryBillingAdmin}
      admins={admins}
      currentUser={currentUser}
      isVisible={isVisible}
      onChangeClick={() => setIsVisible(true)}
      onClickOutside={() => setIsVisible(false)}
      onAdminSelect={handleAdminSelect}
    />
  );
};

interface BillingAdminViewProps {
  primaryBillingAdmin: UserData | undefined;
  admins: UserData[];
  currentUser: UserData | undefined;
  isVisible: boolean;
  onChangeClick: () => void;
  onClickOutside: () => void;
  onAdminSelect: (userId: number) => Promise<boolean>;
}

export const BillingAdminView = React.memo<BillingAdminViewProps>(
  ({
    primaryBillingAdmin,
    admins,
    currentUser,
    isVisible,
    onChangeClick,
    onClickOutside,
    onAdminSelect,
  }) => (
    <Grid container>
      <Typography variant='p16' color='secondary.main'>
        All billing communications are sent to {primaryBillingAdmin?.email}.&nbsp;&nbsp;
      </Typography>
      {admins.length > 1 && (
        <Tippy
          content={
            <Grid container>
              <Typography variant='p18SemiBold' color='secondary.dark'>
                Change billing admin
              </Typography>
              <ClickableList
                divider
                selectionIndicator='checkmark'
                items={admins}
                initialSelectedId={primaryBillingAdmin?.id}
                renderItem={admin =>
                  _.isEqual(admin, currentUser)
                    ? `${admin.firstName} ${admin.lastName} (You)`
                    : `${admin.firstName} ${admin.lastName}`
                }
                getItemKey={admin => admin?.id}
                onItemClick={onAdminSelect}
              />
            </Grid>
          }
          placement='bottom'
          arrow={false}
          trigger='click'
          interactive={true}
          visible={isVisible}
          onClickOutside={onClickOutside}
          theme='tippy-rounded'>
          <Link onClick={onChangeClick} variant='p16'>
            Change billing admin
          </Link>
        </Tippy>
      )}
    </Grid>
  ),
);
