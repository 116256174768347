import { Backdrop } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastbarContext } from '../App';
import { useService } from '@aesop-fables/containr-react';
import { useLoading } from '../hooks/useLoading';
import { Toast } from '../models/Toast';
import { authContextKey, IAuthenticationContext } from '../services/authentication';
import { CancellationPolicy, ChooseAccountType } from '../sign-up';
import AuthenticatedRoutes, { Loading } from './AuthenticatedRoutes';
import { useObservable } from '@aesop-fables/scrinium';
import { Main } from '../dashboard';
import AutoLogoutModal from '../services/logout/AutoLogoutModal';

function AppGateway() {
  const authContext = useService<IAuthenticationContext>(authContextKey);
  const authReady = useObservable(authContext.authReady$);
  const authStatus = useObservable(authContext.isAuthenticated$);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const { setToast } = useContext(ToastbarContext);
  const { loading } = useLoading();

  useEffect(() => {
    if (!authStatus && isAuthenticated) {
      setToast(
        new Toast({
          message: 'You have been logged out due to inactivity',
          severity: 'error',
          open: true,
        }),
      );
    }
    if (authStatus !== undefined) {
      setIsAuthenticated(authStatus);
    }
  }, [authStatus, isAuthenticated, setToast]);

  return (
    <>
      <Backdrop
        open={!authReady || loading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Loading />
      </Backdrop>
      {authStatus && <AutoLogoutModal />}
      {authReady &&
        typeof authStatus !== 'undefined' &&
        (authStatus ? (
          <AuthenticatedRoutes />
        ) : (
          <Routes>
            <Route path='/cancellation-policy' element={<CancellationPolicy />} />
            <Route path='/' element={<ChooseAccountType />} />
            <Route
              path='/*'
              element={typeof authStatus === 'undefined' ? <Main /> : <Navigate to='/' replace />}
            />
          </Routes>
        ))}
    </>
  );
}

export default AppGateway;
