import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Typography } from '@mui/material';
import { Column, HorizontalFormInput, Row, SecuritySearch, Spacer } from '../../../../components';
import { Form } from 'react-final-form';
import LineupTable from './LineupTable';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { TrustFamilyCompartments, trustFamilyStorageKey } from '../../../../data/trustFamily';
import { FundData, TrustFamilyData } from '../../../../api/apis/TrustFamilyApi';
import {
  SecuritiesApi,
  SecuritiesSearchRest,
  SecurityRest,
  SecurityTypeEnum,
} from '../../../../api/apis/SecuritiesApi';
import { ApiKeys } from '../../../../api/apis/ApiKeys';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { AddTrustFamily } from '../../../../data/trustFamily/mutations';
import { useLoading } from '../../../../hooks/useLoading';
import { AxiosError } from 'axios';
import { Svgs } from '../../../../assets/svg';

const InvestmentLineup = () => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  const appStorage = useAppStorage();
  const trustFamilyDataCache = appStorage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
  const trustFamilyData = useObservable(
    trustFamilyDataCache.observe$<TrustFamilyData[]>('trustFamilyData'),
  );
  const trustFamily = trustFamilyData?.at(0);
  const securitiesApi = useService<SecuritiesApi>(ApiKeys.Securities);
  const addTrustFamily = useMutation(new AddTrustFamily());

  const [selectedSecurities, setSelectedSecurities] = useState<Map<string, SecurityRest>>(
    new Map(),
  );
  const [message, setMessage] = useState<string[] | undefined>();

  useEffect(() => {
    if (trustFamily) {
      const fundMap = new Map();
      trustFamily.fundList.forEach((fund, index) => {
        const security = {
          id: index,
          ticker: fund.ticker,
          securityName: fund.name,
          securityType: SecurityTypeEnum['Mutual Fund'],
        };
        fundMap.set(security.id, security);
      });
      setSelectedSecurities(fundMap);
    }
  }, [trustFamily]);

  useEffect(() => {
    if (addTrustFamily.error) {
      const error = addTrustFamily.error as AxiosError;
      const errorMessage = (error.response?.data as Record<string, any>)?.message;
      const regex = /Asset class(.+?) is not represented|missing\s(.*?)(?=$|error|warning)/gi;
      let missingAssets = [];
      let match = regex.exec(errorMessage);
      while (match !== null) {
        if (match[1]) {
          missingAssets.push(match[1]);
          match = regex.exec(errorMessage);
        } else {
          missingAssets.push(match[2]);
          match = regex.exec(errorMessage);
        }
      }
      setMessage(missingAssets);
      setLoading(false);
      if (errorMessage.includes('name') && errorMessage.includes('already in use')) {
        setToast(
          new Toast({
            message: 'Line-up name is already taken. Please use a unique name.',
            severity: 'error',
            open: true,
            autoHideDuration: 3000,
          }),
        );
      } else {
        setToast(
          new Toast({
            message: 'Could not save investment line-up',
            severity: 'error',
            open: true,
            autoHideDuration: 3000,
          }),
        );
      }
    } else if (addTrustFamily.status === MutationStatus.Complete) {
      setLoading(false);
      navigate('/plan-info');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTrustFamily.error, addTrustFamily.status, navigate, setLoading]);

  const addSecurity = async (security: SecuritiesSearchRest) => {
    const { data } = await securitiesApi.findById(security.id);
    setSelectedSecurities(prevState => {
      if (prevState.has(data.ticker)) {
        setToast(
          new Toast({
            message: 'Investment already added',
            severity: 'error',
            open: true,
          }),
        );
        return prevState;
      }
      return new Map(prevState.set(data.ticker, data));
    });
  };

  const removeSecurity = async (row: FundData) => {
    setSelectedSecurities(prevState => {
      prevState.delete(row.ticker);
      return new Map(prevState);
    });
  };

  const navBack = () => {
    navigate(-1);
  };

  const onSubmit = async (values: any) => {
    if (!trustFamily) {
      setLoading(true);
      const now = new Date();
      now.setMinutes(now.getMinutes() + 5);
      await addTrustFamily.action({
        effectiveDate: now.toISOString(),
        trustFamilyName: values.lineupName,
        fundList: [...selectedSecurities.values()].map(security => {
          const fund: FundData = {
            ticker: security.ticker,
            name: security.securityName,
            expenseRatio: security.expenseRatio,
            fundPerformances: [],
          };
          return fund;
        }),
      });
    } else {
      navigate('/plan-info');
    }
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Enter your investment line-up
      </Typography>
      <Spacer height='xs' />

      <Typography variant='p18Light' color='info.main'>
        Tell us about the investment line-up available in this plan.
      </Typography>
      <Spacer height='xs' />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <>
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Typography variant='p18Light' color='secondary'>
                <HorizontalFormInput
                  label={trustFamily ? 'Line-up name' : 'New line-up name'}
                  fieldName='lineupName'
                  initialValue={trustFamily?.trustFamilyName}
                  maxLength={100}
                  inputStyle={{ flex: '3 0 10px' }}
                  readonly={trustFamily !== undefined}
                  rightAlignedKey
                  required
                />
              </Typography>
            </Card>
            <Spacer height='xxs' />

            {!trustFamily && (
              <Card
                sx={{ padding: 2, width: '100%', flexWrap: 'wrap' }}
                variant='ghost'
                color='primary'>
                <div style={{ alignItems: 'center', display: 'flex', width: '60%' }}>
                  <Typography
                    style={{ paddingRight: 20, flex: '2 0 10px', textAlign: 'right' }}
                    variant='p18SemiBold'
                    color='secondary'>
                    Add an investment
                  </Typography>
                  <SecuritySearch setSelectedSecurity={addSecurity} />
                </div>
              </Card>
            )}
            <Spacer height='xs' />

            {message?.map((assetClass, index) => (
              <>
                <Card
                  sx={{ padding: 2, width: '100%', flexWrap: 'wrap' }}
                  variant='ghost'
                  color='primary'>
                  <Row
                    style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                    <Svgs.IconWarning style={{ marginRight: 15 }} />
                    <Typography key={index} color='error.dark'>
                      Your line-up is missing the following asset class
                      {assetClass.includes('and') && 'es'}:{' '}
                      <Typography color='error.dark' variant='p16Bold'>
                        {assetClass}
                      </Typography>
                    </Typography>
                  </Row>
                </Card>
                <Spacer height='xs' />
              </>
            ))}

            <LineupTable
              editing={!trustFamily}
              data={[...selectedSecurities.values()].map(security => {
                return { name: security.securityName, ticker: security.ticker };
              })}
              remove={removeSecurity}
            />
            <Spacer height='sm' />

            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
                Cancel
              </Button>

              <Button
                className='xs'
                color='primary'
                disabled={!values.lineupName || selectedSecurities.size === 0}
                onClick={handleSubmit}>
                Save line-up
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Column>
  );
};

export default InvestmentLineup;
