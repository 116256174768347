import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, MenuItem, Typography } from '@mui/material';
import { Column, HorizontalFormInput, Row, Spacer, StepsHeader } from '../../../components';
import { Form } from 'react-final-form';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { PlanData } from '../../../models/PlanData';
import { formatDateYYYYMMDD } from '../../../helpers/utilityFunctions';
import { PlanWizard, planWizardKey } from '../../../services/plan';

const PlanBasics = () => {
  const navigate = useNavigate();

  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const planWizard = useService<PlanWizard>(planWizardKey);
  const planWizardState = useObservable(planWizard.state$);

  const [activeStep] = useState(1);
  const [totalSteps] = useState(3);

  const navBack = () => {
    navigate(-1);
  };

  const navToNext = async (values: any) => {
    planWizard.setState({
      planData: {
        ...planWizardState?.planData,
        ...values,
        planAnniversaryDate: new Date().toISOString(),
      },
    });
    navigate('/plan-info/employer-contributions');
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps='investmentLineup' />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Plan basics
      </Typography>
      <Spacer height='xs' />

      <Form onSubmit={navToNext}>
        {({ handleSubmit }) => (
          <>
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Typography variant='p18Light' color='secondary'>
                <HorizontalFormInput
                  label='Plan type'
                  fieldName='planType'
                  initialValue={planWizardState?.planData?.planType ?? plan?.planType}
                  inputStyle={{ flex: '3 0 10px' }}
                  rightAlignedKey
                  required
                  select>
                  <MenuItem key='401(a)' value='401(a)'>
                    401(a)
                  </MenuItem>
                  <MenuItem key='401(k)' value='401(k)'>
                    401(k)
                  </MenuItem>
                  <MenuItem key='403(b)' value='403(b)'>
                    403(b)
                  </MenuItem>
                  <MenuItem key='457' value='457'>
                    457
                  </MenuItem>
                </HorizontalFormInput>
                <Spacer height='xxs' />
                <HorizontalFormInput
                  label='Plan name'
                  fieldName='planName'
                  initialValue={planWizardState?.planData?.planName ?? plan?.planName}
                  maxLength={128}
                  inputStyle={{ flex: '3 0 10px' }}
                  rightAlignedKey
                  required
                />
                <Spacer height='xxs' />
                <HorizontalFormInput
                  label='Benefit name'
                  fieldName='employerContributionName'
                  initialValue={
                    planWizardState?.planData?.employerContributionName ??
                    plan?.employerContributionName
                  }
                  inputStyle={{ flex: '3 0 10px' }}
                  maxLength={64}
                  rightAlignedKey
                  required
                />
                <Spacer height='xxs' />
                <HorizontalFormInput
                  label='Recordkeeper plan ID'
                  fieldName='rkPlanId'
                  placeholder='Optional'
                  initialValue={planWizardState?.planData?.rkPlanId ?? plan?.rkPlanId}
                  inputStyle={{ flex: '3 0 10px' }}
                  helpText={<PlanBasicsHelpText />}
                  rightAlignedKey
                />
                <Spacer height='xxs' />
                <HorizontalFormInput
                  label='Date created'
                  fieldName='planAnniversaryDate'
                  type='date'
                  initialValue={formatDateYYYYMMDD(
                    planWizardState?.planData?.planAnniversaryDate ??
                      plan?.planAnniversaryDate ??
                      new Date().toISOString(),
                  )}
                  inputStyle={{ flex: '3 0 10px' }}
                  rightAlignedKey
                  readonly
                />
              </Typography>
            </Card>
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Next: Employer contributions
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Column>
  );
};

export const PlanBasicsHelpText = () => {
  return (
    <Column style={{ padding: '20px' }}>
      <Typography variant='p18Bold' color='secondary'>
        Recordkeeper plan ID
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p18Light' color='secondary'>
        This is a unique identifier for your plan in your recordkeeper’s system. If you don’t know
        it, we’ll generate one for you.
      </Typography>
    </Column>
  );
};

export default PlanBasics;
