import { Typography, Grid, Box, Card, Skeleton, useTheme } from '@mui/material';
import { useToDoList } from '../hooks/useToDoList';
import { ImageOutlined } from '@mui/icons-material';
import _ from 'lodash';

const LIST_ITEMS = 3;
const GRID_SIZE = 12 / LIST_ITEMS;

const TodoSkeleton = ({ index }: { index: number }) => {
  const theme = useTheme();

  return (
    <Grid item xs sm={GRID_SIZE}>
      <Card
        variant='ghost'
        color='primary'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 4,
          gap: 2,
          opacity: 0.8,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.disabled,
            opacity: 0.5,
            width: 50,
            height: 50,
          }}>
          <ImageOutlined sx={{ color: theme.palette.secondary.light, fontSize: 24 }} />
        </Box>
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography
            variant='p18'
            sx={{
              color: theme.palette.secondary.light,
              mb: 1,
            }}>
            Step {index + 1}
          </Typography>
          <Skeleton
            variant='rectangular'
            animation='wave'
            sx={{
              borderRadius: 1,
              backgroundColor: theme.palette.primary.disabled,
              opacity: 0.5,
              width: '90%',
              height: '28px',
            }}
          />
        </Box>
      </Card>
    </Grid>
  );
};

const CongratsTodoList = () => {
  const todos = useToDoList();

  if (!todos || todos.length === 0) {
    return (
      <Grid container spacing={5}>
        {_.times(LIST_ITEMS, index => (
          <TodoSkeleton key={index} index={index} />
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={5}>
      {todos.map(todo => (
        <Grid item xs sm={GRID_SIZE} key={todo.number}>
          <Card
            variant='ghost'
            color='primary'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              p: 4,
              gap: 2,
            }}>
            <Box>{todo.art && <todo.art />}</Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='p18' color='secondary.main' gutterBottom>
                Step {todo.number}
              </Typography>
              <Typography variant='p20Bold' color='secondary.main'>
                {todo.text}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CongratsTodoList;
