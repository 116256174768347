import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Row, Spacer } from '../../components';
import { Svgs } from '../../assets/svg';
import { logo3NSmall, logoGcSmall } from '../../assets/png';
import { PricebookBundle, ProductTypeEnum } from '../../models/PricebookData';

const CongratsLicenses = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state;
  const quantity = state.quantity ?? undefined;
  const chosenPackage: PricebookBundle = state.chosenPackage ?? undefined;
  const gcPackage = chosenPackage.pricebook.productType === ProductTypeEnum.GuidedChoice;
  const ourLogo = gcPackage ? logoGcSmall : logo3NSmall;
  const ourLogoAlt = gcPackage ? 'GuidedChoice Logo' : '3Nickels Logo';

  const navToNextStep = () => {
    if (quantity) {
      navigate(-1); // for purchase licenses flow
      return;
    }
    // for add a package flow
    if (location.state.origin === 'license') {
      navigate('/license-agreement');
      return;
    }
    navigate('/');
  };

  return (
    <Column style={{ margin: '15px 100px', alignItems: 'center' }}>
      <Row style={{ justifyContent: 'flex-end' }}>
        <img src={ourLogo} alt={ourLogoAlt} style={{ width: '30%' }} />
      </Row>
      <Spacer height='lg' />
      <Typography variant='p40Bold' color='secondary'>
        Congratulations!
      </Typography>
      <Spacer height='xs' />
      <Typography variant='p20' color='secondary.dark'>
        {quantity &&
          `Thank you for adding ${quantity} ${quantity === 1 ? 'license' : 'licenses'} of ${
            chosenPackage.pricebook.pricebookName
          }.`}
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='h2' color='info.main'>
        We have sent you a confirmation email.
      </Typography>
      <Spacer height='sm' />
      <Svgs.ArtThumbsUp />
      <Spacer height='lg' />
      <Button className='xs' onClick={navToNextStep} color='secondary' variant='outlined'>
        Close
      </Button>
    </Column>
  );
};

export default CongratsLicenses;
