import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

export interface ISelectedLessonContext {
  selectedLessonId?: number;
  selectLesson: (id?: number) => void;
}

const SelectedLessonContext = createContext<ISelectedLessonContext>({
  selectLesson: (id?: number) => {},
});

// basically just used to scroll to lesson when user navigates to CourseSummary from LessonSummary
export const SelectedLessonProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedLessonId, setSelectedLessonId] = useState<number | undefined>();

  const selectLesson = useCallback((id?: number) => {
    setSelectedLessonId(id);
  }, []);

  return (
    <SelectedLessonContext.Provider value={{ selectedLessonId, selectLesson }}>
      {children}
    </SelectedLessonContext.Provider>
  );
};

export function useSelectedLessonContext() {
  const context = useContext(SelectedLessonContext);

  if (!context) {
    throw new Error('useSelectedLessonContext must be used within SelectedLessonProvider');
  }

  return context;
}
