import { Route, RouteProps, Routes } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import { AboutYourOrg, ChooseProduct, License, Contract } from '../sign-up/index';

import * as Style from '../dashboard/main/style';
import AdpContract from '../sign-up/Adp/AdpContract';
import AdpOnboarding from '../sign-up/Adp/AdpOnboarding';
import { RestrictMemberAccess } from '../dashboard';
import InviteOtherAdmins from '../sign-up/InviteOtherAdmins';
import AddMembersBulk from '../dashboard/members/AddMembersBulk';
import { NavigatedFromBulkAddProvider } from '../hooks/useNavigatedFromBulkAdd';

const OnboardingRoutes: React.FC<RouteProps> = () => {
  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <TopNavBar hideProfile hideDashboard useDrillDownCrumbs />
        <Style.MainSection>
          <Style.MainContentRight>
            <NavigatedFromBulkAddProvider>
              <Routes>
                <Route path='/aboutYourOrg' element={<AboutYourOrg />} />
                <Route path='/adpContract' element={<AdpContract />} />
                <Route path='/adpDeductions' element={<AdpOnboarding />} />
                <Route path='/chooseProduct' element={<ChooseProduct />} />
                <Route path='/contract' element={<Contract />} />
                <Route path='/inviteOtherAdmins' element={<InviteOtherAdmins />} />
                <Route path='/license' element={<License />} />
                <Route path='/restrictMembers' element={<RestrictMemberAccess />} />
                <Route path='/restrictMembers/add' element={<AddMembersBulk />} />
              </Routes>
            </NavigatedFromBulkAddProvider>
          </Style.MainContentRight>
        </Style.MainSection>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

export default OnboardingRoutes;
