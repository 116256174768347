import { AdminCompartments, adminStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { UserApi } from '../../../api/apis/UserApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { UserData } from '../../../models/UserData';

export class AddOrgAdmin implements IMutation<UserData> {
  async execute(context: MutationContext<UserData>): Promise<void> {
    const { data, storage, container } = context;
    const userApi = container.get<UserApi>(ApiKeys.User);

    await userApi.addOrgUser(data);

    const dataCache = storage.retrieve<AdminCompartments>(adminStorageKey);
    dataCache.reload('orgAdmins');
  }
}

export class AddTeamAdmin implements IMutation<UserData> {
  async execute(context: MutationContext<UserData>): Promise<void> {
    const { data, storage, container } = context;
    const userApi = container.get<UserApi>(ApiKeys.User);

    await userApi.addTeamUser(data);

    const dataCache = storage.retrieve<AdminCompartments>(adminStorageKey);
    dataCache.reload('teamAdmins');
  }
}

export class AddGroupAdmin implements IMutation<UserData> {
  async execute(context: MutationContext<UserData>): Promise<void> {
    const { data, storage, container } = context;
    const userApi = container.get<UserApi>(ApiKeys.User);

    await userApi.addGroupUser(data);

    const dataCache = storage.retrieve<AdminCompartments>(adminStorageKey);
    dataCache.reload('groupAdmins');
  }
}
