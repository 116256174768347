import { useContext, useMemo } from 'react';
import { KeyValueLabel, RightDrawer, Row, Spacer } from '../../../../components';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { Form } from 'react-final-form';
import { Button } from '@mui/material';
import { LessonData } from '../../../../models/CourseData';
import { useLoading } from '../../../../hooks/useLoading';
import { useCommands } from '../../../../helpers/useCommands';
import { AddOrUpdateLesson } from '../../../../data/learning/commands/AddOrUpdateLesson';
import { AxiosError } from 'axios';
import { useTabContext } from '../../../../components/TabContext';

interface LessonEditDrawerProps {
  originalUnitId: number;
  lessonData?: LessonData;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LessonEditDrawer: React.FC<LessonEditDrawerProps> = ({
  originalUnitId,
  lessonData,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const { setActiveTab } = useTabContext();
  const commands = useCommands();
  const editing = useMemo(() => typeof lessonData !== 'undefined', [lessonData]);

  const onSubmit = async (values: Partial<LessonData>) => {
    try {
      setLoading(true);
      await commands.execute(AddOrUpdateLesson, {
        ...lessonData,
        ...values,
        unitId: originalUnitId,
      });
      setActiveTab(1);
      setDrawerOpen(false);
      setToast(
        new Toast({
          message: lessonData ? 'Lesson updated!' : 'Lesson created!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      const error = err as AxiosError;
      const uniqueNameViolation = (error.response?.data as Record<string, any>)?.message?.includes(
        'already exists',
      );
      const message = uniqueNameViolation && 'Please use a unique lesson name';
      setToast(new Toast({ message, severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <RightDrawer
      title={editing ? 'Rename Lesson' : 'Create Lesson'}
      open={drawerOpen}
      setOpen={setDrawerOpen}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Spacer height='sm' />
            <KeyValueLabel
              keyString='Lesson name'
              value={lessonData?.name}
              fieldName='name'
              editing
              required
              maxLength={100}
            />
            <Spacer height='lg' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                {editing ? 'Save lesson' : 'Create lesson'}
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default LessonEditDrawer;
