import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ToastbarContext } from '../../App';
import {
  Column,
  EditDrawer,
  HorizontalFormInput,
  KeyValueLabel,
  Row,
  Spacer,
} from '../../components';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Toast } from '../../models/Toast';
import { GroupCompartments, groupStorageKey } from '../../data/group';
import { GroupData } from '../../models/GroupData';
import { UpdateGroupData } from '../../data/group/mutations';
import { RoleInfoCard } from '../multitier/AdminInfo';

const AboutYourGroupCard: React.FC = () => {
  const appStorage = useAppStorage();
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const groupName = useObservable(groupDataCache.observe$<GroupData>('group'))?.name ?? '';
  const updateGroupData = useMutation(new UpdateGroupData());
  const { setToast } = useContext(ToastbarContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (
      updateGroupData?.error?.message.includes('415') ||
      updateGroupData?.error?.message.includes('500')
    ) {
      setToast(
        new Toast({
          message: 'Group name is already taken. Please use a unique name.',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } else if (updateGroupData.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          message: 'Group info updated!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    }
  }, [setToast, updateGroupData?.error?.message, updateGroupData.status]);

  const onSubmit = (values: GroupData) => {
    const { name } = values;
    try {
      updateGroupData.action({ name });
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
    setDrawerOpen(false);
  };

  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          About your group
        </Typography>
      </Row>
      <Spacer height='xs' />

      <RoleInfoCard setDrawerOpen={setDrawerOpen} editable={true}>
        <KeyValueLabel keyString='Name' value={groupName} />
        <EditDrawer
          title='Edit group'
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          onSubmit={onSubmit}>
          <Column
            style={{
              width: '90%',
            }}>
            <HorizontalFormInput<any>
              fieldName='name'
              label='Name'
              initialValue={groupName}
              labelStyle={{ flex: '2 0 10px', marginTop: '10px' }}
              inputStyle={{ flex: '3 0 10px' }}
              textSize='small'
              rightAlignedKey
              autoFocus
              required
            />
          </Column>
        </EditDrawer>
      </RoleInfoCard>
    </>
  );
};

export default AboutYourGroupCard;
