import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css'; // must import any theme you wish to use with Tippy
import 'tippy.js/dist/tippy.css'; // optional
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Button, Typography, useTheme } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { OffboardingWizard, offboardingWizardKey } from '../../services/offboarding';
import { Column, Row, Spacer, StepsHeader, UploadDropZone } from '../../components';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { SaveOrganizationLogo } from '../../data/organization/mutations';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { useLoading } from '../../hooks/useLoading';
import { useToDoList } from '../../hooks/useToDoList';
import { StepHeaderType } from '../../models/StepsHeaderData';

const UploadLogo = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();

  const [logo, setLogo] = useState<Blob | undefined>(undefined);
  const appStorage = useAppStorage();
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const current = location.pathname;
  const { activeStep, totalSteps } = offboardingWizard.getStepInfo(current);
  const saveOrganizationLogo = useMutation(new SaveOrganizationLogo());
  const { offboardingComplete } = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  ) ?? { offboardingComplete: false };

  const todos = useToDoList() ?? [];
  const headerSteps: StepHeaderType = todos.some(t => t.text.includes('Restrict'))
    ? 'defaultOffboarding'
    : 'memberPaysOffboarding';

  const navBack = () => {
    navigate('/');
  };

  const navToNext = async () => {
    setLoading(true);
    try {
      if (logo) {
        await saveOrganizationLogo.action(logo);
      }
    } catch (err) {
      console.error('error saving logo:', err);
      setLoading(false);
    }
    await updateSignUpState.action({
      lastCompletedStep: current,
      offboardingComplete: offboardingComplete || activeStep === totalSteps,
    });
    const next = offboardingWizard.nextStep(location);
    navigate(next.route);
    setLoading(false);
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps={headerSteps} />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Upload logo
      </Typography>
      <Spacer height='xs' />
      <Row style={{ alignItems: 'center' }}>
        <Typography variant='h2' color='info.main'>
          Upload a logo to personalize your experience. You can skip if you would like.&nbsp;
        </Typography>
        <Tippy placement='right' content={<LogoHelpText />} theme='tippy-rounded'>
          <HelpOutline style={{ color: theme.palette.info.main }} />
        </Tippy>
      </Row>
      <Spacer height='md' />
      <UploadDropZone setData={setLogo} uploadTypes={['logo']} />
      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
          {activeStep === 1 ? `Cancel` : `Back`}
        </Button>
        <Button className='sm' color='primary' onClick={navToNext}>
          Next
        </Button>
      </Row>
    </Column>
  );
};

export const LogoHelpText = () => {
  return (
    <Column>
      <Typography variant='p18Bold' color='secondary.dark' style={{ paddingTop: '10px' }}>
        Logo upload details
      </Typography>
      <Spacer height='xxs' />
      <Typography
        variant='h2'
        color='secondary.dark'
        style={{ paddingLeft: '25px', paddingBottom: '10px' }}>
        <li>Must be a PNG or JPG file</li>
        <li>Dimensions must be 100px (height) by 400px (width)</li>
      </Typography>
    </Column>
  );
};

export default UploadLogo;
