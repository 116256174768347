import { inject } from '@aesop-fables/containr';
import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { LicenseAgreementApi } from '../../../api/apis/LicenseAgreementApi';
import { PricebookCompartments, pricebookStorageKey } from '../../pricebook';
import { licenseStorageKey, LicenseCompartments } from '..';
import {
  licenseAgreementAcceptLogStorageKey,
  licenseAgreementAcceptLogCompartments,
} from '../../licenseAgreementAcceptLog';
import { organizationStorageKey, OrganizationCompartments } from '../../organization';
import { productTypeStorageKey, ProductTypeCompartments } from '../../productType';

export class InvalidateAgreement implements IDataCommand<number, void> {
  constructor(
    @inject(ApiKeys.LicenseAgreement) private readonly licenseAgreementApi: LicenseAgreementApi,
    @injectDataCache(pricebookStorageKey)
    private readonly pricebookDataCache: DataCache<PricebookCompartments>,
    @injectDataCache(productTypeStorageKey)
    private readonly productTypeDataCache: DataCache<ProductTypeCompartments>,
    @injectDataCache(organizationStorageKey)
    private readonly organizationDataCache: DataCache<OrganizationCompartments>,
    @injectDataCache(licenseAgreementAcceptLogStorageKey)
    private readonly licenseAgreementAcceptLogDataCache: DataCache<licenseAgreementAcceptLogCompartments>,
    @injectDataCache(licenseStorageKey)
    private readonly licenseDataCache: DataCache<LicenseCompartments>,
  ) {}

  async execute(targetLicenseId: number): Promise<void> {
    await this.licenseAgreementApi.invalidate(targetLicenseId);

    await Promise.all([
      this.licenseDataCache.reload('licenseAgreement'),
      this.pricebookDataCache.reload('pricebookSelections'),
      this.productTypeDataCache.reload('paidBy'),
      this.organizationDataCache.reload('allMetrics'),
      this.licenseAgreementAcceptLogDataCache.reload('licenseAgreementAcceptLog'),
    ]);
  }
}
