import { Button, Card, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { ApiKeys } from '../../../../api/apis/ApiKeys';
import {
  SecuritiesApi,
  SecurityRest,
  SecuritiesSearchRest,
  SecurityTypeEnum,
} from '../../../../api/apis/SecuritiesApi';
import { FundData, TrustFamilyData } from '../../../../api/apis/TrustFamilyApi';
import { ToastbarContext } from '../../../../App';
import {
  Column,
  HorizontalFormInput,
  Row,
  SecuritySearch,
  Spacer,
  ToggleInput,
} from '../../../../components';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { TrustFamilyCompartments, trustFamilyStorageKey } from '../../../../data/trustFamily';
import { EditTrustFamily } from '../../../../data/trustFamily/mutations';
import { formatDateMMDDYYYY, formatDateYYYYMMDD } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { Toast } from '../../../../models/Toast';
import LineupTable from './LineupTable';
import { AxiosError } from 'axios';
import { Svgs } from '../../../../assets/svg';

const EditInvestmentLineup: React.FC = () => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  const appStorage = useAppStorage();
  const trustFamilyDataCache = appStorage.retrieve<TrustFamilyCompartments>(trustFamilyStorageKey);
  const trustFamilyData = useObservable(
    trustFamilyDataCache.observe$<TrustFamilyData[]>('trustFamilyData'),
  );
  const securitiesApi = useService<SecuritiesApi>(ApiKeys.Securities);
  const editTrustFamily = useMutation(new EditTrustFamily());

  const [selectedSecurities, setSelectedSecurities] = useState<Map<string, SecurityRest>>(
    new Map(),
  );
  const [message, setMessage] = useState<string[] | undefined>();
  const trustFamily = trustFamilyData?.at(0);
  const changesMade = (trustFamilyData?.length ?? 0) > 1;
  const newestChanges = changesMade ? trustFamilyData?.slice(-1)[0] : undefined;
  const noncurrentChanges = newestChanges
    ? new Date(newestChanges?.effectiveDate) > new Date()
    : undefined;

  const items = [
    'Current line-up',
    `Effective ${newestChanges ? formatDateMMDDYYYY(newestChanges?.effectiveDate) : undefined}`,
  ];

  useEffect(() => {
    if (editTrustFamily.error) {
      const error = editTrustFamily.error as AxiosError;
      const errorMessage = (error.response?.data as Record<string, any>)?.message;
      const regex = /Asset class(.+?) is not represented|missing\s(.*?)(?=$|error|warning)/gi;
      let missingAssets = [];
      let match = regex.exec(errorMessage);
      while (match !== null) {
        if (match[1]) {
          missingAssets.push(match[1]);
          match = regex.exec(errorMessage);
        } else {
          missingAssets.push(match[2]);
          match = regex.exec(errorMessage);
        }
      }
      setMessage(missingAssets);
      setLoading(false);
      if (errorMessage.includes('name') && errorMessage.includes('already in use')) {
        setToast(
          new Toast({
            message: 'Line-up name is already taken. Please use a unique name.',
            severity: 'error',
            open: true,
            autoHideDuration: 3000,
          }),
        );
      } else {
        setToast(
          new Toast({
            message: 'Could not save investment line-up',
            severity: 'error',
            open: true,
            autoHideDuration: 3000,
          }),
        );
      }
    } else if (editTrustFamily.status === MutationStatus.Complete) {
      setLoading(false);
      setToast(
        new Toast({ message: 'Investment line-up updated!', severity: 'success', open: true }),
      );
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTrustFamily.error, editTrustFamily.status, navigate, setLoading]);

  useEffect(() => {
    if (noncurrentChanges) {
      const fundMap = new Map();
      newestChanges?.fundList.forEach((fund, index) => {
        const security = {
          id: index,
          ticker: fund.ticker,
          securityName: fund.name,
          securityType: SecurityTypeEnum['Mutual Fund'],
        };
        fundMap.set(security.ticker, security);
      });
      setSelectedSecurities(fundMap);
    } else if (trustFamily) {
      const fundMap = new Map();
      trustFamily.fundList.forEach((fund, index) => {
        const security = {
          id: index,
          ticker: fund.ticker,
          securityName: fund.name,
          securityType: SecurityTypeEnum['Mutual Fund'],
        };
        fundMap.set(security.ticker, security);
      });
      setSelectedSecurities(fundMap);
    }
  }, [newestChanges?.fundList, noncurrentChanges, trustFamily]);

  const validateEffectiveDate = (effectiveDate: string) => {
    const date = new Date(effectiveDate.replaceAll('-', '/'));
    const now = new Date();
    if (date.getDate() === now.getDate()) return undefined;
    return date < now ? 'Effective date cannot be in the past' : undefined;
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    const date = new Date(values.effectiveDate.replaceAll('-', '/'));
    const initialValue = newestChanges ? new Date(newestChanges.effectiveDate) : new Date();
    if (date.getDate() === initialValue?.getDate()) {
      date.setHours(initialValue.getHours());
      date.setMinutes(initialValue.getMinutes() + 5);
      date.setSeconds(initialValue.getSeconds());
    }
    date.toISOString();
    await editTrustFamily.action({
      effectiveDate: date,
      trustFamilyName: trustFamily?.trustFamilyName,
      fundList: [...selectedSecurities.values()].map(security => {
        const fund: FundData = {
          ticker: security.ticker,
          name: security.securityName,
          expenseRatio: security.expenseRatio,
          fundPerformances: [],
        };
        return fund;
      }),
    });
  };

  const navBack = () => {
    navigate(-1);
  };

  const addSecurity = async (security: SecuritiesSearchRest) => {
    const { data } = await securitiesApi.findById(security.id);
    setSelectedSecurities(prevState => {
      if (prevState.has(data.ticker)) {
        setToast(
          new Toast({
            message: 'Investment already added',
            severity: 'error',
            open: true,
          }),
        );
        return prevState;
      }
      return new Map(prevState.set(data.ticker, data));
    });
  };

  const removeSecurity = async (row: FundData) => {
    setSelectedSecurities(prevState => {
      prevState.delete(row.ticker);
      return new Map(prevState);
    });
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Edit your investment line-up
      </Typography>
      <Spacer height='xs' />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <>
            {noncurrentChanges && (
              <ToggleInput
                fullWidth
                buttonStyle={{ maxWidth: '200px', height: '45px' }}
                inputStyle={{ justifyContent: 'center' }}
                initialValue={items[1]}
                fieldName='lineupView'
                items={items}
              />
            )}
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Typography variant='p18Light' color='secondary'>
                <HorizontalFormInput
                  label={'Line-up name'}
                  fieldName='lineupName'
                  initialValue={
                    values.lineupView === 'Current line-up' || !values.lineupView
                      ? trustFamily?.trustFamilyName
                      : newestChanges?.trustFamilyName
                  }
                  inputStyle={{ flex: '3 0 10px' }}
                  rightAlignedKey
                  readonly
                />
                <Spacer height='xxs' />
                {values.lineupView !== 'Current line-up' && (
                  <HorizontalFormInput
                    label='When will these changes go into effect?'
                    fieldName='effectiveDate'
                    type='date'
                    initialValue={
                      newestChanges
                        ? formatDateYYYYMMDD(new Date(newestChanges.effectiveDate).toDateString())
                        : formatDateYYYYMMDD(new Date().toDateString())
                    }
                    validate={validateEffectiveDate}
                    inputStyle={{ flex: '3 0 10px' }}
                    rightAlignedKey
                    required
                  />
                )}
              </Typography>
            </Card>
            {values.lineupView !== 'Current line-up' && (
              <>
                <Spacer height='xxs' />
                <Card
                  sx={{ padding: 2, width: '100%', flexWrap: 'wrap' }}
                  variant='ghost'
                  color='primary'>
                  <div style={{ alignItems: 'center', display: 'flex', width: '60%' }}>
                    <Typography
                      style={{ paddingRight: 20, flex: '2 0 10px', textAlign: 'right' }}
                      variant='p18SemiBold'
                      color='secondary'>
                      Add an investment
                    </Typography>
                    <SecuritySearch setSelectedSecurity={addSecurity} />
                  </div>
                </Card>

                <Spacer height='xs' />
              </>
            )}

            {message?.map((assetClass, index) => (
              <>
                <Card
                  sx={{ padding: 2, width: '100%', flexWrap: 'wrap' }}
                  variant='ghost'
                  color='primary'>
                  <Row
                    style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                    <Svgs.IconWarning style={{ marginRight: 15 }} />
                    <Typography key={index} color='error.dark'>
                      Your line-up is missing the following asset class
                      {assetClass.includes('and') && 'es'}:{' '}
                      <Typography color='error.dark' variant='p16Bold'>
                        {assetClass}
                      </Typography>
                    </Typography>
                  </Row>
                </Card>
                <Spacer height='xs' />
              </>
            ))}

            <LineupTable
              data={
                values.lineupView !== 'Current line-up'
                  ? [...selectedSecurities.values()].map(security => {
                      return { name: security.securityName, ticker: security.ticker };
                    })
                  : trustFamily
                  ? trustFamily.fundList.map(security => {
                      return { ticker: security.ticker, name: security.name };
                    })
                  : []
              }
              editing={values.lineupView !== 'Current line-up'}
              remove={removeSecurity}
            />
            <Spacer height='sm' />

            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
                Cancel
              </Button>

              <Button
                className='xs'
                color='primary'
                disabled={!values.effectiveDate}
                onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Column>
  );
};

export default EditInvestmentLineup;
