import { useNavigate, useParams } from 'react-router-dom';
import { useObservableQuery } from '@aesop-fables/scrinium';
import { FindActiveLessonById } from '../../../../data/learning/queries/FindActiveLessonById';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../../../../components/Page';
import { TabPanel, Tabs } from '../../../../components/Tabs';
import LessonSummaryDetails from './LessonSummaryDetails';
import { FindDraftByLessonId } from '../../../../data/learning/queries/FindDraftByLesson';
import { FindUnitById } from '../../../../data/learning/queries/FindUnitById';
import { PublicationStatusEnum } from '../../../../models/CourseData';
import { Button, Grid, Typography } from '@mui/material';
import LessonSummaryHeader from './LessonSummaryHeader';
import { useLessonCategories } from '../../../../hooks/useLessonCategories';
import LessonCategories from './LessonCategories';
import { Svgs } from '../../../../assets/svg';
import { FindCourseById } from '../../../../data/learning/queries/FindCourseById';
import { useTabContext } from '../../../../components/TabContext';
import { useSelectedLessonContext } from './SelectedLessonContext';

interface LessonSummaryProps {}

const LessonSummary: React.FC<LessonSummaryProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { selectLesson } = useSelectedLessonContext();
  const categories = useLessonCategories();
  const { activeTab, setActiveTab } = useTabContext();
  const [initialRender, setInitialRender] = useState(true);

  const { courseId, unitId, originalLessonId } = useMemo(
    () => ({
      courseId: parseInt(params.courseId ?? ''),
      unitId: parseInt(params.unitId ?? ''),
      originalLessonId: parseInt(params.originalLessonId ?? ''),
    }),
    [params.courseId, params.originalLessonId, params.unitId],
  );
  const course = useObservableQuery(FindCourseById, courseId);
  const unit = useObservableQuery(FindUnitById, unitId);
  const lesson = useObservableQuery(FindActiveLessonById, { unitId, originalLessonId });
  const draft = useObservableQuery(FindDraftByLessonId, { unitId, originalLessonId });

  const activeLesson = useMemo(() => {
    return activeTab === 1 ? draft : lesson;
  }, [activeTab, draft, lesson]);

  const disabled = useMemo(
    () => activeLesson?.status === PublicationStatusEnum.PUBLISHED && typeof draft !== 'undefined',
    [draft, activeLesson?.status],
  );
  const linked = useMemo(() => unit?.sourceCourseId !== courseId, [courseId, unit?.sourceCourseId]);

  const tabLabels = useMemo(() => ['Published Version', 'Draft'], []);

  const isAllPublished = useMemo(
    () =>
      lesson?.status === PublicationStatusEnum.PUBLISHED &&
      unit?.status === PublicationStatusEnum.PUBLISHED &&
      course?.status === PublicationStatusEnum.PUBLISHED,
    [lesson?.status, unit?.status, course?.status],
  );

  useEffect(() => {
    if (typeof lesson === 'undefined' && typeof draft !== 'undefined') {
      setActiveTab(1);
      return;
    }

    if (typeof draft === 'undefined' && typeof lesson !== 'undefined') {
      setActiveTab(0);
      return;
    }

    if (typeof draft !== 'undefined') {
      if (initialRender) {
        setInitialRender(false);
      } else {
        setActiveTab(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson, draft]);

  const changeTabs = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
    },
    [setActiveTab],
  );

  return (
    <Page>
      <Grid display='flex' flexDirection='column' rowGap='20px'>
        <Grid display='flex' alignItems='center' columnGap='10px'>
          <Typography
            variant='p30'
            color='secondary'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'>
            {activeLesson?.name}
          </Typography>
          {isAllPublished && (
            <Svgs.IconCheckmarkGrey style={{ flexShrink: 0, marginRight: '20px' }} />
          )}
        </Grid>
        <Grid display='flex' justifyContent='space-between' flexGrow={1}>
          {typeof lesson !== 'undefined' && typeof draft !== 'undefined' && (
            <Tabs value={activeTab} className='bordered' labels={tabLabels} onChange={changeTabs} />
          )}
          <Grid display='flex' justifyContent='flex-end' flexGrow={1}>
            <LessonSummaryHeader
              lesson={activeLesson}
              draftWithPublished={
                activeLesson?.lessonId === draft?.lessonId && typeof lesson !== 'undefined'
              }
            />
          </Grid>
        </Grid>
        {categories && (
          <Grid container>
            <LessonCategories categories={categories} lesson={activeLesson} disabled={disabled} />
          </Grid>
        )}
      </Grid>
      <TabPanel value={activeTab} index={0}>
        <LessonSummaryDetails lesson={lesson} linked={linked} disabled={disabled} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <LessonSummaryDetails lesson={draft} linked={linked} />
      </TabPanel>
      <Button
        variant='outlined'
        color='secondary'
        style={{ marginTop: '30px', width: '150px' }}
        onClick={() => {
          selectLesson(lesson?.originalLessonId ?? draft?.originalLessonId);
          navigate(-1);
        }}>
        Back
      </Button>
    </Page>
  );
};

export default LessonSummary;
