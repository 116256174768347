import { DropdownMenu, KeyValueLabel, RightDrawer, Row, Spacer } from '../../components';
import { Form } from 'react-final-form';
import { Button } from '@mui/material';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { EditAMember as EditAMemberApi } from '../../data/members/mutations';
import React, { useContext, useEffect, useRef } from 'react';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { AdminStatusEnum } from '../../models/UserData';

interface Props {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  member: any;
}

const EditAMember: React.FC<Props> = props => {
  const {
    drawerOpen,
    setDrawerOpen,
    member: { firstName = '', lastName = '', email = '', status = null },
  } = props;
  const statusRef = useRef<any>(null);
  const { setToast } = useContext(ToastbarContext);
  const editAMember = useMutation(new EditAMemberApi());
  const statusInitialValue = status?.slice(0, status.indexOf(' ')).toUpperCase();

  useEffect(() => {
    if (editAMember.error) {
      setToast(
        new Toast({
          message: 'Could not save changes',
          severity: 'error',
          open: true,
        }),
      );
    } else if (editAMember.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          message: statusRef?.current === 'Not eligible' ? 'Member removed!' : 'Member info saved!',
          severity: 'success',
          open: true,
        }),
      );
    }
  }, [editAMember.error, editAMember.status, setToast]);

  const onSubmit = async (values: any) => {
    try {
      await editAMember.action({
        id: props.member.id,
        organizationId: props.member.organizationId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        status:
          statusRef?.current === 'Eligible'
            ? AdminStatusEnum.Active
            : statusRef?.current === 'Not eligible'
            ? AdminStatusEnum.Removed
            : statusInitialValue,
        statusTimestamp: props.member.statusTimestamp,
      });
      setDrawerOpen(false);
      setToast(new Toast({ message: 'Member removed!', severity: 'success', open: true }));
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} title={'Edit member'}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <KeyValueLabel
              keyString='First name'
              value={firstName}
              fieldName='firstName'
              editing
              autoFocus
            />
            <Spacer height='xxs' />
            <KeyValueLabel keyString='Last name' value={lastName} fieldName='lastName' editing />
            <Spacer height='xxs' />
            <KeyValueLabel keyString='Email' value={email} fieldName='email' editing />
            <Spacer height='xs' />
            <KeyValueLabel
              keyString='Status'
              value={
                <DropdownMenu
                  innerRef={statusRef}
                  callback={val => (statusRef.current = val)}
                  selected={
                    statusInitialValue.indexOf(AdminStatusEnum.Invited) !== -1 ||
                    statusInitialValue.indexOf(AdminStatusEnum.Active) !== -1
                      ? 'Eligible'
                      : 'Not eligible'
                  }
                  options={['Eligible', 'Not eligible']}></DropdownMenu>
              }
              fieldName='status'
            />

            <Spacer height='xxl' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default EditAMember;
