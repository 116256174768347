import { Button, Card, Typography } from '@mui/material';
import { Column, Row, Searchbox, Spacer } from '../../components';
import { useDeviceType } from '../../hooks/useDeviceType';

interface MembersDashboardHeaderProps {
  filteredMembersDataForTable: any[];
  setSearchWord: (value: React.SetStateAction<string>) => void;
  onBulkAdd: () => void;
  onBulkRemove: () => void;
  setAddMemberDrawer: (value: React.SetStateAction<boolean>) => void;
}

const MembersDashboardHeader: React.FC<MembersDashboardHeaderProps> = ({
  onBulkAdd,
  setAddMemberDrawer,
  onBulkRemove,
  filteredMembersDataForTable,
  setSearchWord,
}) => {
  const { isMobile, isTablet } = useDeviceType();

  return (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
        }}>
        <Typography variant='p20' color='secondary.dark'>
          Add and manage members individually or by uploading a bulk file.
        </Typography>
      </Row>
      <Spacer height='xs' />
      {isMobile || isTablet ? (
        <Column style={{ alignItems: 'space-between' }}>
          <Button
            onClick={() => setAddMemberDrawer(true)}
            color='primary'
            style={{ width: '100%' }}>
            Add a member
          </Button>
          <Spacer height='xxs' />
          <Button
            onClick={onBulkAdd}
            style={{ marginRight: '20px', width: '100%' }}
            color='secondary'>
            Bulk add
          </Button>
          <Spacer height='xxs' />
          {filteredMembersDataForTable.length > 0 && (
            <Button
              onClick={onBulkRemove}
              variant='outlined'
              color='secondary'
              style={{ width: '100%' }}>
              Bulk remove
            </Button>
          )}
        </Column>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          {filteredMembersDataForTable.length > 0 ? (
            <Button
              onClick={onBulkRemove}
              variant='outlined'
              color='secondary'
              style={{ width: '185px' }}>
              Bulk remove
            </Button>
          ) : (
            <div />
          )}
          <Row>
            <Button
              onClick={onBulkAdd}
              style={{ marginRight: '20px', width: '185px' }}
              color='secondary'>
              Bulk add
            </Button>
            <Button
              onClick={() => setAddMemberDrawer(true)}
              color='primary'
              style={{ width: '185px' }}>
              Add a member
            </Button>
          </Row>
        </Row>
      )}
      <Spacer height='sm' />
      <Card sx={{ padding: 5 }} variant='ghost' color='primary'>
        <Column>
          <Searchbox
            callback={value => setSearchWord(value)}
            label='Search'
            placeholder='Search by name or email'
          />
        </Column>
      </Card>
      <Spacer height='xs' />
    </>
  );
};

export default MembersDashboardHeader;
