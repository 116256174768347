import { Typography, Button } from '@mui/material';
import React from 'react';
import { Row, Spacer, DataTable } from '../../../components';
import { convertToTitleCase } from '../../../helpers/utilityFunctions';
import { PaymentMethodData, StripeSessionStatus } from '../../../api/apis/AdminPaymentApi';

const methodsHeaders = [
  {
    title: 'Name',
    label: 'name',
  },
  {
    title: 'Type',
    label: 'type',
  },
];

interface PaymentMethodsProps {
  paymentMethods?: PaymentMethodData[];
  defaultPaymentMethodId?: string;
  stripeSessionStatus?: StripeSessionStatus;
  onAddPayment: () => Promise<void>;
  onSetAsDefault: (row: any) => Promise<void>;
  onDelete: (row: any) => Promise<void>;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  paymentMethods,
  defaultPaymentMethodId,
  stripeSessionStatus,
  onAddPayment,
  onSetAsDefault,
  onDelete,
}) => {
  return (
    <>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Payment methods
        </Typography>
        {!stripeSessionStatus && (
          <Button onClick={onAddPayment} color='primary' style={{ fontSize: 14 }}>
            Add a payment method
          </Button>
        )}
      </Row>
      <Spacer height='xxs' />
      <DataTable
        headers={methodsHeaders}
        sortBy='name'
        data={
          paymentMethods?.map(({ id, type, lastDigits }) => ({
            id,
            name: lastDigits
              ? `${convertToTitleCase(type)} ending in ${lastDigits}`
              : convertToTitleCase(type),
            type: convertToTitleCase(type),
            isDefault: id === defaultPaymentMethodId,
            isPinned: id === defaultPaymentMethodId,
            deletableRow: true,
          })) ?? []
        }
        button1Text={'Set as default'}
        onButton1Click={onSetAsDefault}
        onDelete={onDelete}
        type={'paymentMethod'}
        emptyStateText={'You do not have any saved payment methods.'}
      />
    </>
  );
};

export default PaymentMethods;
