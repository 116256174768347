import {
  ConfiguredDataSource,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { LicenseAgreementApi, LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';

export interface LicenseCompartments {
  licenseAgreement: DataCompartmentOptions<LicenseAgreementData[] | undefined>;
}

export const licenseStorageKey = 'data/license';

export const withLicenseData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<LicenseCompartments>({
      licenseAgreement: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(OrganizationSelected),
        },
        source: new ConfiguredDataSource(async () => {
          const licenseAgreementApi = container.get<LicenseAgreementApi>(ApiKeys.LicenseAgreement);
          const { data } = await licenseAgreementApi.getAllDocs();
          return data;
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(licenseStorageKey, cache);
  },
);
