import { Route, RouteProps, Routes } from 'react-router-dom';
import CourseSummary from '../dashboard/profile/learning/courses/CourseSummary';
import Courses from '../dashboard/profile/learning/courses/Courses';
import LessonSummary from '../dashboard/profile/learning/lessons/LessonSummary';
import ContentEditView from '../dashboard/profile/learning/lessons/contents/ContentEditView';
import QuizEditView from '../dashboard/profile/learning/lessons/quiz/QuizEditView';
import CourseEditView from '../dashboard/profile/learning/courses/CourseEditView';
import UnitEditView from '../dashboard/profile/learning/units/UnitEditView';
import { TabProvider } from '../components/TabContext';
import { SelectedLessonProvider } from '../dashboard/profile/learning/lessons/SelectedLessonContext';

// courseId and unitId are both original ids
const LearningRoutes: React.FC<RouteProps> = () => {
  return (
    <Routes>
      <Route path='/' element={<Courses />} />
      <Route path='/new' element={<CourseEditView />} />
      <Route
        path='/:courseId/*'
        element={
          <SelectedLessonProvider>
            <Routes>
              <Route index element={<CourseSummary />} />
              <Route path='edit' element={<CourseEditView />} />
              <Route path='unit/new' element={<UnitEditView />} />
              <Route path='unit/:unitId/edit' element={<UnitEditView />} />
              <Route
                path='unit/:unitId/lesson/:originalLessonId/*'
                element={
                  <TabProvider>
                    <Routes>
                      <Route index element={<LessonSummary />} />
                      <Route path='content' element={<ContentEditView />} />
                      <Route path='content/edit' element={<ContentEditView editing />} />
                      <Route path='quiz' element={<QuizEditView />} />
                      <Route path='quiz/edit' element={<QuizEditView editing />} />
                    </Routes>
                  </TabProvider>
                }
              />
            </Routes>
          </SelectedLessonProvider>
        }
      />
    </Routes>
  );
};

export default LearningRoutes;
