import { Scopes, createServiceModule } from '@aesop-fables/containr';
import { OffboardingTemplateSubject } from './subjects/OffboardingTemplateSubject';

export const OffboardingServices = {
  TodoList: 'offboarding/subjects/todoList',
} as const;

export const useOffboardingServiceModule = createServiceModule('offboarding/services', services => {
  services.autoResolve(OffboardingServices.TodoList, OffboardingTemplateSubject, Scopes.Transient);
});
