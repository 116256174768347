import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { UserApi } from '../../../api/apis/UserApi';
import { IDataCommand } from '@aesop-fables/scrinium';
import { UserData } from '../../../models/UserData';
import { AxiosResponse } from 'axios';

export class ResendOrgEmail implements IDataCommand<number, AxiosResponse<UserData[], any>> {
  constructor(@inject(ApiKeys.User) private readonly api: UserApi) {}

  async execute(targetUserId: number): Promise<AxiosResponse<UserData[], any>> {
    return await this.api.resendOrgEmail(targetUserId);
  }
}

export class ResendTeamEmail implements IDataCommand<number, AxiosResponse<UserData[], any>> {
  constructor(@inject(ApiKeys.User) private readonly api: UserApi) {}

  async execute(targetUserId: number): Promise<AxiosResponse<UserData[], any>> {
    return await this.api.resendTeamEmail(targetUserId);
  }
}

export class ResendGroupEmail implements IDataCommand<number, AxiosResponse<UserData[], any>> {
  constructor(@inject(ApiKeys.User) private readonly api: UserApi) {}

  async execute(targetUserId: number): Promise<AxiosResponse<UserData[], any>> {
    return await this.api.resendGroupEmail(targetUserId);
  }
}
