export type StepHeaderType =
  | 'onboarding'
  | 'onboardingMultipleProducts'
  | 'adpOnboarding'
  | 'defaultOffboarding'
  | 'memberPaysOffboarding'
  | 'addPackage'
  | 'investmentLineup';

export const StepSequences: Record<StepHeaderType, string[]> = {
  onboarding: ['About your org', 'Admins', 'Licenses', 'Contract'],
  onboardingMultipleProducts: ['About your org', 'Admins', 'Product', 'Licenses', 'Contract'],
  adpOnboarding: ['Deductions', 'Contract'],
  defaultOffboarding: ['Logo', 'Member access', 'Custom URL'],
  memberPaysOffboarding: ['Logo', 'Member access', 'Custom URL'],
  addPackage: ['Package', 'Contract'],
  investmentLineup: ['Plan basics', 'Employer contributions', 'Member contributions'],
};
