import { Typography, TypographyProps, useTheme } from '@mui/material';
import React, { CSSProperties, useRef, useState } from 'react';
import { FormInput, PasswordInput, Row, Spacer } from '.';
import { formatPhoneNumber } from '../helpers/utilityFunctions';

interface Props extends React.PropsWithChildren {
  keyString?: string;
  value?: string | React.ReactComponentElement<any>;
  editing?: boolean;
  fieldName?: string;
  type?: 'text' | 'password';
  valueIsLink?: boolean;
  required?: boolean;
  containerStyles?: CSSProperties;
  keyStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  valueStyles?: CSSProperties;
  variant?: TypographyProps['variant'];
  layout?: 'horizontal' | 'stacked';
  maxLength?: number;
  autoFocus?: boolean;
}

const KeyValueLabel: React.FC<Props> = props => {
  const theme = useTheme();
  const inputRef = useRef<HTMLElement>(null);
  const {
    keyString,
    value,
    editing,
    fieldName,
    valueIsLink,
    required,
    containerStyles,
    inputStyles,
    valueStyles,
    keyStyles,
    variant,
    layout = 'horizontal',
    maxLength,
    autoFocus,
  } = props;
  const isStacked = layout === 'stacked';
  const baseContainerStyles: CSSProperties = {
    flexWrap: 'wrap',
    gap: isStacked ? '5px' : '17px',
    justifyContent: isStacked ? 'flex-start' : 'flex-end',
    alignItems: isStacked ? 'flex-start' : 'baseline',
    flexDirection: isStacked ? 'column' : 'row',
    ...containerStyles,
  };
  const baseKeyStyles: CSSProperties = {
    flex: isStacked ? 'unset' : '2 0 10px',
    textAlign: isStacked ? 'left' : 'right',
    ...(editing ? { marginTop: '10px' } : { alignSelf: 'baseline' }),
    ...keyStyles,
  };
  const baseValueStyles: CSSProperties = {
    flex: isStacked ? 'unset' : '3 0 10px',
    wordWrap: 'break-word',
    maxWidth: '58.5%',
    ...(valueIsLink ? { textDecoration: 'underline', cursor: 'pointer' } : {}),
    ...valueStyles,
  };
  const baseInputStyles: CSSProperties = {
    flex: isStacked ? 'unset' : '3 0 10px',
    ...inputStyles,
  };

  const [formattedPhone, setFormattedPhone] = useState<string>();

  const validatePhoneNumber = (value: string) => {
    const result = formatPhoneNumber(value);
    setFormattedPhone(result);

    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
    return regex.test(value) ? undefined : 'Invalid Phone Number';
  };

  return (
    <>
      <Row style={baseContainerStyles}>
        <Typography style={baseKeyStyles} variant='p14' color='info.main'>
          {keyString}
        </Typography>

        {editing ? (
          props.type === 'password' ? (
            <PasswordInput
              style={baseInputStyles}
              label={''}
              amount={''}
              weight={''}
              weightRange={''}
              innerStyle={{ margin: 0 }}
            />
          ) : props.fieldName === 'phoneNumber' ? (
            <FormInput
              formattedValue={formattedPhone}
              validate={validatePhoneNumber}
              maxLength={14}
              inputStyle={baseInputStyles}
              fieldName={fieldName ?? ''}
              initialValue={typeof value === 'string' ? value : ''}
              type={props.type ?? 'text'}
              inputRef={inputRef}
              autoFocus={autoFocus}
              required
            />
          ) : (
            <FormInput
              inputStyle={baseInputStyles}
              fieldName={fieldName ?? ''}
              initialValue={typeof value === 'string' ? value : ''}
              type={props.type ?? 'text'}
              required={required ? required : false}
              maxLength={maxLength}
              autoFocus={autoFocus}
              inputRef={inputRef}
            />
          )
        ) : (
          <Typography
            style={baseValueStyles}
            variant={variant ?? 'p14'}
            color={valueIsLink ? undefined : `secondary.dark`}>
            {valueIsLink ? (
              <a
                style={{ color: theme.palette.primary.main }}
                href={value?.toString()}
                target='_blank'
                rel='noreferrer'>
                {value}
              </a>
            ) : (
              value
            )}
          </Typography>
        )}
      </Row>
      <Spacer height='xxs' />
    </>
  );
};

export default KeyValueLabel;
