import { inject } from '@aesop-fables/containr';
import { IDataCommand } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { InvoiceApi } from '../../../api/apis/InvoiceApi';

export interface GetInvoiceTotalParams {}

export class GetInvoiceTotal implements IDataCommand<void, number> {
  constructor(@inject(ApiKeys.Invoice) private readonly api: InvoiceApi) {}

  async execute(): Promise<number> {
    const { data } = await this.api.getInvoiceTotal();
    return data;
  }
}
