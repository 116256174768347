import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class OrgPricebookLicenseMetrics {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<OrganizationMetricsData[]>> {
    return this.axios.get(`organization-pricebook-license-metrics`);
  }
}

export interface OrganizationMetricsData {
  organizationId: number;
  organizationName: string;
  totalLicenseAgreements: number;
  activeUsers: number;
  pricebookLicenseMetricsList: LicenseMetricsData[];
}

export interface LicenseMetricsData {
  pricebookId: number;
  activeLicenses: number;
  purchasedLicenses: number;
  availableLicenses: number;
  pricebookName: string;
  artUrl: string;
  paidBy: string;
  paymentType: string;
}
