import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class ChargeApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<ChargeData[]>> {
    return this.axios.get('charge/organization');
  }

  getReceiptUrl(chargeId: number): Promise<AxiosResponse<string>> {
    return this.axios.get(`charge/receipt?chargeId=${chargeId}`);
  }
}

export interface ChargeData {
  charge: Charge;
  paymentInfo: PaymentInfo;
}

interface Charge {
  id: number;
  stripeId: string;
  amountPaid: number;
  description: string;
  invoiceId: string;
  name: string;
  email: string;
  receiptUrl: string;
  datePaid: string;
  clearedAt: string;
  cleared: boolean;
  subscriptionId: number;
  checkoutId: number;
}

interface PaymentInfo {
  id: number;
  chargeId: number;
  paymentMethod: string;
  last4: string;
  zipCode: string;
  country: string;
}
