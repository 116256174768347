import { IListener, injectDataCache, injectSubject } from '@aesop-fables/scrinium';
import type { DataCache } from '@aesop-fables/scrinium';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';
import { Predicates } from '../../../predicates/Types';
import { PlanCompartments, planStorageKey } from '..';

export class PlanListener implements IListener {
  private initialized = false;
  constructor(
    @injectSubject(Predicates.OrganizationSelected)
    private readonly predicate: Observable<boolean>,
    @injectDataCache(planStorageKey)
    private readonly cache: DataCache<PlanCompartments>,
  ) {}

  start(): Subscription {
    return this.predicate.pipe(distinctUntilChanged()).subscribe(x => {
      if (this.initialized) {
        if (x) {
          this.cache.reload('plan');
        } else {
          this.cache.reset('plan');
        }
      } else if (x) {
        this.initialized = true;
      }
    });
  }
}
