import React, { useCallback, useContext, useState } from 'react';
import { PublicationStatusEnum, UnitData } from '../../../../models/CourseData';
import { Typography } from '@mui/material';
import Column from '../../../../components/Column';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { PublishUnit } from '../../../../data/learning/commands/PublishUnit';
import { ToastbarContext } from '../../../../App';
import { Toast } from '../../../../models/Toast';
import { UnpublishUnit } from '../../../../data/learning/commands/UnpublishUnit';
import Modal from '../../../../components/Modal';
import Spacer from '../../../../components/Spacer';
import { RemoveUnit } from '../../../../data/learning/commands/RemoveUnit';
import EllipsesMenu from '../../../../components/EllipsesMenu';
import { useNavigate } from 'react-router-dom';

interface UnitMenuProps {
  unit: UnitData;
  disabled: boolean;
}

const UnitMenu: React.FC<UnitMenuProps> = ({ unit, disabled }) => {
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const publishUnit = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(PublishUnit, unit);
      setToast(
        new Toast({
          message: 'Unit published!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, setLoading, setToast, unit]);

  const unpublishUnit = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(UnpublishUnit, unit);
      setToast(
        new Toast({
          message: 'Unit unpublished!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, setLoading, setToast, unit]);

  const editUnit = useCallback(() => {
    navigate(`unit/${unit.originalUnitId}/edit`);
  }, [navigate, unit.originalUnitId]);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const removeUnit = useCallback(async () => {
    setIsVisible(false);
    setLoading(true);
    try {
      await commands.execute(RemoveUnit, unit);
      setToast(
        new Toast({
          message: 'Unit removed!',
          severity: 'success',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, commands, unit, setToast]);

  const menuItems = [
    ...(unit.status === PublicationStatusEnum.DRAFT && (unit.lessons?.length ?? 0) > 0
      ? [
          {
            label: 'Publish unit',
            onClick: publishUnit,
          },
        ]
      : []),
    ...(unit.status === PublicationStatusEnum.PUBLISHED
      ? [
          {
            label: 'Unpublish unit',
            onClick: unpublishUnit,
          },
        ]
      : []),
    {
      label: 'Edit unit',
      onClick: editUnit,
    },
    {
      label: 'Remove unit',
      onClick: (unit.lessons?.length ?? 0) > 0 ? openModal : removeUnit,
      sx: { color: 'error.dark' },
    },
  ];

  return (
    <>
      <EllipsesMenu items={menuItems} disabled={disabled} />
      <Modal
        open={isVisible}
        setOpen={setIsVisible}
        handleSave={removeUnit}
        title='Remove unit?'
        primaryButtonText='Yes, Remove'
        secondaryButtonText='No, Keep'>
        <Column>
          <Typography variant='p14' color='secondary'>
            Are you sure you want to remove {unit.name}?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p14' color='secondary'>
            This will remove any lessons attached to this unit.
          </Typography>
          <Spacer height='xs' />
        </Column>
      </Modal>
    </>
  );
};

export default UnitMenu;
