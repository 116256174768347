import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Row, Spacer } from '../../components';
import { Svgs } from '../../assets/svg';
import { logo3NSmall, logoGcSmall } from '../../assets/png';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { PricebookBundle } from '../../models/PricebookData';
import { LicenseData } from '../../models/LicenseData';

const Congrats = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const pricebook: PricebookBundle =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const licenseData: LicenseData =
    orgSignUpState?.state?.licenseData && JSON.parse(orgSignUpState?.state?.licenseData);
  const product = pricebook?.pricebook?.productType;
  const licenseQuantity = licenseData?.quantity;
  const { quantity, chosenPackage } = location?.state ?? {
    quantity: licenseQuantity,
    chosenPackage: pricebook,
  };

  let logo, logoAlt;
  const gcPackage = product === 'GUIDED_CHOICE';
  logo = gcPackage ? logoGcSmall : logo3NSmall;
  logoAlt = gcPackage ? 'GuidedChoice Logo' : '3Nickels Logo';

  const navToNextStep = () => {
    navigate('/');
  };

  return (
    <Column style={{ margin: '15px 100px', alignItems: 'center' }}>
      <Row style={{ justifyContent: 'flex-end' }}>
        <img src={logo} alt={logoAlt} style={{ width: '30%' }} />
      </Row>
      <Spacer height='lg' />
      <Typography variant='p40Bold' color='secondary'>
        Congratulations!
      </Typography>
      <Spacer height='xs' />
      <Typography variant='p20' color='secondary.dark'>
        Thank you for adding {quantity ? ` ${quantity} license${quantity === 1 ? '' : 's'} of` : ''}
        {chosenPackage?.pricebook?.pricebookName ?? ''} for your members.
      </Typography>

      <Spacer height='xxs' />
      <Typography variant='h2' color='info.main'>
        We have sent you a confirmation email.
      </Typography>

      <Spacer height='sm' />
      <Svgs.ArtThumbsUp />

      <Spacer height='lg' />
      <Button className='xs' onClick={navToNextStep} color='secondary' variant='outlined'>
        Close
      </Button>
    </Column>
  );
};

export default Congrats;
