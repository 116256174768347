import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, LessonData, UnitData } from '../../../models/CourseData';
import { LessonApi } from '../../../api/apis/LessonApi';
import { findLessonSource, updateCourses } from './utils';

export class RemoveLesson implements IDataCommand<LessonData> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.Lesson) private readonly api: LessonApi,
  ) {}

  async execute(lesson: LessonData): Promise<void> {
    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex } = findLessonSource(lesson, courses);
      if (
        courseIndex !== -1 &&
        typeof unitIndex !== 'undefined' &&
        lesson.unitId &&
        lesson.lessonId
      ) {
        await this.api.deleteLesson(lesson.lessonId);
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        const updatedLessons = newUnit.lessons?.filter(x => {
          if (lesson.active) {
            // if deleting the published, active version, remove all lessons with that originalLessonId
            return x.originalLessonId !== lesson.originalLessonId;
          }
          // if deleting the draft, only remove that
          return x.lessonId !== lesson.lessonId;
        });
        newUnit.lessons = updatedLessons;

        const newCourses = updateCourses(newUnit, courses);

        return newCourses;
      }
      return courses;
    });
  }
}
