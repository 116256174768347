import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { LicenseMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { Svgs } from '../../assets/svg';
import { Row, Spacer } from '../../components';

interface MemberPaysMetricsCardProps {
  licenseMetric: LicenseMetricsData;
}

const MemberPaysMetricsCard: React.FC<MemberPaysMetricsCardProps> = ({ licenseMetric }) => {
  return (
    <Card variant='ghost' color='secondary' style={{ minWidth: '150px' }}>
      <CardContent className='basic'>
        <Row style={{ justifyContent: 'space-between' }}>
          <Row style={{ alignItems: 'center' }}>
            <img src={licenseMetric.artUrl} alt='Package Logo' style={{ width: '70px' }} />
            <Spacer width='xxs' />
            <Typography variant='p18Bold' color='secondary'>
              {licenseMetric.pricebookName}
            </Typography>
          </Row>
          <Row style={{ alignItems: 'center' }}>
            <Typography variant='p16SemiBold' color='secondary'>
              Active users
            </Typography>
            <Spacer width='xs' />
            <Svgs.IconMembersDefault />
            <Spacer width='xxs' />
            <Typography variant='p20SemiBold' color='secondary'>
              {licenseMetric.activeLicenses}
            </Typography>
            <Spacer width='xxs' />
          </Row>
        </Row>
      </CardContent>
    </Card>
  );
};

export default MemberPaysMetricsCard;
