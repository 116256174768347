import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import type { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

export class RevenueShareApi {
  private readonly csvConfig: AxiosRequestConfig = {
    headers: {
      Accept: 'text/csv',
    },
    responseType: 'arraybuffer',
  };

  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  generateTeamRevShare(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-team/rev-shares/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }

  generateTeamCharges(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-team/charges/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }

  generateOrgRevShare(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-org/rev-shares/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }

  generateOrgCharges(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-org/charges/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }

  generateGroupRevShare(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-group/rev-shares/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }

  generateGroupCharges(startDate: string, endDate: string): Promise<AxiosResponse> {
    return this.axios.post(
      `/revenue-share/by-group/charges/file?startDate=${startDate}&endDate=${endDate}`,
      {},
      this.csvConfig,
    );
  }
}
