import { useContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastbarContext } from '../../../../App';
import EllipsesMenu from '../../../../components/EllipsesMenu';
import { PublishCourse } from '../../../../data/learning/commands/PublishCourse';
import { UnpublishCourse } from '../../../../data/learning/commands/UnpublishCourse';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { PublicationStatusEnum } from '../../../../models/CourseData';
import { Toast } from '../../../../models/Toast';
import { Grid, Typography } from '@mui/material';
import { Modal, Spacer } from '../../../../components';
import { RemoveCourse } from '../../../../data/learning/commands/RemoveCourse';
import { CourseItemProps } from './CourseItems';

const CourseMenu: React.FC<CourseItemProps> = ({ course }) => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const [isVisible, setIsVisible] = useState(false);

  const publishCourse = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(PublishCourse, course);
      setToast(
        new Toast({
          message: 'Course published!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, course, setLoading, setToast]);

  const unpublishCourse = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(UnpublishCourse, course);
      setToast(
        new Toast({
          message: 'Course unpublished!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, course, setLoading, setToast]);

  const editCourse = useCallback(() => {
    navigate(`/drill-down/courses/${course.originalCourseId}/edit`);
  }, [course.originalCourseId, navigate]);

  const removeCourse = useCallback(async () => {
    setIsVisible(false);
    setLoading(true);
    try {
      await commands.execute(RemoveCourse, course.courseId);
      setToast(
        new Toast({
          message: 'Course removed!',
          severity: 'success',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [commands, course.courseId, setLoading, setToast]);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const menuItems = [
    ...(course.status === PublicationStatusEnum.DRAFT && (course.units?.length ?? 0) > 0
      ? [
          {
            label: 'Publish course',
            onClick: publishCourse,
          },
        ]
      : []),
    ...(course.status === PublicationStatusEnum.PUBLISHED
      ? [
          {
            label: 'Unpublish course',
            onClick: unpublishCourse,
          },
        ]
      : []),
    {
      label: 'Edit course',
      onClick: editCourse,
    },
    {
      label: 'Remove course',
      onClick: (course.units?.length ?? 0) > 0 ? openModal : removeCourse,
      sx: { color: 'error.dark' },
    },
  ];

  return (
    <>
      <Modal
        open={isVisible}
        setOpen={setIsVisible}
        handleSave={removeCourse}
        title='Remove course?'
        primaryButtonText='Yes, Remove'
        secondaryButtonText='No, Keep'>
        <Grid>
          <Typography variant='p14' color='secondary'>
            Are you sure you want to remove {course.name}?
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p14' color='secondary'>
            This will remove any units or lessons attached to this course.
          </Typography>
          <Spacer height='xs' />
        </Grid>
      </Modal>
      <EllipsesMenu items={menuItems} />
    </>
  );
};

export default CourseMenu;
