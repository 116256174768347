import { ReactComponent as ActionSmallCalendarDefault } from './action-small-calendar-default.svg';
import { ReactComponent as ActionSmallRefreshDefault } from './action-small-refresh-default.svg';
import { ReactComponent as ArtAdvice } from './art-advice.svg';
import { ReactComponent as ArtBooks } from './art-books.svg';
import { ReactComponent as ArtCellPhone } from './art-cell-phone.svg';
import { ReactComponent as ArtConfusedMan } from './art-confused-man.svg';
import { ReactComponent as ArtDashboardChecklist } from './art-dashboard-checklist.svg';
import { ReactComponent as ArtDashboardLaptop } from './art-dashboard-laptop.svg';
import { ReactComponent as ArtDashboardLightbulb } from './art-dashboard-lightbulb.svg';
import { ReactComponent as ArtDashboardMagnifyingGlass } from './art-dashboard-magnifying-glass.svg';
import { ReactComponent as ArtDashboardOrganization } from './art-dashboard-organization.svg';
import { ReactComponent as ArtHouse } from './art-house.svg';
import { ReactComponent as ArtLaptop } from './art-laptop.svg';
import { ReactComponent as ArtLightbulbSmall } from './art-lightbulb-small.svg';
import { ReactComponent as ArtMeditation } from './art-meditation.svg';
import { ReactComponent as ArtOrganization } from './art-organization.svg';
import { ReactComponent as ArtPieChart } from './art-pie-chart.svg';
import { ReactComponent as ArtPlant } from './art-plant.svg';
import { ReactComponent as ArtRetirement } from './art-retirement.svg';
import { ReactComponent as ArtRetirementLarge } from './art-retirement-large.svg';
import { ReactComponent as ArtStudyMaterials } from './art-study-materials.svg';
import { ReactComponent as ArtTarget } from './art-target.svg';
import { ReactComponent as ArtThumbsUp } from './art-thumbs-up.svg';
import { ReactComponent as ArtUni3N } from './art-uni-3n.svg';
import { ReactComponent as ArtUniversity } from './art-university.svg';
import { ReactComponent as Bullet3nUniCoach } from './bullet-3nickelsU-coach.svg';
import { ReactComponent as Bullet3nUniLessons } from './bullet-3nickelsU-lessons.svg';
import { ReactComponent as Bullet3nUniQA } from './bullet-3nickelsU-qa.svg';
import { ReactComponent as BulletBook } from './bullet-book.svg';
import { ReactComponent as BulletCalculator } from './bullet-calculator.svg';
import { ReactComponent as BulletChecklist } from './bullet-checklist.svg';
import { ReactComponent as BulletCommunications } from './bullet-communications.svg';
import { ReactComponent as BulletDocument } from './bullet-document.svg';
import { ReactComponent as BulletInvestment } from './bullet-investment.svg';
import { ReactComponent as BulletLoans } from './bullet-loans.svg';
import { ReactComponent as BulletLock } from './bullet-lock.svg';
import { ReactComponent as BulletPicture } from './bullet-picture.svg';
import { ReactComponent as BulletPiggyBank } from './bullet-piggy-bank.svg';
import { ReactComponent as BulletStatistics } from './bullet-statistics.svg';
import { ReactComponent as CoursesActive } from './courses-active.svg';
import { ReactComponent as CoursesDefault } from './courses-default.svg';
import { ReactComponent as DisplayExtraSmallCustomX } from './display-extra-small-custom-x.svg';
import { ReactComponent as DisplayLargeCustomCheckmark } from './display-large-custom-checkmark.svg';
import { ReactComponent as IconAdd } from './icon-add.svg';
import { ReactComponent as IconAddLight } from './icon-add-light.svg';
import { ReactComponent as IconAdminsActive } from './icon-admins-active.svg';
import { ReactComponent as IconAdminsDefault } from './icon-admins-default.svg';
import { ReactComponent as IconArrowDown } from './icon-arrow-down.svg';
import { ReactComponent as IconBusinessActive } from './icon-business-active.svg';
import { ReactComponent as IconBusinessDefault } from './icon-business-default.svg';
import { ReactComponent as IconBook } from './icon-book.svg';
import { ReactComponent as IconCellPhoneDark } from './icon-cell-phone-dark.svg';
import { ReactComponent as IconCellPhoneLight } from './icon-cell-phone-light.svg';
import { ReactComponent as IconCheckmark } from './icon-checkmark.svg';
import { ReactComponent as IconCheckmarkGrey } from './icon-checkmark-grey.svg';
import { ReactComponent as IconClose } from './icon-close.svg';
import { ReactComponent as IconCollapse } from './icon-collapse.svg';
import { ReactComponent as IconCommunicationsActive } from './icon-communications-active.svg';
import { ReactComponent as IconCommunicationsDefault } from './icon-communications-default.svg';
import { ReactComponent as IconContactUsActive } from './icon-contact-us-active.svg';
import { ReactComponent as IconContactUsDefault } from './icon-contact-us-default.svg';
import { ReactComponent as IconDashboardActive } from './icon-dashboard-active.svg';
import { ReactComponent as IconDashboardDefault } from './icon-dashboard-default.svg';
import { ReactComponent as IconDelete } from './icon-delete.svg';
import { ReactComponent as IconDocument } from './icon-document.svg';
import { ReactComponent as IconDocumentGrey } from './icon-document-grey.svg';
import { ReactComponent as IconDownload } from './icon-download.svg';
import { ReactComponent as IconExport } from './icon-export.svg';
import { ReactComponent as IconGrip } from './icon-grip.svg';
import { ReactComponent as IconHelp } from './icon-help.svg';
import { ReactComponent as IconInfoActive } from './icon-info-active.svg';
import { ReactComponent as IconInfoDefault } from './icon-info-default.svg';
import { ReactComponent as IconLicenseActive } from './icon-license-active.svg';
import { ReactComponent as IconLicenseDefault } from './icon-license-default.svg';
import { ReactComponent as IconLightbulb } from './icon-lightbulb.svg';
import { ReactComponent as IconLogout } from './icon-logout.svg';
import { ReactComponent as IconMemberPaysLarge } from './icon-member-pays-large.svg';
import { ReactComponent as IconMembersActive } from './icon-members-active.svg';
import { ReactComponent as IconMembersDefault } from './icon-members-default.svg';
import { ReactComponent as IconMembersDefault2 } from './icon-members-default-2.svg';
import { ReactComponent as IconMembersDefaultWhite } from './icon-members-default-white.svg';
import { ReactComponent as IconMembersLarge } from './icon-members-large.svg';
import { ReactComponent as IconOrganizationsActive } from './icon-organizations-active.svg';
import { ReactComponent as IconOrganizationsDefault } from './icon-organizations-default.svg';
import { ReactComponent as IconOrgPaysLarge } from './icon-org-pays-large.svg';
import { ReactComponent as IconOrgPaysTertiary } from './icon-org-pays-tertiary.svg';
import { ReactComponent as IconPaymentActive } from './icon-payment-active.svg';
import { ReactComponent as IconPaymentDefault } from './icon-payment-default.svg';
import { ReactComponent as IconPicture } from './icon-picture.svg';
import { ReactComponent as IconPlan } from './icon-plan.svg';
import { ReactComponent as IconPlanInfoActive } from './icon-plan-info-active.svg';
import { ReactComponent as IconPlanInfoDefault } from './icon-plan-info-default.svg';
import { ReactComponent as IconProfileActive } from './icon-profile-active.svg';
import { ReactComponent as IconProfileDefault } from './icon-profile-default.svg';
import { ReactComponent as IconReportingActive } from './icon-reporting-active.svg';
import { ReactComponent as IconReportingDefault } from './icon-reporting-default.svg';
import { ReactComponent as IconSearch } from './icon-search.svg';
import { ReactComponent as IconSettingsActive } from './icon-settings-active.svg';
import { ReactComponent as IconSettingsDefault } from './icon-settings-default.svg';
import { ReactComponent as IconSideBySideDark } from './icon-side-by-side-dark.svg';
import { ReactComponent as IconSideBySideLight } from './icon-side-by-side-light.svg';
import { ReactComponent as IconStatisticsActive } from './icon-statistics-active.svg';
import { ReactComponent as IconStatisticsDefault } from './icon-statistics-default.svg';
import { ReactComponent as IconStatusActive } from './icon-status-active.svg';
import { ReactComponent as IconStatusAbandoned } from './icon-status-abandoned.svg';
import { ReactComponent as IconStatusIneligible } from './icon-status-ineligible.svg';
import { ReactComponent as IconStatusInvited } from './icon-status-invited.svg';
import { ReactComponent as IconStatusTrial } from './icon-status-trial.svg';
import { ReactComponent as IconStatusUndecided } from './icon-status-undecided.svg';
import { ReactComponent as IconStatusUnsubscribed } from './icon-status-unsubscribed.svg';
import { ReactComponent as IconStepper } from './icon-stepper.svg';
import { ReactComponent as IconSubtract } from './icon-subtract.svg';
import { ReactComponent as IconTeamsActive } from './icon-teams-active.svg';
import { ReactComponent as IconTeamsDefault } from './icon-teams-default.svg';
import { ReactComponent as IconTechDark } from './icon-tech-dark.svg';
import { ReactComponent as IconTechLight } from './icon-tech-light.svg';
import { ReactComponent as IconWarning } from './icon-warning.svg';
import { ReactComponent as Package3nAdviceLarge } from './package-3nadvice-large.svg';
import { ReactComponent as Package3nAdviceMedium } from './package-3nadvice-medium.svg';
import { ReactComponent as Package3nAdviceSmall } from './package-3nadvice-small.svg';
import { ReactComponent as Package3nFreeLarge } from './package-3nfree-large.svg';
import { ReactComponent as Package3nFreeMedium } from './package-3nfree-medium.svg';
import { ReactComponent as Package3nFreeSmall } from './package-3nfree-small.svg';
import { ReactComponent as Package3nULarge } from './package-3nu-large.svg';
import { ReactComponent as Package3nUMedium } from './package-3nu-medium.svg';
import { ReactComponent as Package3nUSmall } from './package-3nu-small.svg';
import { ReactComponent as PackageGcAdviceMedium } from './package-gcadvice-medium.svg';
import { ReactComponent as PackageGcAdviceSmall } from './package-gcadvice-small.svg';

export const Svgs = {
  ActionSmallCalendarDefault,
  ActionSmallRefreshDefault,
  ArtAdvice,
  ArtBooks,
  ArtCellPhone,
  ArtConfusedMan,
  ArtDashboardChecklist,
  ArtDashboardLaptop,
  ArtDashboardLightbulb,
  ArtDashboardMagnifyingGlass,
  ArtDashboardOrganization,
  ArtHouse,
  ArtLaptop,
  ArtLightbulbSmall,
  ArtMeditation,
  ArtOrganization,
  ArtPieChart,
  ArtPlant,
  ArtRetirement,
  ArtRetirementLarge,
  ArtStudyMaterials,
  ArtTarget,
  ArtThumbsUp,
  ArtUni3N,
  ArtUniversity,
  Bullet3nUniCoach,
  Bullet3nUniLessons,
  Bullet3nUniQA,
  BulletBook,
  BulletCalculator,
  BulletChecklist,
  BulletCommunications,
  BulletDocument,
  BulletInvestment,
  BulletLoans,
  BulletLock,
  BulletPicture,
  BulletPiggyBank,
  BulletStatistics,
  CoursesActive,
  CoursesDefault,
  DisplayExtraSmallCustomX,
  DisplayLargeCustomCheckmark,
  IconAdd,
  IconAddLight,
  IconAdminsActive,
  IconAdminsDefault,
  IconArrowDown,
  IconBusinessActive,
  IconBusinessDefault,
  IconBook,
  IconCellPhoneDark,
  IconCellPhoneLight,
  IconCheckmark,
  IconCheckmarkGrey,
  IconClose,
  IconCollapse,
  IconCommunicationsActive,
  IconCommunicationsDefault,
  IconContactUsActive,
  IconContactUsDefault,
  IconDashboardActive,
  IconDashboardDefault,
  IconDelete,
  IconDocument,
  IconDocumentGrey,
  IconDownload,
  IconExport,
  IconGrip,
  IconHelp,
  IconInfoActive,
  IconInfoDefault,
  IconLicenseActive,
  IconLicenseDefault,
  IconLightbulb,
  IconLogout,
  IconMemberPaysLarge,
  IconMembersActive,
  IconMembersDefault,
  IconMembersDefault2,
  IconMembersDefaultWhite,
  IconMembersLarge,
  IconOrganizationsActive,
  IconOrganizationsDefault,
  IconOrgPaysLarge,
  IconOrgPaysTertiary,
  IconPaymentActive,
  IconPaymentDefault,
  IconPicture,
  IconPlan,
  IconPlanInfoActive,
  IconPlanInfoDefault,
  IconProfileActive,
  IconProfileDefault,
  IconReportingActive,
  IconReportingDefault,
  IconSearch,
  IconSettingsActive,
  IconSettingsDefault,
  IconSideBySideDark,
  IconSideBySideLight,
  IconStatisticsActive,
  IconStatisticsDefault,
  IconStatusActive,
  IconStatusAbandoned,
  IconStatusIneligible,
  IconStatusInvited,
  IconStatusTrial,
  IconStatusUndecided,
  IconStatusUnsubscribed,
  IconStepper,
  IconSubtract,
  IconTeamsActive,
  IconTeamsDefault,
  IconTechDark,
  IconTechLight,
  IconWarning,
  Package3nAdviceLarge,
  Package3nAdviceMedium,
  Package3nAdviceSmall,
  Package3nFreeLarge,
  Package3nFreeMedium,
  Package3nFreeSmall,
  Package3nULarge,
  Package3nUMedium,
  Package3nUSmall,
  PackageGcAdviceMedium,
  PackageGcAdviceSmall,
};
