import { Card, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { LicenseMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { Svgs } from '../../assets/svg';
import { PricebookBundle } from '../../models/PricebookData';
import NumericStepper from '../../components/NumericStepper';

interface LicenseAtAGlanceProps {
  licenseMetrics: LicenseMetricsData;
  pricebookBundle: PricebookBundle;
  value: number;
  onChange: (value: number) => void;
}

const LicenseAtAGlance: React.FC<LicenseAtAGlanceProps> = ({ pricebookBundle }) => {
  const pricebookName = pricebookBundle.pricebook.pricebookName;
  const [packageArt, setPackageArt] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    collectContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collectContext = () => {
    switch (pricebookName) {
      case 'GuidedChoice Advisory Service':
        setPackageArt(<Svgs.PackageGcAdviceMedium />);
        break;
      case '3Nickels Advice':
        setPackageArt(<Svgs.Package3nAdviceMedium />);
        break;
      case '3Nickels Free':
        setPackageArt(<Svgs.Package3nFreeMedium />);
        break;
      case 'Advice + 3NickelsU':
        setPackageArt(<Svgs.Package3nUMedium />);
        break;
    }
  };

  return (
    <Card color='primary' variant='ghost'>
      <Grid container alignItems='center'>
        <Grid xs container alignItems='center'>
          <Grid item px={2}>
            {packageArt}
          </Grid>
          <Grid item xs>
            <Typography variant='p18Bold' color='secondary.main'>
              {pricebookName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} m={2} container justifyContent='flex-end'>
          <NumericStepper label='Total licenses' fieldName='licenseCount' />
        </Grid>
      </Grid>
    </Card>
  );
};

export default LicenseAtAGlance;
