import { Button, Card, CardContent, Link, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Column, Row, Spacer, StepsHeader, SingleCheckbox } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpWizard as SignUpWizardService, signUpWizardKey } from '../../services/signUp';
import { Svgs } from '../../assets/svg';
import { ToastbarContext } from '../../App';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { Toast } from '../../models/Toast';
import { AcceptLicenseAgreement } from '../../data/license/mutations';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { useDeviceType } from '../../hooks/useDeviceType';
import { TermsApi } from '../../api/apis/TermsApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { API_URL } from '../../api/apis';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';

const AdpContract = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const acceptLicenseAgreement = useMutation(new AcceptLicenseAgreement());
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const signUpWizard = useService<SignUpWizardService>(signUpWizardKey);
  const termsApi = useService<TermsApi>(ApiKeys.Terms);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );

  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [docUri, setDocUri] = useState<string | undefined>(undefined);
  const [doc, setDoc] = useState<LicenseAgreementData | undefined>(undefined);
  const [tosLink, setTosLink] = useState<string>();
  const { setToast } = useContext(ToastbarContext);
  const agreeText = 'Agree';
  const backText = 'Back';
  const { isMobile, isTablet } = useDeviceType();
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);
  const { onboardingComplete } = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  ) ?? { onboardingComplete: false };
  // const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  // const pricebookOptions =
  // useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  // const getPricebookDetails = (pricebookId: number) => {
  // return pricebookOptions?.find(pricebook => pricebook.pricebook.id === pricebookId);
  // };
  const pendingAgreement =
    licenseAgreements &&
    licenseAgreements?.find(
      licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
    );
  // const selectedPricebook = getPricebookDetails(pendingAgreement?.pricebookId ?? 0);
  // currently only supporting add package for member pays
  // const syntheticLicenseData: LicenseData = {
  //   paymentParty: selectedPricebook?.pricebook.paidBy ?? '',
  //   package: selectedPricebook?.pricebook.productType,
  //   cost: (selectedPricebook?.prices[0]?.price ?? 0) / 100.0,
  // };
  useEffect(() => {
    const getDocUri = async () => {
      const doc = pendingAgreement;
      if (doc) {
        setDocUri(API_URL + 'license-agreement/doc?docId=' + doc.id);
        setDoc(doc);
      }
    };

    getDocUri();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseAgreements]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getAll();
      const termsObject = res.data.find(doc => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.get(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
    //eslint-disable-next-line
  }, []);

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreedToTerms(event.target.checked);
  };

  const navToNextStep = async () => {
    if (orgSignUpState?.onboardingComplete) {
      navigate('/addPackage/congrats');
    } else {
      const next = signUpWizard.nextStep(location);
      navigate(next.route);
    }
    setToast(
      new Toast({
        message: 'Contract successfully set up!',
        severity: 'success',
        open: true,
        autoHideDuration: 3000,
      }),
    );
  };

  const navBack = async () => {
    if (orgSignUpState?.onboardingComplete) {
      navigate(-1);
    } else {
      const previousStep = signUpWizard.previousStep(location);
      await updateSignUpState.action({ lastCompletedStep: previousStep.previousCheckpoint });
      navigate(previousStep?.route ?? '', { replace: true });
    }
  };

  const onSubmit = async () => {
    if (doc) {
      await acceptLicenseAgreement.action(doc.id);
    }
    if (!orgSignUpState?.onboardingComplete) {
      updateSignUpState.action({
        lastCompletedStep: signUpWizard.getCurrent(location),
        onboardingComplete: onboardingComplete || activeStep === totalSteps,
      });
    }
    navToNextStep();
  };

  const onDownload = () => {
    if (docUri) window.open(`${docUri}`, '_blank');
    else {
      setToast(
        new Toast({
          message: `Cannot find license agreement`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  return (
    <Column style={{ margin: isMobile || isTablet ? '0 20px' : '0px 100px' }}>
      <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps='adpOnboarding' />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Step {activeStep}: Review contract
      </Typography>
      {/* <Spacer height='sm' />
      {licenseData && <PricebookSummaryCard licenseData={licenseData} pricebook={pricebook} />}
      {!licenseData && syntheticLicenseData && (
        <PricebookSummaryCard licenseData={syntheticLicenseData} pricebook={selectedPricebook} />
      )} */}
      <Spacer height='xxs' />
      <Typography variant='p14' color='info.main'>
        * Please see our{' '}
        <Link href={tosLink} target='_blank'>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href='/cancellation-policy' target='_blank'>
          Cancellation and Refund Policy
        </Link>{' '}
        for details
      </Typography>
      <Spacer height='sm' />
      <Row style={{ alignItems: 'center' }}>
        <Svgs.IconDocumentGrey />
        <Spacer width='xxs' />
        <Typography variant='p20SemiBold' color='secondary.main'>
          License Agreement
        </Typography>
      </Row>
      <Spacer height='xxs' />
      <Typography variant='body1' color='info.main'>
        Please view your license agreement before continuing.
      </Typography>
      <Spacer height='xs' />
      <Card variant='innerCard'>
        <CardContent className='yearlyPrice'>
          {isMobile || isTablet ? (
            <Column style={{ alignItems: 'left' }}>
              <SingleCheckbox
                label='I have read the license agreement, and I agree to it.'
                onChange={handleChecked}
              />
              <Spacer height='xs' />
              <Button startIcon={<Svgs.IconDownload />} onClick={onDownload} color='secondary'>
                Download
              </Button>
            </Column>
          ) : (
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <SingleCheckbox
                label='I have read the license agreement, and I agree to it.'
                onChange={handleChecked}
              />
              <Button startIcon={<Svgs.IconDownload />} onClick={onDownload} color='secondary'>
                Download
              </Button>
            </Row>
          )}
        </CardContent>
      </Card>
      <Spacer height='sm' />
      {isMobile ? (
        <Column style={{ alignItems: 'center' }}>
          <Button
            style={{ width: '100%' }}
            sx={{ padding: '5px 40px' }}
            className='xxxl'
            onClick={onSubmit}
            color='primary'
            disabled={!agreedToTerms}>
            {agreeText}
          </Button>
          <Spacer height='xs' />
          <Button
            style={{ width: '100%' }}
            className='xs'
            onClick={navBack}
            variant='outlined'
            color='secondary'>
            {backText}
          </Button>
        </Column>
      ) : (
        <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            className='xs'
            onClick={navBack}
            variant='outlined'
            color='secondary'
            style={{ minWidth: 'fit-content' }}>
            {backText}
          </Button>
          <Row style={{ alignItems: 'center' }}>
            <Button
              sx={{ padding: '5px 40px' }}
              className='xxxl'
              onClick={onSubmit}
              color='primary'
              disabled={!agreedToTerms}>
              {agreeText}
            </Button>
          </Row>
        </Row>
      )}
      <Spacer height='sm' />
    </Column>
  );
};

export default AdpContract;
