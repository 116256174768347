import { Grid, Card, Typography, Button, Box } from '@mui/material';
import { StepStatus } from './UnfinishedPlan';
import React from 'react';

interface PlanTodoCardProps {
  title: string;
  icon: React.ReactNode;
  status: StepStatus;
  stepNumber: number;
  onClick: () => void;
}

const PlanTodoCard: React.FC<PlanTodoCardProps> = ({
  title,
  icon,
  status,
  stepNumber,
  onClick,
}) => {
  const isComplete = status === 'complete';

  return (
    <Grid item xs={12} sm={6}>
      <Card
        variant='ghost'
        color='primary'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 4,
          gap: 2,
        }}>
        <Box>{icon}</Box>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='p18' color='secondary.main' gutterBottom>
            Step {stepNumber}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
              alignItems: { xs: 'flex-start', lg: 'center' },
              justifyContent: 'space-between',
              gap: 2,
              height: '100%',
            }}>
            <Typography
              variant='p20Bold'
              color='secondary.main'
              sx={{
                flex: 1,
                minWidth: 0,
                alignSelf: { xs: 'flex-start', lg: 'center' },
              }}>
              {title}
            </Typography>
            <Button
              variant={isComplete ? 'outlined' : 'tertiary'}
              color={isComplete ? 'secondary' : 'primary'}
              disabled={status === 'inactive'}
              onClick={onClick}
              sx={{
                width: { xs: '100%', lg: '160px' },
                alignSelf: 'center',
              }}>
              {isComplete ? 'Review' : 'Start'}
            </Button>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default PlanTodoCard;
