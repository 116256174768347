import { Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spacer, Column } from '../../components';
import MembersFileUpload from './MembersFileUpload';
import { useNavigatedFromBulkAdd } from '../../hooks/useNavigatedFromBulkAdd';

const AddMembersBulk: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inOffboarding = location.pathname.includes('restrictMembers');
  const { setNavigatedFromBulkAdd } = useNavigatedFromBulkAdd();

  const onSubmit = async () => {
    if (inOffboarding) {
      setNavigatedFromBulkAdd(true);
    }
    navigate(inOffboarding ? '/restrictMembers' : '/members');
  };

  const leftButtonOnSubmit = () => {
    if (inOffboarding) {
      setNavigatedFromBulkAdd(true);
    }
  };

  return (
    <Column style={{ margin: '15px 100px' }}>
      <Spacer height='sm' />
      <Typography color='secondary.main' variant='p50Light'>
        Bulk add
      </Typography>
      <Spacer height='sm' />
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <div style={{}}>
            <MembersFileUpload
              mode='add/edit'
              leftButtonText='Cancel'
              rightButtonText='Done'
              onSubmit={handleSubmit}
              leftButtonOnSubmit={leftButtonOnSubmit}
            />
          </div>
        )}
      </Form>
    </Column>
  );
};

export default AddMembersBulk;
