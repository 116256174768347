import { SubscriptionCompartments, subscriptionStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { SubscriptionApi, SubscriptionRest } from '../../../api/apis/SubscriptionApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { PaymentCompartments, paymentStorageKey } from '../../payment';
import { OrganizationCompartments, organizationStorageKey } from '../../organization';

export class AddLicenses implements IMutation<SubscriptionRest> {
  async execute(context: MutationContext<SubscriptionRest>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.add(data);

    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const subscriptionDataCache =
      storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    const paymentDataCache = storage.retrieve<PaymentCompartments>(paymentStorageKey);

    await Promise.all([
      subscriptionDataCache.reload('subscription'),
      paymentDataCache.reloadAll(),
      orgDataCache.reload('allMetrics'),
    ]);
  }
}

export class DecreaseLicenses implements IMutation<SubscriptionRest> {
  async execute(context: MutationContext<SubscriptionRest>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.decrease(data);

    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const subscriptionDataCache =
      storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    const paymentDataCache = storage.retrieve<PaymentCompartments>(paymentStorageKey);

    await Promise.all([
      subscriptionDataCache.reload('subscription'),
      paymentDataCache.reloadAll(),
      orgDataCache.reload('allMetrics'),
    ]);
  }
}

export class CancelInvoice implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const subscriptionApi = container.get<SubscriptionApi>(ApiKeys.Subscription);

    await subscriptionApi.cancelInvoice(data);

    const cache = storage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
    await cache.reloadAll();
  }
}
