import { Svgs } from '../assets/svg';
import { SideNavDrawer } from '.';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { useService } from '@aesop-fables/containr-react';
import { TeamCompartments, teamStorageKey } from '../data/team';
import { TeamData } from '../models/TeamData';
import { GroupCompartments, groupStorageKey } from '../data/group';
import { GroupData } from '../models/GroupData';
import { NavItemProps } from './SideNavDrawer';
import { useEffect } from 'react';

interface MultiTierAdminSideNavProps extends React.PropsWithChildren {}

export const getSidebarItemsByAdminTier = (
  tier: AdminTierTypeEnum | undefined,
  highestAccess: AdminTierTypeEnum | undefined,
  revenueAccess: AdminTierTypeEnum | undefined,
): NavItemProps[] => {
  const sidebarItemsByAdminTier: NavItemProps[] = [];

  if (tier === AdminTierTypeEnum.Team) {
    sidebarItemsByAdminTier.push({
      pathname: '/controller/teams',
      label: 'Teams',
      artDefault: <Svgs.IconTeamsDefault />,
      artActive: <Svgs.IconTeamsActive />,
    });
    if (highestAccess === AdminTierTypeEnum.Group) {
      sidebarItemsByAdminTier.push(
        {
          pathname: '/controller/group-info',
          label: 'Group info',
          artDefault: <Svgs.IconInfoDefault />,
          artActive: <Svgs.IconInfoActive />,
        },
        {
          pathname: '/controller/group-access',
          label: 'Group access',
          artDefault: <Svgs.IconAdminsDefault />,
          artActive: <Svgs.IconAdminsActive />,
        },
        {
          pathname: '/controller/group-reports',
          label: 'Reports',
          artDefault: <Svgs.IconReportingDefault />,
          artActive: <Svgs.IconReportingActive />,
        },
      );
      if (revenueAccess === AdminTierTypeEnum.Group) {
        sidebarItemsByAdminTier.push({
          pathname: '/controller/group-rev-share',
          label: 'Revenue Share',
          artDefault: <></>,
          artActive: <></>,
        });
      }
    }
  } else if (tier === AdminTierTypeEnum.Organization) {
    sidebarItemsByAdminTier.push({
      pathname: '/controller/organizations',
      label: 'Organizations',
      artDefault: <Svgs.IconOrganizationsDefault />,
      artActive: <Svgs.IconOrganizationsActive />,
    });
    if (highestAccess === AdminTierTypeEnum.Team || highestAccess === AdminTierTypeEnum.Group) {
      sidebarItemsByAdminTier.push(
        {
          pathname: '/controller/team-info',
          label: 'Team info',
          artDefault: <Svgs.IconInfoDefault />,
          artActive: <Svgs.IconInfoActive />,
        },
        {
          pathname: '/controller/team-access',
          label: 'Team access',
          artDefault: <Svgs.IconAdminsDefault />,
          artActive: <Svgs.IconAdminsActive />,
        },
        {
          pathname: '/controller/team-reports',
          label: 'Reports',
          artDefault: <Svgs.IconReportingDefault />,
          artActive: <Svgs.IconReportingActive />,
        },
      );
      if (revenueAccess === AdminTierTypeEnum.Team || revenueAccess === AdminTierTypeEnum.Group) {
        sidebarItemsByAdminTier.push({
          pathname: '/controller/team-rev-share',
          label: 'Revenue Share',
          artDefault: <></>,
          artActive: <></>,
        });
      }
    }
  }

  sidebarItemsByAdminTier.push({
    pathname: '/controller/contact-us',
    label: 'Contact us',
    artDefault: <Svgs.IconContactUsDefault />,
    artActive: <Svgs.IconContactUsActive />,
  });

  return sidebarItemsByAdminTier;
};

const MultiTierAdminSideNav: React.FC<MultiTierAdminSideNavProps> = props => {
  const appStorage = useAppStorage();
  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const drillDownWizardInitialized = useObservable(drillDownWizard.initialized$);

  const tier = useObservable(drillDownWizard.tier$);
  const groupName = useObservable(groupDataCache.observe$<GroupData>('group'))?.name ?? '';
  const teamName = useObservable(teamDataCache.observe$<TeamData>('team'))?.name ?? '';
  const highestAccess = useObservable(drillDownWizard.highestAccess$);
  const revenueAccess = useObservable(drillDownWizard.revenueAccess$);
  const sidebarItems = getSidebarItemsByAdminTier(tier, highestAccess, revenueAccess);
  const secondDividerIndex = highestAccess === tier ? undefined : 4;

  let clickableName = '';
  if (tier === AdminTierTypeEnum.Team) {
    clickableName = groupName;
  } else if (tier === AdminTierTypeEnum.Organization) {
    clickableName = teamName;
  }

  useEffect(() => {
    if (drillDownWizardInitialized && sidebarItems) {
      drillDownWizard.setSidebarItems(sidebarItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drillDownWizardInitialized, tier, highestAccess, revenueAccess]);

  return (
    <SideNavDrawer
      sidebarItems={sidebarItems}
      secondDividerIndex={secondDividerIndex}
      clickableName={clickableName}
    />
  );
};
export default MultiTierAdminSideNav;
