import React, { useCallback, useContext, useMemo, useState } from 'react';
import { LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { ToastbarContext } from '../../../../App';
import { useCommands } from '../../../../helpers/useCommands';
import { useLoading } from '../../../../hooks/useLoading';
import { Toast } from '../../../../models/Toast';
import { PublishLesson } from '../../../../data/learning/commands/PublishLesson';
import { UnpublishLesson } from '../../../../data/learning/commands/UnpublishLesson';
import { RemoveLesson } from '../../../../data/learning/commands/RemoveLesson';
import RemoveLessonModal from '../lessons/RemoveLessonModal';
import { useNavigate } from 'react-router';
import EllipsesMenu from '../../../../components/EllipsesMenu';

interface LessonMenuProps {
  lesson: LessonData;
  editLesson: (lesson: LessonData) => void;
  disabled: boolean;
}

const LessonMenu: React.FC<LessonMenuProps> = ({ lesson, editLesson, disabled }) => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const commands = useCommands();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const lessonEmpty = useMemo(
    () => (lesson.contents?.length ?? 0) === 0 && !lesson.quiz,
    [lesson.contents?.length, lesson.quiz],
  );

  const publishLesson = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(PublishLesson, lesson);
      setToast(
        new Toast({
          message: 'Lesson published!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, setLoading, setToast, lesson]);

  const unpublishLesson = useCallback(async () => {
    try {
      setLoading(true);
      await commands.execute(UnpublishLesson, lesson);
      setToast(
        new Toast({
          message: 'Lesson unpublished!',
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    } finally {
      setLoading(false);
    }
  }, [commands, setLoading, setToast, lesson]);

  const viewLesson = useCallback(() => {
    navigate(`unit/${lesson.unitId}/lesson/${lesson.originalLessonId}`);
  }, [lesson.originalLessonId, lesson.unitId, navigate]);

  const openModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const removeLesson = useCallback(async () => {
    setIsVisible(false);
    setLoading(true);
    try {
      await commands.execute(RemoveLesson, lesson);
      setToast(
        new Toast({
          message: 'Lesson removed!',
          severity: 'success',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, commands, lesson, setToast]);

  const menuItems = [
    ...(!lessonEmpty && lesson.status === PublicationStatusEnum.DRAFT
      ? [
          {
            label: 'Publish lesson',
            onClick: publishLesson,
            sx: { color: 'secondary.main' },
          },
        ]
      : []),
    ...(!lessonEmpty && lesson.status === PublicationStatusEnum.PUBLISHED
      ? [
          {
            label: 'Unpublish lesson',
            onClick: unpublishLesson,
            sx: { color: 'secondary.main' },
          },
        ]
      : []),
    {
      label: 'View lesson',
      onClick: viewLesson,
      sx: { color: 'secondary.main' },
    },
    {
      label: 'Remove lesson',
      onClick:
        (lesson.contents?.length ?? 0) > 0 || typeof lesson.quiz !== 'undefined'
          ? openModal
          : removeLesson,
      sx: { color: 'error.dark' },
    },
  ];

  return (
    <>
      <EllipsesMenu items={menuItems} disabled={disabled} />
      <RemoveLessonModal
        lesson={lesson}
        open={isVisible}
        setOpen={setIsVisible}
        removeLesson={removeLesson}
      />
    </>
  );
};

export default LessonMenu;
