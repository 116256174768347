import { LicenseCompartments, licenseStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { LicenseAgreementApi } from '../../../api/apis/LicenseAgreementApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../../organization';
import {
  licenseAgreementAcceptLogCompartments,
  licenseAgreementAcceptLogStorageKey,
} from '../../licenseAgreementAcceptLog';

export class AcceptLicenseAgreement implements IMutation<number> {
  async execute(context: MutationContext<number>): Promise<void> {
    const { data, storage, container } = context;
    const licenseAgreementApi = container.get<LicenseAgreementApi>(ApiKeys.LicenseAgreement);

    await licenseAgreementApi.accept(data);

    const licenseDataCache = storage.retrieve<LicenseCompartments>(licenseStorageKey);
    const organizationDataCache =
      storage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const licenseAgreementAcceptLogDataCache =
      storage.retrieve<licenseAgreementAcceptLogCompartments>(licenseAgreementAcceptLogStorageKey);

    await Promise.all([
      licenseDataCache.reloadAll(),
      organizationDataCache.reload('allMetrics'),
      licenseAgreementAcceptLogDataCache.reload('licenseAgreementAcceptLog'),
    ]);
  }
}
