import { Svgs } from '../assets/svg';
import { SideNavDrawer } from '.';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { ProductTypeCompartments, productTypeStorageKey } from '../data/productType';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { OrganizationData } from '../models/OrganizationData';
import { MemberCompartments, membersStorageKey } from '../data/members';
import { PaymentCompartments, paymentStorageKey } from '../data/payment';
import { LicenseAgreementData } from '../api/apis/LicenseAgreementApi';
import { LicenseCompartments, licenseStorageKey } from '../data/license';
import { useService } from '@aesop-fables/containr-react';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { NavItemProps } from './SideNavDrawer';
import { useEffect } from 'react';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../data/groupFeatureMap';
import { GroupFeatureMapData } from '../models/GroupFeatureMapData';

interface OrganizationSideNavProps extends React.PropsWithChildren {}

export const getSidebarItemsPaidByAdmin = (
  licenseAgreements: LicenseAgreementData[],
  revenueAccess: AdminTierTypeEnum | undefined,
): NavItemProps[] => {
  const sidebarItemsPaidByAdmin: NavItemProps[] = [];
  const mendixClient =
    licenseAgreements &&
    licenseAgreements?.filter(
      licenseAgreement =>
        licenseAgreement.pricebookBundleDto.pricebook.pricebookName === 'Mendix API Access',
    ).length > 0;

  sidebarItemsPaidByAdmin.push(
    {
      pathname: '/',
      label: 'Dashboard',
      artDefault: <Svgs.IconStatisticsDefault />,
      artActive: <Svgs.IconStatisticsActive />,
    },
    {
      pathname: '/org-info',
      label: 'Org info',
      artDefault: <Svgs.IconBusinessDefault />,
      artActive: <Svgs.IconBusinessActive />,
    },
    {
      pathname: '/org-access',
      label: 'Org access',
      artDefault: <Svgs.IconAdminsDefault />,
      artActive: <Svgs.IconAdminsActive />,
    },
    {
      pathname: '/members',
      label: 'Members',
      artDefault: <Svgs.IconMembersDefault />,
      artActive: <Svgs.IconMembersActive />,
    },
    {
      pathname: '/reports',
      label: 'Reports',
      artDefault: <Svgs.IconReportingDefault />,
      artActive: <Svgs.IconReportingActive />,
    },
  );

  if (
    revenueAccess === AdminTierTypeEnum.Organization ||
    revenueAccess === AdminTierTypeEnum.Team ||
    revenueAccess === AdminTierTypeEnum.Group
  ) {
    sidebarItemsPaidByAdmin.push({
      pathname: '/rev-share',
      label: 'Revenue Share',
      artDefault: <></>,
      artActive: <></>,
    });
  }

  sidebarItemsPaidByAdmin.push(
    {
      pathname: '/license-agreement',
      label: 'License agreement',
      artDefault: <Svgs.IconLicenseDefault />,
      artActive: <Svgs.IconLicenseActive />,
    },
    {
      pathname: '/payment',
      label: 'Payment',
      artDefault: <Svgs.IconPaymentDefault />,
      artActive: <Svgs.IconPaymentActive />,
    },
    {
      pathname: '/plan-info',
      label: 'Plan info',
      artDefault: <Svgs.IconPlanInfoDefault />,
      artActive: <Svgs.IconPlanInfoActive />,
    },
    {
      pathname: '/contact-us',
      label: 'Contact us',
      artDefault: <Svgs.IconContactUsDefault />,
      artActive: <Svgs.IconContactUsActive />,
    },
  );

  if (mendixClient) {
    sidebarItemsPaidByAdmin.splice(1, 0, {
      pathname: '/public-key',
      label: 'Public key',
      artDefault: <Svgs.IconSettingsDefault />,
      artActive: <Svgs.IconSettingsActive />,
    });
  }

  return sidebarItemsPaidByAdmin;
};

export const sidebarItemsPaidByMember: NavItemProps[] = [
  {
    pathname: '/',
    label: 'Dashboard',
    artDefault: <Svgs.IconStatisticsDefault />,
    artActive: <Svgs.IconStatisticsActive />,
  },
  {
    pathname: '/org-info',
    label: 'Org info',
    artDefault: <Svgs.IconBusinessDefault />,
    artActive: <Svgs.IconBusinessActive />,
  },
  {
    pathname: '/org-access',
    label: 'Org access',
    artDefault: <Svgs.IconAdminsDefault />,
    artActive: <Svgs.IconAdminsActive />,
  },
  {
    pathname: '/members',
    label: 'Members',
    artDefault: <Svgs.IconMembersDefault />,
    artActive: <Svgs.IconMembersActive />,
  },
  {
    pathname: '/reports',
    label: 'Reports',
    artDefault: <Svgs.IconReportingDefault />,
    artActive: <Svgs.IconReportingActive />,
  },
  // {
  //   pathname: '/communications',
  //   label: 'Communications',
  //   artDefault: <Svgs.IconCommunicationsDefault />,
  //   artActive: <Svgs.IconCommunicationsActive />,
  // },
  {
    pathname: '/license-agreement',
    label: 'License agreement',
    artDefault: <Svgs.IconLicenseDefault />,
    artActive: <Svgs.IconLicenseActive />,
  },
  {
    pathname: '/plan-info',
    label: 'Plan info',
    artDefault: <Svgs.IconPlanInfoDefault />,
    artActive: <Svgs.IconPlanInfoActive />,
  },
  {
    pathname: '/contact-us',
    label: 'Contact us',
    artDefault: <Svgs.IconContactUsDefault />,
    artActive: <Svgs.IconContactUsActive />,
  },
];

const OrganizationSideNav: React.FC<OrganizationSideNavProps> = props => {
  const appStorage = useAppStorage();
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const memberDataCache = appStorage.retrieve<MemberCompartments>(membersStorageKey);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

  const productTypeDataCacheInitialized = useObservable(productTypeDataCache.initialized$);
  const memberDataCacheInitialized = useObservable(memberDataCache.initialized$);
  const paymentDataCacheInitialized = useObservable(paymentDataCache.initialized$);
  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );
  const licenseAgreements =
    useObservable(licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement')) ?? [];
  const organization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const revenueAccess = useObservable(drillDownWizard.revenueAccess$);

  let sidebarItems =
    paidBy === 'ORG' || (paymentMethods?.length ?? 0) > 0
      ? getSidebarItemsPaidByAdmin(licenseAgreements, revenueAccess)
      : sidebarItemsPaidByMember;
  if (groupFeatureMapData) {
    if (!groupFeatureMapData.organizationMembersView) {
      sidebarItems = sidebarItems.filter(item => item.pathname !== '/members');
    }
    if (!groupFeatureMapData.organizationAdminView) {
      sidebarItems = sidebarItems.filter(item => item.pathname !== '/org-access');
    }
  }

  const secondDividerIndex = sidebarItems.length - 2;

  useEffect(() => {
    if (
      sidebarItems &&
      productTypeDataCacheInitialized &&
      memberDataCacheInitialized &&
      paymentDataCacheInitialized
    ) {
      drillDownWizard.setSidebarItems(sidebarItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productTypeDataCacheInitialized,
    memberDataCacheInitialized,
    paymentDataCacheInitialized,
    revenueAccess,
  ]);

  return (
    <SideNavDrawer
      sidebarItems={sidebarItems}
      secondDividerIndex={secondDividerIndex}
      clickableName={organization?.name ?? ''}
    />
  );
};

export default OrganizationSideNav;
