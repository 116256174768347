import { PricebookCompartments, pricebookStorageKey } from '..';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { PricebookApi } from '../../../api/apis/PricebookApi';
import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { PricebookBundle } from '../../../models/PricebookData';
import { ProductTypeCompartments, productTypeStorageKey } from '../../productType';
import { OffboardingCompartments, offboardingStorageKey } from '../../offboarding';

export class SelectPricebook implements IMutation<PricebookBundle> {
  async execute(context: MutationContext<PricebookBundle>): Promise<void> {
    const { data, storage, container } = context;
    const pricebookApi = container.get<PricebookApi>(ApiKeys.Pricebook);
    await pricebookApi.selectByPricebookId(data.pricebook.id);

    const cache = storage.retrieve<PricebookCompartments>(pricebookStorageKey);
    const productTypeDataCache = storage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
    const offboardingDataCache = storage.retrieve<OffboardingCompartments>(offboardingStorageKey);

    await Promise.all([
      cache.reload('pricebookSelections'),
      productTypeDataCache.reload('paidBy'),
      offboardingDataCache.reload('offboardingTemplate'),
    ]);
  }
}
