import React, { useContext, useState } from 'react';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { MembersApi } from '../../api/apis/MembersApi';
import { ToastbarContext } from '../../App';
import { KeyValueLabel } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SetDomains } from '../../data/offboarding/mutations';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { UpdateOrganizationRestrictions } from '../../data/organization/mutations';
import { UserCompartments, userStorageKey } from '../../data/user';
import { OrganizationData, RestrictionEnum } from '../../models/OrganizationData';
import { Toast } from '../../models/Toast';
import { AuthenticatedWizard, authenticatedWizardKey } from '../../services/authenticated';
import { convertBackToKey } from '../offboarding/RestrictMemberAccess';
import { OrganizationCard } from './OrganizationInfo';
import RestrictionEditDrawer from './RestrictionEditDrawer';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { RestrictionCompartments, restrictionStorageKey } from '../../data/restrictions';
import { useLoading } from '../../hooks/useLoading';

const MembersAccessCard: React.FC<React.PropsWithChildren> = () => {
  const appStorage = useAppStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(organizationDataCache.observe$<OrganizationData>('organization'));
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  return (
    <OrganizationCard title={`Member access`} editable={true} setDrawerOpen={setDrawerOpen}>
      <KeyValueLabel
        keyString='Member access'
        value={
          groupFeatureMapData?.onlyRestrictMembersByDomain
            ? 'Restricted by domain'
            : orgData?.restrictMemberAccess === RestrictionEnum.NONE ||
              !orgData?.restrictMemberAccess
            ? 'Not restricted'
            : 'Restricted by ' +
              (orgData?.restrictMemberAccess === RestrictionEnum.LIST
                ? 'a list of members'
                : orgData?.restrictMemberAccess?.toLocaleLowerCase())
        }
      />
      <MemberAccessCardEdit
        title='Member access'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        orgData={orgData}
      />
    </OrganizationCard>
  );
};
interface MemberAccessCardEditProps {
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orgData?: OrganizationData;
}

const MemberAccessCardEdit: React.FC<MemberAccessCardEditProps> = ({
  title,
  drawerOpen,
  setDrawerOpen,
  orgData,
}) => {
  const appStorage = useAppStorage();
  const { setLoading } = useLoading();
  const [tsv, setTsv] = useState<string | undefined>(undefined);
  const { setToast } = useContext(ToastbarContext);

  const authWizard = useService<AuthenticatedWizard>(authenticatedWizardKey);
  const setDomains = useMutation(new SetDomains());
  const updateOrgRestrictions = useMutation(new UpdateOrganizationRestrictions());
  const membersApi = useService<MembersApi>(ApiKeys.Members);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const restrictionsCache = appStorage.retrieve<RestrictionCompartments>(restrictionStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  const domains =
    useObservable(restrictionsCache.observe$<{ domain: string }[]>('restrictions')) ?? [];
  if (domains.length === 0) {
    domains.push({
      domain: '',
    });
  }
  const onSubmit = async (values: any) => {
    setLoading(true);
    if (!values.restrictMemberAccess) {
      if (groupFeatureMapData?.onlyRestrictMembersByDomain) {
        await setDomains.action(values.domains);
      } else {
        return;
      }
    }
    try {
      switch (values.restrictMemberAccess) {
        case 'By domain':
          await setDomains.action(values.domains);
          break;
        case 'By a list of members':
          if (tsv) {
            setLoading(true);
            let formData = new FormData();
            formData.append('file', tsv);
            await membersApi.bulkAddMembers(formData);
            userDataCache.reloadAll();
            setToast(new Toast({ message: 'Members added', severity: 'success', open: true }));
          }
          break;
        default:
          break;
      }

      updateOrgRestrictions.action({
        restrictMemberAccess: convertBackToKey(values.restrictMemberAccess),
      });

      authWizard.setRestriction(convertBackToKey(values.restrictMemberAccess));
      authWizard.setRestriction(values.restriction);
      setTsv(undefined);
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Successfully saved restriction changes.',
        }),
      );
    } catch (error) {
      setLoading(false);
      console.error('error :>> ', error);
      setTsv(undefined);
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error parsing TSV. Please check your formatting and try again.',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <RestrictionEditDrawer
      onSubmit={onSubmit}
      title={title}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      initialValues={domains}
      orgData={orgData}
      setTsv={setTsv}
    />
  );
};
export default MembersAccessCard;
