import { Card, CardContent, Typography, Button } from '@mui/material';
import React from 'react';
import { LicenseMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { Svgs } from '../../assets/svg';
import { Row, Spacer, Column } from '../../components';
import { PaymentTypeEnum } from '../../models/PricebookData';

interface OrgPaysMetricsCardProps {
  licenseMetric: LicenseMetricsData;
  onPurchaseLicenses: () => void;
  purchaseDisabled?: boolean;
}

const OrgPaysMetricsCard: React.FC<OrgPaysMetricsCardProps> = ({
  licenseMetric,
  onPurchaseLicenses,
  purchaseDisabled,
}) => {
  const meteredPayment = licenseMetric.paymentType === PaymentTypeEnum.Metered;

  return (
    <Card variant='ghost' color='secondary' style={{ minWidth: '150px', margin: '5px 0px' }}>
      <CardContent className='basic'>
        <Row style={{ justifyContent: 'space-between' }}>
          <Row style={{ alignItems: 'center' }}>
            <img src={licenseMetric.artUrl} alt='Package Logo' style={{ width: '70px' }} />
            <Spacer width='xxs' />
            <Typography variant='p18Bold' color='secondary'>
              {licenseMetric.pricebookName}
            </Typography>
          </Row>
          <Row>
            <Column>
              <Typography variant='p12SemiBold' color='secondary'>
                Licenses purchased
              </Typography>
              <Spacer height='xxxs' />
              <Row>
                <Svgs.IconLicenseDefault />
                <Spacer width='xxs' />
                <Typography variant='p20SemiBold' color='secondary'>
                  {licenseMetric.purchasedLicenses ?? 0}
                </Typography>
              </Row>
            </Column>
            <Spacer width='sm' />
            <Column>
              <Typography variant='p12SemiBold' color='secondary'>
                Licenses remaining
              </Typography>
              <Spacer height='xxxs' />
              <Row>
                <Svgs.IconLicenseDefault />
                <Spacer width='xxs' />
                <Typography variant='p20SemiBold' color='secondary'>
                  {licenseMetric.availableLicenses ?? 0}
                </Typography>
              </Row>
            </Column>
            <Spacer width='md' />
            {!meteredPayment && (
              <Button
                onClick={onPurchaseLicenses}
                disabled={purchaseDisabled}
                style={{ alignSelf: 'flex-end' }}
                color='primary'>
                Edit licenses
              </Button>
            )}
          </Row>
        </Row>
      </CardContent>
    </Card>
  );
};

export default OrgPaysMetricsCard;
