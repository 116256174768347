import { HelpOutline } from '@mui/icons-material';
import { Button, Card, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Svgs } from '../../assets/svg';
import { HorizontalFormInput, RightDrawer, Row, Spacer, ToggleInput } from '../../components';
import { OrganizationData, RestrictionEnum } from '../../models/OrganizationData';
import { RestrictionHelpText } from '../offboarding/RestrictMemberAccess';
import arrayMutators from 'final-form-arrays';
import { OrgDomain } from '../../models/OffboardingData';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { DeleteDomain } from '../../data/offboarding/mutations';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';

interface RestrictionEditDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onSubmit: (values: any) => void;
  initialValues?: { domain: string }[];
  orgData?: OrganizationData;
  setTsv: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const RestrictionEditDrawer: React.FC<RestrictionEditDrawerProps> = props => {
  const { drawerOpen, setDrawerOpen, title, onSubmit, orgData, initialValues } = props;
  const appStorage = useAppStorage();
  const theme = useTheme();
  const items = ['By domain', 'By a list of members', `I don't want to restrict`];
  const deleteDomain = useMutation(new DeleteDomain());
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  const initialRestriction =
    orgData?.restrictMemberAccess === RestrictionEnum.NONE
      ? items[2]
      : orgData?.restrictMemberAccess === RestrictionEnum.LIST
      ? items[1]
      : orgData?.restrictMemberAccess === RestrictionEnum.DOMAIN
      ? items[0]
      : items[2];

  const handleSave = (values: any) => {
    onSubmit(values);
    setDrawerOpen(false);
  };

  const onDelete = async (id: string) => {
    await deleteDomain.action(id);
  };

  return (
    <RightDrawer
      open={drawerOpen}
      setOpen={setDrawerOpen}
      smallTitle='Edit organization'
      title={title}>
      <Form onSubmit={handleSave} initialValues={initialValues} mutators={{ ...arrayMutators }}>
        {({
          form: {
            mutators: { push },
          },
          handleSubmit,
          values,
        }) => (
          <>
            <Spacer height='xxxs' />
            <>
              {!groupFeatureMapData?.onlyRestrictMembersByDomain && (
                <>
                  <Row style={{ alignItems: 'center' }}>
                    <Typography variant='h2' color='info.main'>
                      How would you like to restrict access to the product you purchased?&nbsp;
                    </Typography>
                    <Tippy
                      placement='right'
                      content={<RestrictionHelpText />}
                      theme='tippy-rounded'>
                      <HelpOutline style={{ color: theme.palette.info.main }} />
                    </Tippy>
                  </Row>

                  <Spacer height='xs' />
                  <ToggleInput
                    initialValue={initialRestriction}
                    buttonStyle={{ maxWidth: '200px', height: '45px' }}
                    fieldName='restrictMemberAccess'
                    required={true}
                    items={items}
                  />
                  <Spacer height='md' />
                </>
              )}
              {values.restrictMemberAccess === 'By domain' ||
              groupFeatureMapData?.onlyRestrictMembersByDomain ? (
                <>
                  <Typography variant='p20SemiBold' color='secondary.dark'>
                    Enter your organization’s domain(s)
                  </Typography>
                  <Spacer height='xxxs' />
                  <Typography variant='p16Italic' color='info.main'>
                    For example: In user@sample-domain.com, “sample-domain.com” would be the domain.
                  </Typography>
                  <Spacer height='sm' />

                  <FieldArray
                    initialValue={initialValues}
                    style={{ paddingLeft: '34px', align: 'center' }}
                    name='domains'>
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div key={name}>
                          <Row style={{ alignItems: 'center' }}>
                            {index !== 0 && (
                              <Svgs.IconDelete
                                onClick={() => {
                                  if ((fields.value[index] as OrgDomain)?.id !== undefined) {
                                    onDelete((fields.value[index] as OrgDomain).id.toString());
                                  }
                                  fields.remove(index);
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}

                            {index === 0 ? (
                              <Spacer style={{ width: '44px' }} />
                            ) : (
                              <Spacer width='xs' />
                            )}
                            <HorizontalFormInput
                              fieldName={`${name}.domain`}
                              label='Domain name'
                              required={index === 0}
                              isDomain
                            />
                          </Row>
                          <Spacer height='sm' />
                        </div>
                      ))
                    }
                  </FieldArray>

                  <Row
                    style={{ alignItems: 'center', cursor: 'pointer' }}
                    onClick={() => push('domains', undefined)}>
                    <Svgs.IconAddLight />
                    <Spacer width='xxs' />
                    <Typography color='primary' variant='h3' sx={{ textDecoration: 'underline' }}>
                      Add another domain
                    </Typography>
                  </Row>
                </>
              ) : values.restrictMemberAccess === 'By a list of members' ? (
                <Card className='restrict-by-members-info'>
                  <Svgs.IconMemberPaysLarge />
                  <Typography variant='p16SemiBold' color='secondary.main'>
                    You can manage your list of members in the Members tab.
                  </Typography>
                </Card>
              ) : null}
            </>
            <Spacer height='xl' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Close
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default RestrictionEditDrawer;
