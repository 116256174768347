import { Grid, Typography } from '@mui/material';
import { Modal, Spacer } from '../../components';

interface ConfirmBulkAddModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setClearUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setProceedWithBulkAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmBulkAddModal: React.FC<ConfirmBulkAddModalProps> = ({
  open,
  setOpen,
  setClearUpload,
  setProceedWithBulkAdd,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleSave={() => setProceedWithBulkAdd(true)}
      handleCancel={() => setClearUpload(true)}
      onClose={() => setClearUpload(true)}
      title='Continue with upload?'
      primaryButtonText='Yes, Upload'
      secondaryButtonText='No, Do It Later'>
      <Grid width='450px'>
        <Typography variant='p14' color='secondary'>
          Uploading this file will send an invite immediately to any members that you add. You can
          upload later if you wish to wait.
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p14' color='secondary'>
          Are you sure you want to upload now?
        </Typography>
        <Spacer height='xs' />
      </Grid>
    </Modal>
  );
};

export default ConfirmBulkAddModal;
