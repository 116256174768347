import { Observable, BehaviorSubject } from 'rxjs';
import { Scopes, createServiceModule, inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { AuthUserApi } from '../../api/apis/UserApi';

export const authContextKey = 'auth/context';

export const AuthServices = {
  IsAuthenticated: 'authentication/subjects/IsAuthenticated',
};

export interface IAuthenticationContext {
  get isAuthenticated$(): Observable<boolean | undefined>;
  get authReady$(): Observable<boolean | undefined>;
  setIsAuthenticated(val: boolean): void;
  setAuthReady(val: boolean): void;
  refresh(): void;
}

export class AuthenticationContext implements IAuthenticationContext {
  private readonly isAuthenticated = new BehaviorSubject<boolean | undefined>(undefined);
  private readonly authReady = new BehaviorSubject<boolean | undefined>(false);

  constructor(@inject(ApiKeys.AuthUserApi) private readonly authUserApi: AuthUserApi) {
    this.refresh();
  }

  refresh(): void {
    try {
      this.determineIfAuthenticated()
        .then(isAuth => {
          this.setIsAuthenticated(isAuth);
        })
        .catch(() => {
          this.setIsAuthenticated(false);
        })
        .finally(() => {
          this.setAuthReady(true);
        });
    } catch {
      this.isAuthenticated.next(false);
      this.authReady.next(false);
    }
  }

  private async determineIfAuthenticated(): Promise<boolean> {
    try {
      await this.authUserApi.getUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  get isAuthenticated$(): Observable<boolean | undefined> {
    return this.isAuthenticated.pipe();
  }

  get authReady$(): Observable<boolean | undefined> {
    return this.authReady.pipe();
  }

  setIsAuthenticated(val: boolean): void {
    this.isAuthenticated.next(val);
  }

  setAuthReady(val: boolean): void {
    this.authReady.next(val);
  }
}

export const withAuthContext = createServiceModule(authContextKey, services => {
  services.autoResolve(authContextKey, AuthenticationContext, Scopes.Singleton);
});
