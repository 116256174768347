import { MutatorState } from '@aesop-fables/scrinium';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { ToastbarContext } from '../../App';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { Svgs } from '../../assets/svg';
import { Row, Column, Spacer } from '../../components';
import {
  PricebookBundle,
  PaymentTypeEnum,
  ProductTypeEnum,
  IntervalTypeEnum,
} from '../../models/PricebookData';
import { OrgSignUpStateData, State } from '../../models/SignUpData';
import {
  LearnMoreAdviceDrawer,
  LearnMoreThreeNickelsFreeDrawer,
  LearnMoreAdviceAndThreeNickelsUDrawer,
  LearnMoreGuidedChoiceDrawer,
} from '../learn-more';
import { useCancelContractToast } from '../../components/CancelContract';

interface BundleCardProps {
  pricebookBundle: PricebookBundle;
  selected: boolean;
  current?: boolean;
  setSelectedPricebook?: React.Dispatch<React.SetStateAction<PricebookBundle | undefined>>;
  currentLicense?: LicenseAgreementData;
  updateSignUpState: MutatorState<OrgSignUpStateData>;
  signUpState?: State;
  multiplePricebooks: boolean;
  style?: React.CSSProperties;
}

const BundleCard: React.FC<BundleCardProps> = ({
  pricebookBundle,
  selected,
  current,
  setSelectedPricebook,
  currentLicense,
  updateSignUpState,
  signUpState,
  multiplePricebooks,
  style,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const cancelContractToast = useCancelContractToast();

  const selectedPrice =
    pricebookBundle.prices.find(price => price.intervalType === IntervalTypeEnum.None) ||
    pricebookBundle.prices[0];
  const price = selectedPrice ? selectedPrice.price / 100 : 0;
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true';
  const meteredPayment = pricebookBundle.pricebook.paymentType === PaymentTypeEnum.Metered;
  const selectedStyle = selected && setSelectedPricebook && multiplePricebooks;

  const pendingAgreement = currentLicense?.docStatus && !currentLicense?.hasAccepted;
  const activeLicense = pendingAgreement || currentLicense?.docStatus;
  const activeContract = selected && activeLicense;

  return (
    <Card
      style={{ height: '295px', width: '538px', ...style }}
      variant={
        (current && !activeContract) || activeContract
          ? 'current'
          : selected && multiplePricebooks
          ? 'selected'
          : undefined
      }
      color='primary'>
      {pricebookBundle.pricebook.productType === ProductTypeEnum.Nickels ? (
        pricebookBundle.pricebook.pricebookName === '3Nickels Advice' ? (
          <LearnMoreAdviceDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
          />
        ) : (
          <LearnMoreThreeNickelsFreeDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
          />
        )
      ) : null}
      {pricebookBundle.pricebook.productType === ProductTypeEnum.NickelsU && (
        <LearnMoreAdviceAndThreeNickelsUDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          pricebookBundle={pricebookBundle}
        />
      )}
      {pricebookBundle.pricebook.productType === ProductTypeEnum.GuidedChoice && (
        <LearnMoreGuidedChoiceDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          pricebookBundle={pricebookBundle}
        />
      )}
      <CardContent className='packageCard' style={{ height: '100%' }}>
        <Row style={{ justifyContent: 'space-between', height: '100%' }}>
          {/* Left side */}
          <Column style={{ width: '45%', justifyContent: 'space-between' }}>
            <Spacer height='xs' />
            <Typography variant='p20SemiBold'>{pricebookBundle.pricebook.pricebookName}</Typography>
            <Spacer height='s15' />
            {pricebookBundle.pricebook.description.map((bullet, index) => (
              <Row key={index}>
                <Typography style={{ marginRight: '20px' }}>•</Typography>
                <Typography
                  variant='p14'
                  color={
                    !selected || !multiplePricebooks || current || activeContract
                      ? `secondary.dark`
                      : `white`
                  }>
                  {bullet}
                </Typography>
              </Row>
            ))}
            {setSelectedPricebook && multiplePricebooks && (
              <>
                <Spacer height='xs' />
                <Button
                  className='onDisabled'
                  sx={{
                    fontSize: '16px',
                  }}
                  disabled={current || activeContract}
                  onClick={async () => {
                    if (currentLicense?.docStatus) {
                      cancelContractToast(setToast);
                    } else if (setSelectedPricebook) {
                      setSelectedPricebook(prevState =>
                        prevState !== pricebookBundle ? pricebookBundle : undefined,
                      );
                      await updateSignUpState.action({
                        state: {
                          ...signUpState,
                          pricebook: JSON.stringify(pricebookBundle),
                        },
                      });
                    }
                  }}
                  color={current || activeContract ? 'secondary' : 'tertiary'}
                  variant={current || selected ? `outlined` : undefined}>
                  {current || activeContract ? 'Current' : selected ? `Selected` : `Select`}
                </Button>
              </>
            )}
            <Spacer height='xs' />
            <Button
              onClick={() => setDrawerOpen(true)}
              style={{ width: '100%' }}
              color='primary'
              className={
                activeContract || current
                  ? 'linkLearnMore'
                  : selectedStyle
                  ? 'activeLearnMore'
                  : 'learnMore'
              }>
              Learn more
            </Button>
          </Column>
          {/* Right side */}
          <Column style={{ width: '50%', alignItems: 'center', justifyContent: 'space-between' }}>
            {!selected || !multiplePricebooks || current || activeContract ? (
              <>
                <img
                  src={pricebookBundle.pricebook.logoUrl}
                  alt='Product Logo'
                  height='auto'
                  width='100%'
                />
                <Spacer height='xxs' />
                <img src={pricebookBundle.pricebook.artUrl} alt='Product Art' height='90px' />
                <Spacer height='xs' />
              </>
            ) : (
              <>
                <Svgs.DisplayLargeCustomCheckmark
                  style={{ width: '112px', height: 'auto', flexGrow: 1 }}
                />
              </>
            )}
            <Card
              sx={{ minWidth: '100px' }}
              variant={
                !selected || !multiplePricebooks || current || activeContract
                  ? 'innerCard'
                  : 'selected'
              }>
              <CardContent className='centered-inner'>
                <Column style={{ alignItems: 'center' }}>
                  <Row style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                    {freeTrial30Day ? (
                      <Typography
                        variant='p20SemiBold'
                        color={
                          !selected || !multiplePricebooks || current || activeContract
                            ? `secondary.dark`
                            : `white`
                        }>
                        30 days free
                      </Typography>
                    ) : (
                      <>
                        <Typography variant='p20SemiBold'>$</Typography>
                        <Typography
                          variant='p20SemiBold'
                          color={
                            !selected || !multiplePricebooks || current || activeContract
                              ? `secondary.dark`
                              : `white`
                          }>
                          {price}
                        </Typography>
                      </>
                    )}
                    {selectedPrice?.intervalType !== IntervalTypeEnum.None && !freeTrial30Day && (
                      <Typography variant='h3'>/{selectedPrice?.intervalType}</Typography>
                    )}
                  </Row>
                  <Row>
                    <Typography
                      variant='p12SemiBold'
                      color={
                        !selected || !multiplePricebooks || current || activeContract
                          ? `secondary`
                          : `white`
                      }>
                      {freeTrial30Day
                        ? `then $14.99/month`
                        : selectedPrice?.intervalType === IntervalTypeEnum.Month
                        ? `auto-renews monthly`
                        : selectedPrice?.intervalType === IntervalTypeEnum.Year
                        ? `auto-renews annually`
                        : selectedPrice?.price === 0
                        ? `free`
                        : `one-time payment`}
                      {meteredPayment && `*`}
                    </Typography>
                  </Row>
                </Column>
              </CardContent>
            </Card>
          </Column>
        </Row>
      </CardContent>
    </Card>
  );
};

export default BundleCard;
