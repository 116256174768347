export const ApiKeys = {
  AdminPayment: 'api/adminPayment',
  AuthUserApi: 'api/authUserApi',
  AuthUserAxios: 'api/authUserAxios',
  Charge: 'api/charge',
  Course: 'api/course',
  GooglePlaces: 'api/googlePlaces',
  Group: 'api/group',
  GroupFeatureMap: 'api/groupFeatureMap',
  GroupMetrics: 'api/groupMetrics',
  GroupSelection: 'api/groupSelection',
  Invoice: 'api/invoice',
  Lesson: 'api/lesson',
  LessonCategory: 'api/lessonCategory',
  LessonContent: 'api/lessonContent',
  LicenseAgreementAcceptLog: 'api/licenseAgreementAcceptLog',
  LicenseAgreement: 'api/licenseAgreement',
  LicenseMetrics: 'api/licenseMetrics',
  LmsPermission: 'api/lmsPermission',
  Media: 'api/media',
  Members: 'api/members',
  MendixClient: 'api/mendixClient',
  OffboardingTemplate: 'api/offboardingTemplate',
  OnboardingTemplate: 'api/onboardingTemplate',
  Organization: 'api/organization',
  OrganizationMetadata: 'api/organizationMetadata',
  CustomerOrganization: 'api/customerOrganization',
  OrgPricebookLicenseMetrics: 'api/orgPricebookLicenseMetrics',
  OrgSelection: 'api/orgSelection',
  OrgSignUpState: 'api/orgSignUpState',
  PayrollDeduction: 'api/payrollDeduction',
  Plan: 'api/plan',
  Pricebook: 'api/pricebook',
  Quiz: 'api/quiz',
  QuizQuestion: 'api/quizQuestion',
  Report: 'api/report',
  Restriction: 'api/restriction',
  RevenueShare: 'api/revenueShare',
  Securities: 'api/securities',
  Subscription: 'api/subscription',
  Team: 'api/team',
  TeamMetrics: 'api/teamMetrics',
  TeamSelection: 'api/teamSelection',
  Terms: 'api/terms',
  TrustFamily: 'api/trustFamily',
  Unit: 'api/unit',
  User: 'api/user',
};
