import { Disposable, ScriniumServices } from '@aesop-fables/scrinium';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import { Scopes, createServiceModule, inject } from '@aesop-fables/containr';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { InitialPlanWizardState, PlanWizardState } from '../../models/PlanData';

export const planWizardKey = 'services/planWizard';

class PlanWizard implements Disposable {
  private state = new BehaviorSubject<PlanWizardState[]>([]);

  constructor(
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
  ) {}

  get state$(): Observable<PlanWizardState | undefined> {
    const orgDataCache = this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const orgData = (
      orgDataCache.findCompartment('organization') as DataCompartment<OrganizationData>
    ).getData() as OrganizationData;
    return this.state
      .asObservable()
      .pipe(map(wizardState => wizardState.find(state => state.organizationId === orgData.id)));
  }

  setState(newState: PlanWizardState): PlanWizardState {
    const orgDataCache = this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
    const orgData = (
      orgDataCache.findCompartment('organization') as DataCompartment<OrganizationData>
    ).getData() as OrganizationData;

    const current = this.state.getValue();
    const orgIndex = current.findIndex(wizardState => wizardState.organizationId === orgData.id);
    let updatedState: PlanWizardState[];

    if (orgIndex !== -1) {
      updatedState = current.map((wizardState, index) =>
        index === orgIndex ? { ...wizardState, planData: newState.planData } : wizardState,
      );
    } else {
      updatedState = [...current, { planData: newState.planData, organizationId: orgData.id }];
    }

    this.state.next(updatedState);

    const planWizardData = updatedState.find(
      wizardState => wizardState.organizationId === orgData.id,
    );
    if (planWizardData) {
      return planWizardData;
    } else {
      return InitialPlanWizardState;
    }
  }

  reset() {
    this.state.next([]);
  }

  dispose(): void {
    console.log('disposing PlanWizard');
  }
}

export const withPlanWizard = createServiceModule(planWizardKey, services => {
  services.autoResolve<PlanWizard>(planWizardKey, PlanWizard, Scopes.Singleton);
});

export { PlanWizard };
