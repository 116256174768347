import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ActivePaymentMethodData,
  AdminPaymentApi,
  PaymentMethodData,
  StripeSessionStatus,
} from '../../../api/apis/AdminPaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { ChargeApi, ChargeData } from '../../../api/apis/ChargeApi';
import { SubscriptionData } from '../../../api/apis/SubscriptionApi';
import { ToastbarContext } from '../../../App';
import { Page, Spacer } from '../../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { PaymentCompartments, paymentStorageKey } from '../../../data/payment';
import {
  DeletePaymentMethod,
  EnableAutoPay,
  SetDefaultPaymentMethod,
} from '../../../data/payment/mutations';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../../data/subscription';
import { Toast } from '../../../models/Toast';
import PastInvoiceDrawer from '../past-invoice-drawer/PastInvoiceDrawer';
import { InvoiceCompartments, invoiceStorageKey } from '../../../data/invoices';
import { InvoiceData, InvoiceStatusEnum } from '../../../api/apis/InvoiceApi';
import UnpaidInvoiceCard from '../UnpaidInvoiceCard';
import AutoPayDrawer from '../auto-pay-drawer/AutoPayDrawer';
import { LicenseAgreementData } from '../../../api/apis/LicenseAgreementApi';
import { LicenseCompartments, licenseStorageKey } from '../../../data/license';
import { AdminCompartments, adminStorageKey } from '../../../data/admin';
import { UserData } from '../../../models/UserData';
import {
  CustomerOrganizationCompartments,
  customerOrganizationStorageKey,
} from '../../../data/customerOrganization';
import { CustomerOrganizationData } from '../../../models/CustomerOrganizationData';
import { UserCompartments, userStorageKey } from '../../../data/user';
import { useAdminFilters } from '../../../hooks/useAdminHooks';
import Invoices from './Invoices';
import PaymentMethods from './PaymentMethods';
import { useCommands } from '../../../helpers/useCommands';
import { GetInvoiceTotal } from '../../../data/invoices/commands/GetInvoiceTotal';
import { BillingAdminManager } from '../../multitier/BillingAdmin';

const Payment = () => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const { filterAdmins } = useAdminFilters();
  const commands = useCommands();

  const adminPaymentApi = useService<AdminPaymentApi>(ApiKeys.AdminPayment);
  const chargeApi = useService<ChargeApi>(ApiKeys.Charge);
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const invoiceDataCache = appStorage.retrieve<InvoiceCompartments>(invoiceStorageKey);
  const customerOrgDataCache = appStorage.retrieve<CustomerOrganizationCompartments>(
    customerOrganizationStorageKey,
  );
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);

  const setDefaultPaymentMethod = useMutation(new SetDefaultPaymentMethod());
  const deletePaymentMethod = useMutation(new DeletePaymentMethod());
  const enableAutoPay = useMutation(new EnableAutoPay());

  const defaultPaymentMethodId = useObservable(
    paymentDataCache.observe$<string>('defaultPaymentMethod'),
  );
  const paymentMethods = useObservable(
    paymentDataCache.observe$<PaymentMethodData[]>('paymentMethods'),
  );
  const stripeSessionStatus = useObservable(
    paymentDataCache.observe$<StripeSessionStatus>('stripeSessionStatus'),
  );
  const customerOrganization = useObservable(
    customerOrgDataCache.observe$<CustomerOrganizationData>('customerOrganization'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const orgAdminsData = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins'));
  const subscriptionData = useObservable(
    subscriptionDataCache.observe$<SubscriptionData[]>('subscription'),
  );
  const orgAdmins = useMemo(() => orgAdminsData ?? [], [orgAdminsData]);
  const subscriptions = useMemo(() => subscriptionData ?? [], [subscriptionData]);

  const paymentHistory = useObservable(paymentDataCache.observe$<ChargeData[]>('paymentHistory'));
  const invoices = useObservable(invoiceDataCache.observe$<InvoiceData[]>('invoices')) ?? [];
  const currentUser = useObservable(userDataCache.observe$<UserData>('user'));

  const [paymentHistoryOpen, setPaymentHistoryOpen] = useState(false);
  const [autoPayOpen, setAutoPayOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<ChargeData | undefined>(undefined);
  const [failedInvoices, setFailedInvoices] = useState<SubscriptionData[]>([]);
  const [invoiceTotal, setInvoiceTotal] = useState<number>();
  const filteredAdmins = filterAdmins(orgAdmins, currentUser);

  const activeOrgPricebooks = useMemo(() => {
    return licenseAgreements?.filter(
      license => license.docStatus && license.pricebookBundleDto.pricebook.paidBy === 'ORG',
    );
  }, [licenseAgreements]);

  const defaultPaymentMethod = useMemo(() => {
    return paymentMethods?.find(method => method.id === defaultPaymentMethodId);
  }, [paymentMethods, defaultPaymentMethodId]);

  const autoPay = useMemo(() => {
    return customerOrganization?.automaticPayment;
  }, [customerOrganization]);

  const onViewPastInvoice = (row: { invoiceNumber: string }) => {
    const data = paymentHistory?.find(
      transaction => transaction?.charge?.invoiceId === row.invoiceNumber,
    );
    if (data) {
      setSelectedInvoice(data);
      setPaymentHistoryOpen(true);
    }
  };

  const onSetUpAutoPay = async () => {
    if (paymentMethods && paymentMethods.length > 0) {
      if (invoices?.some(invoice => invoice.status === InvoiceStatusEnum.Open)) {
        const total = await commands.execute(GetInvoiceTotal, {});
        setInvoiceTotal(total);
      }
      setAutoPayOpen(true);
    } else {
      setToast(
        new Toast({
          message: 'Please add a payment method before setting up auto-pay',
          open: true,
          severity: 'error',
        }),
      );
    }
  };

  const onViewReceipt = async (row: { id: number }) => {
    const invoiceData = invoices?.find(invoice => invoice.id === row.id);

    const matchingCharge = paymentHistory?.find(
      payment => payment.charge?.invoiceId === invoiceData?.invoiceNumber,
    );

    if (matchingCharge) {
      const { data: receiptUrl } = await chargeApi.getReceiptUrl(matchingCharge?.charge.id);
      window.open(receiptUrl, '_blank');
    }
  };

  const onPayInvoice = (row: { id: number }) => {
    const invoiceData = invoices?.find(invoice => invoice.id === row.id);

    if (invoiceData) {
      window.open(invoiceData.invoiceUrl, '_blank');
    }
  };

  const onSetAsDefault = async (row: any) => {
    try {
      const newRow = row as PaymentMethodData;
      await setDefaultPaymentMethod.action(newRow.id);
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} set as default!`,
          severity: 'success',
          open: true,
        }),
      );
    } catch (err) {
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} could not be set as default`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const onAddPayment = async () => {
    const setupIntentUrl = await adminPaymentApi.setupUrl();
    window.location.replace(setupIntentUrl.data);
  };

  const onDelete = async (row: any) => {
    try {
      const newRow = row as PaymentMethodData;
      await deletePaymentMethod.action(newRow.id);
    } catch (err) {
      setToast(
        new Toast({
          message: `${(row as ActivePaymentMethodData).name} could not removed`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    await enableAutoPay.action();
    await customerOrgDataCache.reload('customerOrganization');
    setAutoPayOpen(false);
    setToast(
      new Toast({
        message: 'Auto-pay set up successfully!',
        severity: 'success',
        open: true,
      }),
    );
  };

  useEffect(() => {
    setFailedInvoices(subscriptions.filter(subscription => subscription.paymentFailed));
  }, [subscriptions]);

  return (
    <Page title='Payment'>
      <Spacer height='xxs' />
      <BillingAdminManager admins={filteredAdmins} currentUser={currentUser} />

      <PastInvoiceDrawer
        data={selectedInvoice}
        drawerOpen={paymentHistoryOpen}
        setDrawerOpen={setPaymentHistoryOpen}
      />

      <AutoPayDrawer
        invoices={invoices}
        invoiceTotal={invoiceTotal}
        activeOrgPricebooks={activeOrgPricebooks}
        defaultPaymentMethod={defaultPaymentMethod}
        drawerOpen={autoPayOpen}
        setDrawerOpen={setAutoPayOpen}
        handleSubmit={handleSubmit}
      />
      <Spacer height='xs' />

      {failedInvoices.map(invoice => (
        <>
          <UnpaidInvoiceCard subscription={invoice} />
          <Spacer height='xs' />
        </>
      ))}

      <PaymentMethods
        paymentMethods={paymentMethods}
        defaultPaymentMethodId={defaultPaymentMethodId}
        stripeSessionStatus={stripeSessionStatus}
        onAddPayment={onAddPayment}
        onSetAsDefault={onSetAsDefault}
        onDelete={onDelete}
      />

      <Invoices
        invoices={invoices}
        autoPay={autoPay}
        onSetUpAutoPay={onSetUpAutoPay}
        onViewPastInvoice={onViewPastInvoice}
        onViewReceipt={onViewReceipt}
        onPayInvoice={onPayInvoice}
      />
    </Page>
  );
};

export default Payment;
