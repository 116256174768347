import { Button, Typography } from '@mui/material';
import { Row } from '../../components';

interface MembersOffboardingHeaderProps {
  onBulkAdd: () => void;
  setAddMemberDrawer: (value: React.SetStateAction<boolean>) => void;
}

const MembersOffboardingHeader: React.FC<MembersOffboardingHeaderProps> = ({
  onBulkAdd,
  setAddMemberDrawer,
}) => {
  return (
    <>
      <Typography variant='p24SemiBold' color='secondary.main'>
        Add members
      </Typography>
      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: '10px',
          marginBottom: '10px',
        }}>
        <Typography variant='p18' color='info.main'>
          Add and manage members individually or by uploading a bulk file.
        </Typography>
        <Row>
          <Button
            onClick={onBulkAdd}
            style={{ marginRight: '20px', width: '119px', height: '32px' }}
            color='secondary'>
            <Typography variant='p12'>Bulk add</Typography>
          </Button>
          <Button
            onClick={() => setAddMemberDrawer(true)}
            color='primary'
            style={{ width: '119px', height: '32px' }}>
            <Typography variant='p12'>Add a member</Typography>
          </Button>
        </Row>
      </Row>
    </>
  );
};

export default MembersOffboardingHeader;
