import { OrganizationData } from '../../models/OrganizationData';
import {
  DataCompartmentOptions,
  ConfiguredDataSource,
  createDataCache,
  createDataCacheModule,
  IAppStorage,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { OrganizationApi } from '../../api/apis/OrganizationApi';
import {
  OrganizationMetricsData,
  OrgPricebookLicenseMetrics,
} from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { UserInitialized } from '../../predicates/UserInitialized';
import { TeamSelected } from '../../predicates/TeamSelected';
import { PricebookApi } from '../../api/apis/PricebookApi';

export interface OrganizationCompartments {
  organization: DataCompartmentOptions<OrganizationData | undefined>;
  allOrganizations: DataCompartmentOptions<OrganizationData[] | undefined>;
  allMetrics: DataCompartmentOptions<OrganizationMetricsData[] | undefined>;
}

export const organizationStorageKey = 'data/organization';

export const withOrganizationData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<OrganizationCompartments>({
      organization: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(UserInitialized),
        },
        source: new ConfiguredDataSource(async () => {
          const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);
          const pricebookApi = container.get<PricebookApi>(ApiKeys.Pricebook);

          const { data } = await organizationApi.get();
          if (!data || !data.id) {
            // when an org isn't selected, will return 200 with empty response
            return undefined;
          }

          const selectedPricebook = await pricebookApi.getSelected();
          const urlResult =
            selectedPricebook.data.length > 0 ? await organizationApi.getUrl() : undefined;
          const qrUri = organizationApi.getQR(data.id);
          return { ...data, ...urlResult?.data, qrUri };
        }),
        defaultValue: undefined,
      },
      allOrganizations: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(TeamSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const organizationApi = container.get<OrganizationApi>(ApiKeys.Organization);
            const { data } = await organizationApi.getAll();
            return [...data];
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
      allMetrics: {
        loadingOptions: {
          strategy: 'auto',
          predicate: container.resolve(TeamSelected),
        },
        source: new ConfiguredDataSource(async () => {
          try {
            const orgPricebookLicenseMetricsApi = container.get<OrgPricebookLicenseMetrics>(
              ApiKeys.OrgPricebookLicenseMetrics,
            );

            const { data } = await orgPricebookLicenseMetricsApi.get();
            return [...data];
          } catch (err) {
            return undefined;
          }
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(organizationStorageKey, cache);
  },
);
