import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CourseData,
  LessonData,
  PublicationStatusEnum,
  UnitData,
} from '../../../../models/CourseData';
import { Card, Grid, IconButton, Link, Typography, useTheme } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import DraggableItemProps from '../../../../components/DraggableItemProps';
import LessonMenu from './UnitLessonMenu';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { SemiBold } from '../../../../components/SemiBold';
import { useService } from '@aesop-fables/containr-react';
import { IObservableQueryDispatcher, ScriniumServices } from '@aesop-fables/scrinium';
import { FindDraftByLessonId } from '../../../../data/learning/queries/FindDraftByLesson';
import { StatusDotRed } from '../../../../components/StatusDotRed';
import { Spacer } from '../../../../components';
import { useSelectedLessonContext } from '../lessons/SelectedLessonContext';

interface UnitLessonProps extends DraggableItemProps {
  course: CourseData | undefined;
  unit: UnitData;
  lesson: LessonData;
  editLesson: (lesson: LessonData) => void;
  disabled: boolean;
}

const UnitLesson = forwardRef<HTMLElement, UnitLessonProps>(
  ({ course, unit, lesson, editLesson, disabled, dragProps, dragHandleProps }, ref) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { selectedLessonId, selectLesson } = useSelectedLessonContext();

    const isAllPublished = useMemo(
      () =>
        lesson?.status === PublicationStatusEnum.PUBLISHED &&
        unit?.status === PublicationStatusEnum.PUBLISHED &&
        course?.status === PublicationStatusEnum.PUBLISHED,
      [lesson?.status, unit?.status, course?.status],
    );

    const viewLesson = useCallback(() => {
      navigate(`unit/${lesson.unitId}/lesson/${lesson.originalLessonId}`);
    }, [lesson.originalLessonId, lesson.unitId, navigate]);

    const [publishedWithDraft, setPublishedWithDraft] = useState(false);
    const dispatcher = useService<IObservableQueryDispatcher>(ScriniumServices.QueryDispatcher);
    const isLessonPublished = lesson.status === PublicationStatusEnum.PUBLISHED;
    const isUnitPublished = unit.status === PublicationStatusEnum.PUBLISHED;

    useEffect(() => {
      const checkDraft = async () => {
        const draft = await dispatcher.execute(FindDraftByLessonId, {
          unitId: lesson.unitId!,
          originalLessonId: lesson.originalLessonId!,
        });
        const hasDraft = draft !== null && typeof draft !== 'undefined';
        setPublishedWithDraft(hasDraft);
      };

      if (isLessonPublished) {
        checkDraft();
      }
    }, [lesson, dispatcher, isLessonPublished]);

    useEffect(() => {
      if (selectedLessonId && lesson.originalLessonId === selectedLessonId) {
        const element = document.getElementById(selectedLessonId.toString());
        if (element) {
          const topOffset = 80;
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - topOffset;

          // scroll to selected lesson
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }

        // cleanup
        selectLesson(undefined);
      }
    }, [lesson.originalLessonId, selectLesson, selectedLessonId]);

    return (
      <Card
        className={`unit-lesson-card ${disabled ? 'disabled' : ''}`}
        ref={ref as any}
        {...(disabled ? {} : dragProps)}
        variant='ghost'
        color='secondary'
        onClick={viewLesson}>
        <Grid
          id={lesson.originalLessonId?.toString()}
          container
          sx={{ p: 2, justifyContent: 'space-between' }}>
          <Grid
            item
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              minWidth: 0,
            }}>
            <IconButton {...(disabled ? {} : dragHandleProps)} disableRipple>
              <Svgs.IconGrip className='grip' fill={theme.palette.secondary.light} />
            </IconButton>
            <Link
              variant='p16Bold'
              color='secondary'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
              }}>
              {lesson.name}
            </Link>
            {isAllPublished ? (
              <Svgs.IconCheckmarkGrey style={{ flexShrink: 0, marginRight: '20px' }} />
            ) : (
              <Spacer width='xxs' />
            )}
          </Grid>

          <Grid
            item
            sx={{
              display: 'flex',
              flexShrink: 0,
              alignItems: 'center',
              justifyContent: 'flex-end',
              minWidth: 0,
              columnGap: '20px',
            }}>
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexShrink: 0,
                fontStyle: isLessonPublished ? 'normal' : 'italic',
              }}>
              <Typography variant='p16' color='secondary.main'>
                {!isLessonPublished ? (
                  <Grid display='flex'>
                    Draft
                    <StatusDotRed style={{ marginLeft: '6px', marginTop: '8px' }} />
                  </Grid>
                ) : (
                  <Grid display='flex'>
                    {isUnitPublished ? <SemiBold>Published</SemiBold> : `Published`}
                    {publishedWithDraft && (
                      <>
                        <span style={{ fontStyle: 'italic' }}>&nbsp;(draft open)</span>
                        <StatusDotRed style={{ marginLeft: '8px', marginTop: '8px' }} />
                      </>
                    )}
                  </Grid>
                )}
              </Typography>
            </Grid>

            <Typography variant='p16' color='info.main'>
              |
            </Typography>

            <Grid
              sx={{
                display: 'flex',
                flexBasis: '180px',
                flexShrink: 0,
                minWidth: 0,
              }}>
              <Typography
                variant='p16'
                color='info.main'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {isLessonPublished
                  ? `Published on ${dayjs(lesson.publishedTimestamp).format('MM/DD/YY')}`
                  : `Last edited on ${dayjs(lesson.lastEditedTimestamp).format('MM/DD/YY')}`}
              </Typography>
            </Grid>
            <Grid display='flex' onClick={e => e.stopPropagation()}>
              <LessonMenu lesson={lesson} editLesson={editLesson} disabled={disabled} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  },
);

export default UnitLesson;
