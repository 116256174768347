import { IMutation, MutationContext } from '@aesop-fables/scrinium';
import { MembersApi } from '../../../api/apis/MembersApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MemberCompartments, membersStorageKey } from '..';
import { OrganizationCompartments, organizationStorageKey } from '../../organization';

export class AddAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.addAMember({ ...data });

    const dataCache = storage.retrieve<MemberCompartments>(membersStorageKey);
    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await Promise.all([dataCache.reload('members'), orgDataCache.reload('allMetrics')]);
  }
}

export class RemoveAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.removeMember(data);

    const dataCache = storage.retrieve<MemberCompartments>(membersStorageKey);
    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await Promise.all([dataCache.reload('members'), orgDataCache.reload('allMetrics')]);
  }
}

export class EditAMember implements IMutation<any> {
  async execute(context: MutationContext<any>): Promise<void> {
    const { data, storage, container } = context;
    const membersApi = container.get<MembersApi>(ApiKeys.Members);

    await membersApi.editMember(data);

    const dataCache = storage.retrieve<MemberCompartments>(membersStorageKey);
    const orgDataCache = storage.retrieve<OrganizationCompartments>(organizationStorageKey);

    await Promise.all([dataCache.reload('members'), orgDataCache.reload('allMetrics')]);
  }
}
