import React, { useState, useContext } from 'react';
import { Page } from '../../components';
import dayjs, { Dayjs } from 'dayjs';
import { useLoading } from '../../hooks/useLoading';
import { useService } from '@aesop-fables/containr-react';
import { useObservable } from '@aesop-fables/scrinium';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { ToastbarContext } from '../../App';
import { Button, Grid, Typography } from '@mui/material';
import DatePicker from '../../components/DatePicker';
import { Toast } from '../../models/Toast';
import { useCommands } from '../../helpers/useCommands';
import {
  GenerateTeamCharges,
  GenerateOrgCharges,
  GenerateGroupCharges,
  GenerateTeamRevShare,
  GenerateOrgRevShare,
  GenerateGroupRevShare,
} from '../../data/rev-share/commands';

type ReportType = 'charges' | 'revShare';

const RevenueShare: React.FC = () => {
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const today = dayjs().endOf('day');

  const downloadCsvFile = (data: any, filePrefix: string) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${filePrefix}-${startDate?.format('YYYY-MM-DD')}-${endDate?.format('YYYY-MM-DD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const getCommandForTier = (reportType: ReportType) => {
    switch (tier) {
      case AdminTierTypeEnum.Team:
        return reportType === 'charges' ? GenerateGroupCharges : GenerateGroupRevShare;
      case AdminTierTypeEnum.Organization:
        return reportType === 'charges' ? GenerateTeamCharges : GenerateTeamRevShare;
      case AdminTierTypeEnum.Root:
        return reportType === 'charges' ? GenerateOrgCharges : GenerateOrgRevShare;
      default:
        return reportType === 'charges' ? GenerateTeamCharges : GenerateTeamRevShare;
    }
  };

  const handleCharges = async () => {
    if (!startDate || !endDate) {
      setToast(
        new Toast({
          message: 'Please select both start and end dates',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      return;
    }

    setLoading(true);
    try {
      const dateRange = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      };

      const command = getCommandForTier('charges');
      const response = await commands.execute(command, dateRange);

      if (!response?.data) {
        throw new Error('No data received in response');
      }

      downloadCsvFile(response.data, 'charges');

      setToast(
        new Toast({
          message: 'Charges report generated successfully!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } catch (error: any) {
      setToast(
        new Toast({
          message: 'Failed to generate charges report',
          severity: 'error',
          open: true,
          autoHideDuration: 5000,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRevShare = async () => {
    if (!startDate || !endDate) {
      setToast(
        new Toast({
          message: 'Please select both start and end dates',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      return;
    }

    setLoading(true);
    try {
      const dateRange = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      };

      const command = getCommandForTier('revShare');
      const response = await commands.execute(command, dateRange);

      if (!response?.data) {
        throw new Error('No data received in response');
      }

      downloadCsvFile(response.data, 'revenue-share');

      setToast(
        new Toast({
          message: 'Revenue share report generated successfully!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } catch (error: any) {
      setToast(
        new Toast({
          message: 'Failed to generate revenue share report',
          severity: 'error',
          open: true,
          autoHideDuration: 5000,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page>
      <Grid container xs flexDirection='column' rowGap={5}>
        <Grid display='flex' flex={1} alignItems='flex-end'>
          <Typography variant='p40' color='secondary.main'>
            Revenue Share Reports
          </Typography>
        </Grid>
        <Grid item xs={4} display='flex' alignItems='center' mb={2}>
          <Grid container xs flexDirection='row'>
            <Typography>Start Date</Typography>
            <DatePicker initialDate={startDate} onDateChange={setStartDate} maxDate={today} />
          </Grid>
          <Grid container xs flexDirection='row'>
            <Typography>End Date</Typography>
            <DatePicker initialDate={endDate} onDateChange={setEndDate} maxDate={today} />
          </Grid>
        </Grid>
        <Grid item container xs={2} flexDirection='column' rowGap={2}>
          <Button onClick={handleCharges}>Charges</Button>
          <Button onClick={handleRevShare}>Revenue Shares</Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RevenueShare;
