import React from 'react';
import { Button, Link, Typography, Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { signUpWizardKey, SignUpWizard as SignUpWizardService } from '../services/signUp';
import { OffboardingWizard, offboardingWizardKey } from '../services/offboarding';
import { UpdateSignUpState } from '../data/signUp/mutations';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData } from '../models/SignUpData';
import { PricebookBundle } from '../models/PricebookData';
import { useLoading } from '../hooks/useLoading';
import { logo3NSmall, logoGcSmall } from '../assets/png';
import CongratsTodoList from './CongratsToDoList';

const Congrats = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const signUpWizard = useService<SignUpWizardService>(signUpWizardKey);
  const offboardingWizard = useService<OffboardingWizard>(offboardingWizardKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);

  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const pricebook: PricebookBundle =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state?.pricebook);
  const product = pricebook?.pricebook?.productType;

  const gcPackage = product === 'GUIDED_CHOICE';
  const logo = gcPackage ? logoGcSmall : logo3NSmall;
  const logoAlt = gcPackage ? 'GuidedChoice Logo' : '3Nickels Logo';

  const onSkip = async () => {
    try {
      setLoading(true);
      signUpWizard.agreeAndSignUp();
      await updateSignUpState.action({
        lastCompletedStep: signUpWizard.getCurrent(location),
        onboardingComplete: true,
      });
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  const onStart = async () => {
    signUpWizard.agreeAndSignUp();
    await updateSignUpState.action({
      lastCompletedStep: signUpWizard.getCurrent(location),
      onboardingComplete: true,
    });
    const next = offboardingWizard.nextStep(location);
    navigate(next.route);
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', px: 4, py: 3 }}>
      <Grid container direction='column' spacing={4}>
        <Grid item container justifyContent='flex-end'>
          <img src={logo} alt={logoAlt} style={{ width: '25%', height: 'auto' }} />
        </Grid>

        <Grid item container flexDirection='column'>
          <Typography variant='p45' color='secondary.main' gutterBottom>
            Congratulations!
          </Typography>
          <Typography variant='p20' color='secondary.dark' sx={{ mb: 2 }}>
            All that's left is setting up your members' custom sign up page.
          </Typography>
        </Grid>

        <Grid item>
          <Grid item container flexDirection='column' spacing={8}>
            <Grid item>
              <CongratsTodoList />
            </Grid>
            <Grid item container spacing={4} justifyContent='flex-end' alignItems='center'>
              <Grid item>
                <Link variant='p18' color='primary.main' onClick={onSkip}>
                  Skip for now
                </Link>
              </Grid>
              <Grid item>
                <Button
                  onClick={onStart}
                  color='primary'
                  variant='contained'
                  style={{ minWidth: '150px' }}>
                  Start
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Congrats;
