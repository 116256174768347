import { Typography, Button, Grid } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import { InvoiceData, InvoiceStatusEnum } from '../../../api/apis/InvoiceApi';
import { Row, Spacer, DataTable } from '../../../components';
import { sortByInvoiceStatus, sortByDate } from '../../../helpers/sortUtils';
import { formatDollars, formatDateMMDDYYYY } from '../../../helpers/utilityFunctions';

const invoiceHeaders = [
  { title: 'Invoice #', label: 'invoiceNum' },
  { title: 'Invoice Date', label: 'date' },
  { title: 'Description', label: 'description' },
  { title: 'Amount', label: 'amount' },
  { title: 'Status', label: 'statusDisplay' },
];

interface InvoicesProps {
  invoices: InvoiceData[];
  autoPay?: boolean;
  onSetUpAutoPay: () => void;
  onViewPastInvoice: (val: any) => void;
  onViewReceipt: (val: any) => void;
  onPayInvoice: (val: any) => void;
}

const Invoices: React.FC<InvoicesProps> = ({
  invoices,
  autoPay,
  onSetUpAutoPay,
  onViewPastInvoice,
  onViewReceipt,
  onPayInvoice,
}) => {
  const isOpen = (invoice: InvoiceData): boolean => {
    return invoice.status.toLowerCase() === InvoiceStatusEnum.Open.toLowerCase();
  };
  const isPaid = (invoice: InvoiceData): boolean => {
    return invoice.status.toLowerCase() === InvoiceStatusEnum.Paid.toLowerCase();
  };

  if (!invoices) return null;

  return (
    <>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Invoices
        </Typography>
        {autoPay ? (
          <Typography variant='p16' color='secondary' fontStyle='italic'>
            auto-pay set for the end of the month
          </Typography>
        ) : (
          <Button onClick={onSetUpAutoPay} color='primary' style={{ fontSize: 14 }}>
            Set up auto-pay
          </Button>
        )}
      </Row>
      <Spacer height='xxs' />
      <DataTable
        headers={invoiceHeaders}
        sortBy='date'
        customSort={[
          {
            column: 'statusDisplay',
            function: (a, b) => {
              // Order by status, then by date
              return sortByInvoiceStatus(a.status, b.status) || sortByDate(a.date, b.date);
            },
          },
        ]}
        data={
          invoices?.map(invoice => ({
            id: invoice.id,
            invoiceNum: invoice.invoiceNumber,
            date: formatDateMMDDYYYY(invoice.createdTimestamp),
            description: invoice.description ? `${invoice.description} Licenses` : 'N/A',
            amount: formatDollars(invoice.amount / 100.0),
            status: invoice.status, // for sorting
            statusDisplay: (
              <Grid>
                <Typography variant='p16'>{capitalize(invoice.status)}</Typography>
                {isPaid(invoice) && invoice.paidAtTimestamp && (
                  <Typography variant='body2' color='secondary'>
                    {formatDateMMDDYYYY(invoice.paidAtTimestamp)}
                  </Typography>
                )}
              </Grid>
            ),
            deletableRow: false,
          })) ?? []
        }
        onButton1Click={onPayInvoice}
        button1Text='Pay'
        ellipsesItems={[
          {
            label: 'View invoice',
            onClick: onViewPastInvoice,
          },
          {
            label: 'View receipt',
            onClick: onViewReceipt,
          },
        ]}
        lastColumnElement={row => (isOpen(row) ? 'button' : isPaid(row) ? 'ellipsis' : 'none')}
        lastColumnStyles={{ justifyContent: 'center' }}
        itemsPerPage={20}
        emptyStateText={'You do not have any invoices.'}
      />
    </>
  );
};

export default Invoices;
