import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class InvoiceApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<InvoiceData[]>> {
    return this.axios.get('invoice/all');
  }

  getInvoiceTotal(): Promise<AxiosResponse<number>> {
    return this.axios.get('invoice/total-amount');
  }
}

export interface InvoiceData {
  id: number;
  invoiceId: string;
  subscriptionId: number;
  amount: number;
  createdTimestamp: string;
  paidAtTimestamp: string;
  invoiceUrl: string;
  status: InvoiceStatusEnum;
  invoiceNumber: string;
  description: string;
}

export enum InvoiceStatusEnum {
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}
