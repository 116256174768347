import { DataCache, IDataCommand, ScriniumServices, injectDataCache } from '@aesop-fables/scrinium';
import type { ICommandExecutor } from '@aesop-fables/scrinium';
import { LearningCompartments, learningStorageKey } from '..';
import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { CourseData, QuizData, QuizQuestion, UnitData } from '../../../models/CourseData';
import { findQuizQuestionSource, updateCourses } from './utils';
import { QuizQuestionApi } from '../../../api/apis/QuizQuestionApi';
import { LessonApi } from '../../../api/apis/LessonApi';

export class RemoveQuizQuestion implements IDataCommand<QuizQuestion, QuizQuestion> {
  constructor(
    @injectDataCache(learningStorageKey) private readonly cache: DataCache<LearningCompartments>,
    @inject(ApiKeys.QuizQuestion) private readonly quizQuestionApi: QuizQuestionApi,
    @inject(ApiKeys.Lesson) private readonly lessonApi: LessonApi,
    @inject(ScriniumServices.CommandExecutor) private readonly commands: ICommandExecutor,
  ) {}

  async execute(question: QuizQuestion): Promise<QuizQuestion> {
    let response: QuizQuestion | undefined;

    // need to update status for all linked versions.
    await this.cache.modify<CourseData[]>('courses', async courses => {
      const { courseIndex, unitIndex, lessonIndex } = findQuizQuestionSource(question, courses);
      if (
        typeof courseIndex !== 'undefined' &&
        typeof unitIndex !== 'undefined' &&
        typeof lessonIndex !== 'undefined'
      ) {
        const newUnits = [...(courses[courseIndex].units as UnitData[])];
        const newUnit = newUnits[unitIndex];
        if (newUnit.lessons) {
          const newLessons = [...newUnit.lessons];
          const newLesson = newLessons[lessonIndex];
          const newQuiz = newLesson.quiz as QuizData;
          if (question.questionId && newLesson.lessonId) {
            await this.quizQuestionApi.deleteQuestion(question.questionId);
            const { data } = await this.lessonApi.getLesson(newLesson.lessonId);
            const updatedQuestions = newQuiz.questions?.filter(
              x => x.questionId !== question.questionId,
            );
            newQuiz.questions = updatedQuestions;

            newLesson.quiz = newQuiz;
            newLesson.lastEditedTimestamp = data.lastEditedTimestamp;
            newLessons[lessonIndex] = newLesson;

            newUnit.lessons = newLessons;

            const newCourses = updateCourses(newUnit, courses);

            return newCourses;
          }
        }
      }
      return courses;
    });

    return response as QuizQuestion;
  }
}
