import { Card, CardContent, Typography, Button } from '@mui/material';
import { logoGcSmall, logo3NSmall } from '../../assets/png';
import { Svgs } from '../../assets/svg';
import { Row, Column } from '../../components';
import { ProductTypeEnum } from '../../models/PricebookData';

interface ProductCardProps {
  handleChangeProduct: () => void;
  product: string;
  multipleProductTypesAvailable: boolean;
  cost?: number;
}
const bullets = {
  gc: ['• Retirement planning', '• Retirement saving and spending', '• Best for desktop/laptop'],
  nickels: ['• Financial planning', '• Robust financial education', '• Mobile exclusive'],
  nickelsU: [
    `• 2 months' free access to the 3Nickels Advice app*`,
    '• 1 financial coaching session',
    `• 1 year's access to 3NickelsU`,
  ],
  nickelsFree: [
    `• A suite of DIY tools to help you get answers to your questions`,
    '• Save your data while you work through your options',
    '• Free means free',
  ],
};

const ProductCard: React.FC<ProductCardProps> = ({
  handleChangeProduct,
  product,
  multipleProductTypesAvailable,
  cost,
}) => {
  return (
    <Card color='primary'>
      <CardContent className='pad30'>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {product === ProductTypeEnum.GuidedChoice ? (
            <img
              style={{ width: '20%', paddingTop: '10px' }}
              src={logoGcSmall}
              alt='GuidedChoice Logo'
            />
          ) : (
            <img style={{ width: '20%' }} src={logo3NSmall} alt='3Nickels Logo' />
          )}
          {product === ProductTypeEnum.GuidedChoice ? (
            <Svgs.ArtRetirementLarge />
          ) : (
            <Svgs.ArtPieChart />
          )}
          <Column>
            {product === ProductTypeEnum.GuidedChoice
              ? bullets.gc.map(bullet => (
                  <Typography
                    key={bullets.gc.indexOf(bullet)}
                    variant='body1'
                    color='secondary.dark'>
                    {bullet}
                  </Typography>
                ))
              : bullets.nickels.map(bullet => (
                  <Typography
                    key={bullets.nickels.indexOf(bullet)}
                    variant='body1'
                    color='secondary.dark'>
                    {bullet}
                  </Typography>
                ))}
          </Column>
          {multipleProductTypesAvailable && (
            <Button
              style={{ alignSelf: 'flex-start' }}
              onClick={handleChangeProduct}
              color='secondary'
              sx={{ typography: 'p12' }}>
              Change product
            </Button>
          )}
        </Row>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
