import { Route, RouteProps, Routes } from 'react-router-dom';
import { MultiTierAdminSideNav, TopNavBar } from '../components';
import { ContactUs } from '../dashboard';
import * as Style from '../dashboard/main/style';
import AdminDashboard from '../dashboard/multitier/AdminDashboard';
import { useDeviceType } from '../hooks/useDeviceType';
import AdminInfo from '../dashboard/multitier/AdminInfo';
import AdminAccess from '../dashboard/multitier/AdminAccess';
import Reports from '../dashboard/reports/Reports';
import RevenueShare from '../dashboard/revenue-share/RevenueShare';

const MultiTierAdminRoutes: React.FC<RouteProps> = () => {
  const { isDesktop } = useDeviceType();

  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <TopNavBar hideCompanyLogo hideDashboard useDrillDownCrumbs />
        <Style.MainSection>
          {isDesktop && <MultiTierAdminSideNav />}
          <Style.MainContentRight>
            <Routes>
              <Route path='/contact-us' element={<ContactUs />} />
              <Route key='/group-access' path='/group-access' element={<AdminAccess />} />
              <Route key='/group-info' path='/group-info' element={<AdminInfo />} />
              <Route key='/group-reports' path='/group-reports' element={<Reports />} />
              <Route key='/group-rev-share' path='/group-rev-share' element={<RevenueShare />} />
              <Route key='/organizations' path='/organizations' element={<AdminDashboard />} />
              <Route key='/team-access' path='/team-access' element={<AdminAccess />} />
              <Route key='/team-info' path='/team-info' element={<AdminInfo />} />
              <Route key='/team-reports' path='/team-reports' element={<Reports />} />
              <Route key='/team-rev-share' path='/team-rev-share' element={<RevenueShare />} />
              <Route key='/teams' path='/teams' element={<AdminDashboard />} />
            </Routes>
          </Style.MainContentRight>
        </Style.MainSection>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

export default MultiTierAdminRoutes;
