import { OrganizationData } from './OrganizationData';

export interface SignUpWizardState {
  organizationData: OrganizationData;
  product?: string;
  lastCompletedStep?: string;
}

export const InitialSignUpState: SignUpWizardState = {
  organizationData: {},
};

export interface StepData {
  current: string;
  route: string;
  previousCheckpoint: string;
  totalSteps: number;
  activeStep: number;
}

export interface NewStepData {
  current?: string;
  route: string;
}

export interface TemplateData {
  id: number;
  name: string;
}

export interface State {
  [key: string]: string;
}

export interface OrgSignUpStateData {
  id?: number;
  lastCompletedStep?: string;
  onboardingId?: number;
  offboardingId?: number;
  onboardingComplete?: boolean;
  offboardingComplete?: boolean;
  state?: State;
}

export const OrgSignUpStateDefault: OrgSignUpStateData = {
  id: 0,
  lastCompletedStep: undefined,
  onboardingId: 0,
  offboardingId: 0,
  onboardingComplete: false,
  offboardingComplete: false,
  state: undefined,
};

export const OnboardingStepsMultipleProductTypes: StepData[] = [
  {
    current: 'aboutYourOrg',
    route: '/onboarding/aboutYourOrg',
    previousCheckpoint: '',
    totalSteps: 5,
    activeStep: 1,
  },
  {
    current: 'inviteOtherAdmins',
    route: '/onboarding/inviteOtherAdmins',
    previousCheckpoint: 'aboutYourOrg',
    totalSteps: 5,
    activeStep: 2,
  },
  {
    current: 'chooseProduct',
    route: '/onboarding/chooseProduct',
    previousCheckpoint: 'inviteOtherAdmins',
    totalSteps: 5,
    activeStep: 3,
  },
  {
    current: 'license',
    route: '/onboarding/license',
    previousCheckpoint: 'chooseProduct',
    totalSteps: 5,
    activeStep: 4,
  },
  {
    current: 'contract',
    route: '/onboarding/contract',
    previousCheckpoint: 'license',
    totalSteps: 5,
    activeStep: 5,
  },
  {
    current: 'congrats',
    route: '/onboarding/congrats',
    previousCheckpoint: 'contract',
    totalSteps: 5,
    activeStep: 5,
  },
  {
    current: 'home',
    route: '/',
    previousCheckpoint: 'congrats',
    totalSteps: 5,
    activeStep: 5,
  },
];

export const OnboardingStepsDefault: StepData[] = [
  {
    current: 'aboutYourOrg',
    route: '/onboarding/aboutYourOrg',
    previousCheckpoint: '',
    totalSteps: 4,
    activeStep: 1,
  },
  {
    current: 'inviteOtherAdmins',
    route: '/onboarding/inviteOtherAdmins',
    previousCheckpoint: 'aboutYourOrg',
    totalSteps: 4,
    activeStep: 2,
  },
  {
    current: 'license',
    route: '/onboarding/license',
    previousCheckpoint: 'chooseProduct',
    totalSteps: 4,
    activeStep: 3,
  },
  {
    current: 'contract',
    route: '/onboarding/contract',
    previousCheckpoint: 'license',
    totalSteps: 4,
    activeStep: 4,
  },
  {
    current: 'congrats',
    route: '/onboarding/congrats',
    previousCheckpoint: 'contract',
    totalSteps: 4,
    activeStep: 4,
  },
  {
    current: 'home',
    route: '/',
    previousCheckpoint: 'congrats',
    totalSteps: 4,
    activeStep: 4,
  },
];

export const OnboardingStepsAdp: StepData[] = [
  {
    current: 'adpDeductions',
    route: '/onboarding/adpDeductions',
    previousCheckpoint: '',
    totalSteps: 3,
    activeStep: 1,
  },
  {
    current: 'restrictMembers',
    route: '/onboarding/restrictMembers',
    previousCheckpoint: 'adpDeductions',
    totalSteps: 3,
    activeStep: 2,
  },
  {
    current: 'adpContract',
    route: '/onboarding/adpContract',
    previousCheckpoint: 'restrictMembers',
    totalSteps: 3,
    activeStep: 3,
  },
  {
    current: 'congrats',
    route: '/congrats',
    previousCheckpoint: 'adpContract',
    totalSteps: 3,
    activeStep: 3,
  },
  { current: 'home', route: '/', previousCheckpoint: 'congrats', totalSteps: 3, activeStep: 3 },
];
