import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Typography } from '@mui/material';
import { Column, ToggleInput, Row, Spacer, StepsHeader } from '../../../components';
import { Form } from 'react-final-form';
import { PlanData } from '../../../models/PlanData';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { PlanWizard, planWizardKey } from '../../../services/plan';
import { CreatePlan } from '../../../data/plan/mutations';
import { useLoading } from '../../../hooks/useLoading';
import { ToastbarContext } from '../../../App';
import { Toast } from '../../../models/Toast';

const MemberContributions = () => {
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const planWizard = useService<PlanWizard>(planWizardKey);
  const planWizardState = useObservable(planWizard.state$);
  const createPlan = useMutation(new CreatePlan());

  const [activeStep] = useState(3);
  const [totalSteps] = useState(3);

  const navBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (createPlan.error) {
      console.error(createPlan.error);
      setLoading(false);
      setToast(
        new Toast({
          message: 'Could not create plan',
          severity: 'error',
          open: true,
        }),
      );
    } else if (createPlan.status === MutationStatus.Complete) {
      setLoading(false);
      navigate('/plan-info');
    }
  }, [createPlan.error, createPlan.status, navigate, setLoading, setToast]);

  const navToNext = async (values: any) => {
    setLoading(true);
    const contributionMethod = values['contributionMethod'];
    const planWizardData = planWizard.setState({
      planData: {
        ...planWizardState?.planData,
        deferralChoiceAllowed:
          contributionMethod === '% of pay'
            ? 'P'
            : contributionMethod === 'Fixed $ amount'
            ? 'D'
            : 'B',
        roth401kAllowed: values['rothAllowed'] === 'Yes',
        planContribPercentLimitPosttax: values['afterTaxAllowed'] === 'Yes' ? 100 : 0,
      },
    });
    await createPlan.action(planWizardData.planData);
  };

  return (
    <Column style={{ margin: '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader activeStep={activeStep} totalSteps={totalSteps} headerSteps='investmentLineup' />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Member contributions
      </Typography>

      <Typography variant='p18Light' color='info.main'>
        Please tell us about the contribution types allowed in your plan.
      </Typography>
      <Spacer height='xs' />

      <Form onSubmit={navToNext}>
        {({ handleSubmit }) => (
          <>
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Contribution methods
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Are members’ contributions a percentage of pay (%) or a fixed dollar amount ($)?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    fullWidth
                    buttonStyle={{ maxWidth: '200px', height: '45px' }}
                    initialValue={
                      planWizardState?.planData?.deferralChoiceAllowed === 'P'
                        ? '% of pay'
                        : planWizardState?.planData?.deferralChoiceAllowed === 'D'
                        ? 'Fixed $ amount'
                        : planWizardState?.planData?.deferralChoiceAllowed === 'B'
                        ? 'Both'
                        : plan?.deferralChoiceAllowed === 'P'
                        ? '% of pay'
                        : plan?.deferralChoiceAllowed === 'D'
                        ? 'Fixed $ amount'
                        : plan?.deferralChoiceAllowed === 'B'
                        ? 'Both'
                        : '% of pay'
                    }
                    fieldName='contributionMethod'
                    required
                    items={['% of pay', 'Fixed $ amount', 'Both']}
                  />
                </Row>
              </Row>
            </Card>
            <Card sx={{ padding: 2, width: '60%', flexWrap: 'wrap' }} variant='ghost'>
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Contribution types
                </Typography>
                <div style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />
              {!(planWizardState?.planData?.planType === '401(a)') && (
                <>
                  <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                    <Typography
                      sx={{ flex: '2 0 10px', textAlign: 'right' }}
                      variant='body1'
                      color='info.main'>
                      Are Roth contributions permitted?
                    </Typography>
                    <Row style={{ flex: '2 0 10px' }}>
                      <ToggleInput
                        fullWidth
                        buttonStyle={{ maxWidth: '200px', height: '45px' }}
                        fieldName='rothAllowed'
                        initialValue={
                          planWizardState?.planData?.roth401kAllowed
                            ? 'Yes'
                            : planWizardState?.planData?.roth401kAllowed === false
                            ? 'No'
                            : plan?.roth401kAllowed
                            ? 'Yes'
                            : plan?.roth401kAllowed === false
                            ? 'No'
                            : 'No'
                        }
                        required
                        items={['Yes', 'No']}
                      />
                    </Row>
                  </Row>
                  <Spacer height='xs' />
                </>
              )}
              <Row style={{ flexWrap: 'wrap', gap: '20px', alignItems: 'center' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right' }}
                  variant='body1'
                  color='info.main'>
                  Are after-tax contributions permitted?
                </Typography>
                <Row style={{ flex: '2 0 10px' }}>
                  <ToggleInput
                    fullWidth
                    buttonStyle={{ maxWidth: '200px', height: '45px' }}
                    fieldName='afterTaxAllowed'
                    initialValue={
                      planWizardState?.planData?.planContribPercentLimitPosttax !== undefined
                        ? planWizardState.planData.planContribPercentLimitPosttax > 0
                          ? 'Yes'
                          : 'No'
                        : plan?.planContribPercentLimitPosttax !== undefined
                        ? plan.planContribPercentLimitPosttax > 0
                          ? 'Yes'
                          : 'No'
                        : 'No'
                    }
                    required
                    items={['Yes', 'No']}
                  />
                </Row>
              </Row>
            </Card>
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' variant='outlined' color='secondary' onClick={navBack}>
                {activeStep === 1 ? `Cancel` : `Back`}
              </Button>
              <Button className='xs' color='primary' onClick={handleSubmit}>
                Create plan
              </Button>
            </Row>
          </>
        )}
      </Form>
    </Column>
  );
};

export default MemberContributions;
