import { IObservableQuery, injectSubject } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { CourseData, LessonData } from '../../../models/CourseData';
import { filterLessons, findLessonsByUnitId, sortByPosition } from '../commands/utils';
import { LearningServices } from '../LearningServiceModule';

export class FindLessonsByUnitId implements IObservableQuery<number, LessonData[] | undefined> {
  constructor(
    @injectSubject(LearningServices.Courses)
    private readonly allCourses$: Observable<CourseData[]>,
  ) {}

  execute(unitId: number): Observable<LessonData[] | undefined> {
    return this.allCourses$.pipe(
      map(x => {
        const lessons = findLessonsByUnitId(unitId, x);
        const filteredLessons = filterLessons(lessons);
        const sortedLessons = filteredLessons.sort(sortByPosition);
        return sortedLessons;
      }),
    );
  }
}
