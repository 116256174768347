import { Button, Card, Typography } from '@mui/material';
import { useContext } from 'react';
import { Form } from 'react-final-form';
import { ToastbarContext } from '../../App';
import { Row, Spacer, Column, RightDrawer, HorizontalFormInput } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { AddAMember as AddAMemberApi } from '../../data/members/mutations';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { UserCompartments, userStorageKey } from '../../data/user';
import { validateEmail } from '../../helpers/utilityFunctions';
import { OrganizationData } from '../../models/OrganizationData';
import { Toast } from '../../models/Toast';
import { AdminStatusEnum, UserData } from '../../models/UserData';
import { Svgs } from '../../assets/svg';

interface AddAMemberProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAMember: React.FC<AddAMemberProps> = props => {
  const { drawerOpen, setDrawerOpen } = props;
  const { setToast } = useContext(ToastbarContext);
  const addAMember = useMutation(new AddAMemberApi());
  const appStorage = useAppStorage();
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const userData = useObservable(userDataCache.observe$<UserData>('user'));
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const organizationData = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );

  const onSubmit = async (values: any) => {
    try {
      await addAMember.action({
        id: userData?.id,
        organizationId: organizationData?.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        status: AdminStatusEnum.Invited,
        statusTimestamp: userData?.statusTimestamp,
      });
      setDrawerOpen(false);
      setToast(new Toast({ message: 'Member added!', severity: 'success', open: true }));
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', open: true }));
    }
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Spacer height='sm' />
      <Typography color='secondary.main' variant='h1'>
        Add a member
      </Typography>
      <Spacer height='sm' />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column
              style={{
                width: '90%',
              }}>
              <HorizontalFormInput
                label='First name'
                fieldName='firstName'
                maxLength={32}
                rightAlignedKey
                required
                autoFocus
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Last name'
                fieldName='lastName'
                maxLength={32}
                rightAlignedKey
                required
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Email'
                fieldName='email'
                validate={validateEmail}
                rightAlignedKey
                required
              />
              <Spacer height='lg' />
            </Column>
            <Card className='add-member-warning'>
              <Svgs.IconWarning />
              <Typography variant='p16' color='error.dark'>
                An email invite is sent to a member as soon as they are added.
              </Typography>
            </Card>
            <Spacer height='sm' />
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Add member
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default AddAMember;
