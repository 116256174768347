import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Column, Row, Spacer, StepsHeader } from '../../components';
import { LicenseData } from '../../models/LicenseData';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import {
  PricebookBundle,
  PaymentTypeEnum,
  ProductTypeEnum,
  IntervalTypeEnum,
} from '../../models/PricebookData';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { SelectPricebook } from '../../data/pricebook/mutations';
import { useDeviceType } from '../../hooks/useDeviceType';
import { LicenseAgreementApi, LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { useLoading } from '../../hooks/useLoading';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { Toast } from '../../models/Toast';
import { ToastbarContext } from '../../App';
import HowManyLicenses from './HowManyLicenses';
import ProductCard from './ProductCard';
import ProductOptions from './ProductOptions';
import CancelContract from '../../components/CancelContract';

const License = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const { isMobile, isTablet } = useDeviceType();

  const updateSignUpState = useMutation(new UpdateSignUpState());
  const selectPricebook = useMutation(new SelectPricebook());

  const appStorage = useAppStorage();
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const licenseAgreementApi = useService<LicenseAgreementApi>(ApiKeys.LicenseAgreement);

  const pricebookData = useObservable(
    pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions'),
  );
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const multipleProductTypesAvailable = useObservable(signUpWizard.multipleProductTypes$) ?? false;
  const multiplePricebooks = useObservable(signUpWizard.multiplePricebooks$) ?? false;
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);

  const [selectedPricebook, setSelectedPricebook] = useState<PricebookBundle | undefined>(
    undefined,
  );
  const [hasSetPricebook, setHasSetPricebook] = useState(false);
  const [memberPaidOptions, setMemberPaidOptions] = useState<PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<PricebookBundle[]>([]);
  const [initialQuantity, setInitialQuantity] = useState<number | undefined>(0);

  const currentLicense =
    licenseAgreements &&
    licenseAgreements?.find(
      license => license.pricebookId === selectedPricebook?.pricebook.id && license.docStatus,
    );
  const pendingAgreement = currentLicense?.docStatus && !currentLicense?.hasAccepted;
  const activeContract = pendingAgreement || currentLicense?.docStatus;
  const product =
    orgSignUpState?.state && orgSignUpState?.state.product
      ? orgSignUpState?.state.product
      : undefined;
  const checkedOut =
    orgSignUpState?.state?.checkedOut && JSON.parse(orgSignUpState?.state.checkedOut);
  const licenseData: LicenseData =
    orgSignUpState?.state?.licenseData && JSON.parse(orgSignUpState?.state.licenseData);
  const pricebook: PricebookBundle | undefined =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state.pricebook);
  const productName = product === ProductTypeEnum.GuidedChoice ? 'GuidedChoice' : '3Nickels';
  const meteredPayment = selectedPricebook?.pricebook.paymentType === PaymentTypeEnum.Metered;
  const activeSubscription = selectedPricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = (activeSubscription && activeSubscription.price / 100.0) ?? 0;

  useEffect(() => {
    if (!selectedPricebook?.pricebook.productType.includes(product ?? 'x')) {
      setSelectedPricebook(undefined);
      setInitialQuantity(0);
    } else {
      setInitialQuantity(licenseData?.quantity);
    }
  }, [licenseData?.quantity, product, selectedPricebook?.pricebook.productType]);

  useEffect(() => {
    const updateState = async () => {
      if (currentLicense && !activeContract) {
        await updateSignUpState.action({
          state: {
            ...orgSignUpState?.state,
            licenseData: JSON.stringify({ ...licenseData, quantity: 0 }),
          },
        });
      }
    };
    updateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContract, currentLicense]);

  useEffect(() => {
    if (pricebook && !hasSetPricebook) {
      setSelectedPricebook(pricebook);
      setHasSetPricebook(true);
    }
  }, [pricebook, hasSetPricebook, checkedOut]);

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookData) {
        const pricebooks = pricebookData.filter(bundle =>
          bundle.pricebook.productType.includes(product ?? ''),
        );
        const member = pricebooks.filter(bundle => bundle.pricebook.paidBy === 'USER');
        const org = pricebooks.filter(bundle => bundle.pricebook.paidBy === 'ORG');

        setMemberPaidOptions(member);
        setOrgPaidOptions(org);

        if (pricebooks.length === 1) {
          const singlePricebook = pricebooks[0];
          setSelectedPricebook(singlePricebook);

          if (orgSignUpState?.state) {
            await updateSignUpState.action({
              state: {
                ...orgSignUpState.state,
                pricebook: JSON.stringify(singlePricebook),
              },
            });
          }
        }
      }
    };

    getPricebookOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, pricebookData]);

  const onSubmit = async (values: LicenseData) => {
    if (!selectedPricebook) return;

    try {
      setLoading(true);
      const licenseData: LicenseData = {
        ...values,
        paymentParty: selectedPricebook.pricebook.paidBy,
        package: selectedPricebook.pricebook.productType,
      };
      if (licenseData.paymentParty === 'USER') {
        licenseData.quantity = undefined;
      } else {
        licenseData.quantity = values.quantity as number;
        licenseData.cost = price;
      }
      await selectPricebook.action(selectedPricebook);

      const generatedLicense = (await licenseAgreementApi.generate(selectedPricebook.pricebook.id))
        .data;
      await updateSignUpState.action({
        lastCompletedStep: signUpWizard.getCurrent(location),
        state: {
          ...orgSignUpState?.state,
          stripeSession: JSON.stringify(undefined),
          licenseData: JSON.stringify({ ...licenseData, ...generatedLicense }),
          pricebook: JSON.stringify(selectedPricebook),
        },
      });
      await licenseDataCache.reloadAll();
      const next = signUpWizard.nextStep(location);
      navigate(next.route);
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + selectedPricebook.pricebook.id);
      setToast(
        new Toast({
          message: `Error selecting package`,
          severity: 'error',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const navBack = () => {
    const step = signUpWizard.previousStep(location);
    navigate(step?.route ?? '');
  };

  if (!product) return null;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        quantity: initialQuantity,
      }}>
      {({ handleSubmit, values }) => (
        <Column style={{ margin: isMobile || isTablet ? '0px 20px' : '0px 100px' }}>
          <Spacer height='xs' />
          <StepsHeader
            activeStep={activeStep}
            totalSteps={totalSteps}
            headerSteps={
              multipleProductTypesAvailable ? 'onboardingMultipleProducts' : 'onboarding'
            }
          />
          <Spacer height='xs' />
          <Typography variant='h1' color='secondary'>
            Step {activeStep}: Licenses
          </Typography>
          <Spacer height='xs' />
          <Typography variant='p20SemiBold' color='secondary'>
            {multiplePricebooks ? 'Current product: ' : 'Product: '}
            {productName}
          </Typography>
          <Spacer height='xs' />
          <ProductCard
            handleChangeProduct={navBack}
            product={product}
            multipleProductTypesAvailable={multipleProductTypesAvailable}
          />
          <Spacer height='sm' />
          <Typography variant='p20SemiBold' color='secondary'>
            {multiplePricebooks
              ? 'Which package would you like to choose for your members?'
              : "Your organization's exclusive package"}
          </Typography>
          <ProductOptions
            memberPaidOptions={memberPaidOptions}
            orgPaidOptions={orgPaidOptions}
            selectedPricebook={selectedPricebook}
            setSelectedPricebook={setSelectedPricebook}
            currentLicense={currentLicense}
            signUpState={orgSignUpState?.state}
            updateSignUpState={updateSignUpState}
            multiplePricebooks={multiplePricebooks}
          />
          {!meteredPayment &&
            (selectedPricebook?.pricebook.productType.includes(product ?? 'x') &&
              selectedPricebook?.pricebook.paidBy) === 'ORG' && (
              <HowManyLicenses values={values} cost={price} />
            )}

          <Spacer height='sm' />
          {isMobile ? (
            <Column style={{ alignItems: 'center' }}>
              {activeContract && <CancelContract />}
              <Spacer height='xs' />
              <Button
                style={{ width: '100%' }}
                disabled={
                  multiplePricebooks
                    ? selectedPricebook?.pricebook.paidBy === 'ORG'
                      ? !values.quantity
                      : !selectedPricebook
                    : false
                }
                className='sm'
                onClick={handleSubmit}
                color='primary'>
                Next: Review
              </Button>
              <Spacer height='xs' />
              <Button
                style={{ width: '100%' }}
                className='xs'
                onClick={navBack}
                variant='outlined'
                color='secondary'>
                Back
              </Button>
            </Column>
          ) : (
            <Row style={{ justifyContent: 'space-between' }}>
              <Button className='xs' onClick={navBack} variant='outlined' color='secondary'>
                Back
              </Button>
              <Row style={{ alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                {activeContract && <CancelContract />}
                <Spacer width='sm' />
                <Button
                  disabled={
                    multiplePricebooks
                      ? selectedPricebook?.pricebook.paidBy === 'ORG'
                        ? !values.quantity && !meteredPayment
                        : !selectedPricebook
                      : false
                  }
                  className='sm'
                  onClick={handleSubmit}
                  color='primary'>
                  Next: Review
                </Button>
              </Row>
            </Row>
          )}
          <Spacer height='sm' />
        </Column>
      )}
    </Form>
  );
};

export default License;
