import { Button, Card, CardContent, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Column,
  FormInput,
  HorizontalFormInput,
  StackedFormInput,
  Row,
  Spacer,
  StepsHeader,
  AddressSearchbox,
  DropdownMenu,
} from '../components';
import { useContext, useEffect, useState } from 'react';
import { useAppStorage, useMutation, MutationStatus, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { SignUpWizard, signUpWizardKey } from '../services/signUp';
import { Form } from 'react-final-form';
import { AddressInfo, OrganizationData } from '../models/OrganizationData';
import {
  SaveOrganizationData,
  UnselectOrganization,
  UpdateOrganizationData,
} from '../data/organization/mutations';
import { UpdateSignUpState, CreateSignUpState } from '../data/signUp/mutations';
import { formatPhoneNumber, trimPhoneNumber } from '../helpers/utilityFunctions';
import { useDeviceType } from '../hooks/useDeviceType';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { ToastbarContext } from '../App';
import { Toast } from '../models/Toast';
import { Loading } from '../routes/AuthenticatedRoutes';
import { statesList } from '../helpers/constants';
import { UserCompartments, userStorageKey } from '../data/user';
import { UserRole } from '../models/UserData';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { SignUpCompartments, signUpStorageKey } from '../data/signUp';
import { OrgSignUpStateData, State } from '../models/SignUpData';
import { useLoading } from '../hooks/useLoading';

const AboutYourOrg = () => {
  const appStorage = useAppStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const { isMobile, isTablet } = useDeviceType();
  const { setLoading } = useLoading();

  const saveOrganizationData = useMutation(new SaveOrganizationData());
  const updateOrganizationData = useMutation(new UpdateOrganizationData());
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const createSignUpState = useMutation(new CreateSignUpState());
  const unselectOrganization = useMutation(new UnselectOrganization());
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);

  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);

  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const currentOrganization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const userRoles = useObservable(userDataCache.observe$<UserRole[]>('userRoles'));
  const googlePlacesKey = useObservable(signUpWizard.googlePlacesKey$);
  const multipleProductTypesAvailable = useObservable(signUpWizard.multipleProductTypes$);
  const origin = useObservable(drillDownWizard.origin$);
  const back =
    origin === AdminTierTypeEnum.Organization
      ? '/drill-down/choose-organization'
      : '/controller/organizations';
  const organizationData: OrganizationData =
    currentOrganization &&
    orgSignUpState?.state?.orgData &&
    JSON.parse(orgSignUpState?.state.orgData);
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);

  const [isExistingUser, setIsExistingUser] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const [fullAddress, setFullAddress] = useState<AddressInfo | undefined>(undefined);
  const [streetAddress, setStreetAddress] = useState<string>();
  const [formattedPhone, setFormattedPhone] = useState<string>();
  const [formattedExt, setFormattedExt] = useState<string>();
  const [formattedZip, setFormattedZip] = useState<string>();
  const [selectedState, setState] = useState<string>(organizationData?.state ?? 'Choose a state');

  useEffect(() => {
    if (!organizationData || Object.keys(organizationData).length === 0) return;
    if (organizationData?.state) {
      setState(organizationData?.state);
    }
    if (organizationData?.streetAddress) {
      setStreetAddress(organizationData?.streetAddress);
    }
  }, [organizationData]);

  useEffect(() => {
    if (currentOrganization) {
      setOrganizationData(currentOrganization, orgSignUpState?.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  useEffect(() => {
    if (!tryAgain) return;
    if (
      saveOrganizationData?.error?.message.includes('400') ||
      updateOrganizationData?.error?.message.includes('400')
    ) {
      setToast(
        new Toast({
          message: 'Organization name is already taken. Please use a unique name.',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      setTryAgain(false);
    } else if (
      saveOrganizationData.status === MutationStatus.Complete ||
      updateOrganizationData.status === MutationStatus.Complete
    ) {
      const navToNextStep = () => {
        const next = signUpWizard.nextStep(location);
        navigate(next.route);
      };
      navToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveOrganizationData, updateOrganizationData, signUpWizard]);

  useEffect(() => {
    if (!userRoles) return;
    if (
      userRoles.length === 0 ||
      (userRoles.length === 1 && userRoles?.[0].role?.includes('ORGANIZATION'))
    ) {
      setIsExistingUser(false);
    } else {
      setIsExistingUser(true);
    }
  }, [userRoles]);

  const validateOrgName = (value: string) => {
    if (value.length > 128) {
      return 'Organization name should not exceed 128 characters.';
    }
    return undefined;
  };

  const validatePhoneNumber = (value: string) => {
    const result = formatPhoneNumber(value);
    setFormattedPhone(result);

    const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
    return regex.test(value) ? undefined : 'Invalid Phone Number';
  };

  const validateExtension = (value: string | undefined) => {
    if (!value) return undefined;
    const result = value.replace(/[^0-9]/g, '');
    setFormattedExt(result ? result : '');
  };

  const setOrganizationData = async (orgData: OrganizationData, state?: State) => {
    await updateSignUpState.action({
      state: { ...state, orgData: JSON.stringify(orgData) },
    });
  };

  const onSubmit = async (values: OrganizationData) => {
    setLoading(true);
    const { name, phoneExtension, city, zip } = values;
    const phoneNumber = trimPhoneNumber(values.phoneNumber ?? '');
    const orgData = {
      streetAddress,
      city,
      state: selectedState,
      zip,
      name,
      phoneNumber,
      phoneExtension,
      type: 'GENERAL',
    };

    try {
      if (currentOrganization?.id && currentOrganization?.name === organizationData?.name) {
        await updateOrganizationData.action(orgData);
        await updateSignUpState.action({ lastCompletedStep: signUpWizard.getCurrent(location) });
      } else {
        await saveOrganizationData.action(orgData);
        await createSignUpState.action({
          lastCompletedStep: signUpWizard.getCurrent(location),
        });
        await setOrganizationData(orgData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTryAgain(true);
      setLoading(false);
    }
  };

  const onCancel = async () => {
    if (currentOrganization?.id && currentOrganization.name === organizationData?.name) {
      await updateSignUpState.action({
        onboardingComplete: false,
      });
      await unselectOrganization.action(currentOrganization.id);
      navigate(back);
      return;
    }
    navigate(back);
  };

  const onAddressSelect = (address: AddressInfo) => {
    setFullAddress(address);
  };

  const isAddressFullyEntered = (values: OrganizationData) => {
    return (
      streetAddress &&
      values.city &&
      values.zip &&
      selectedState &&
      selectedState !== 'Choose a state'
    );
  };

  const validateZip = (value: string | undefined) => {
    if (!value) return undefined;
    const result = value.replace(/[^0-9]/g, '');
    setFormattedZip(result ? result : '');
  };

  if (!googlePlacesKey) {
    return <Loading />;
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <Column style={{ margin: isMobile || isTablet ? '0 20px' : '0px 100px' }}>
          <Spacer height='xs' />
          <StepsHeader
            activeStep={activeStep}
            totalSteps={totalSteps}
            headerSteps={
              multipleProductTypesAvailable ? 'onboardingMultipleProducts' : 'onboarding'
            }
          />
          <Spacer height='xs' />
          <Typography variant='h1' color='secondary'>
            Step {activeStep}: About your organization
          </Typography>
          <Spacer height='sm' />
          <Column
            style={{
              justifyContent: isMobile || isTablet ? '' : 'center',
              width: isMobile || isTablet ? '100%' : '70%',
            }}>
            {isMobile || isTablet ? (
              <StackedFormInput<OrganizationData>
                fieldName='name'
                label='Organization name'
                initialValue={organizationData?.name}
                validate={validateOrgName}
                required
              />
            ) : (
              <HorizontalFormInput<OrganizationData>
                fieldName='name'
                label='Organization name'
                initialValue={organizationData?.name}
                validate={validateOrgName}
                rightAlignedKey
                required
              />
            )}
            <Spacer height='s25' />
            <PhoneNumberRow
              formattedPhone={formattedPhone}
              validatePhoneNumber={validatePhoneNumber}
              organizationData={organizationData}
              validateExtension={validateExtension}
              formattedExt={formattedExt}
            />
            <Spacer height='sm' />
          </Column>

          {isMobile || isTablet ? (
            <Column
              style={{
                gap: '20px',
              }}>
              <Typography
                sx={{ flex: '2 0 10px', textAlign: 'left' }}
                variant='p18SemiBold'
                color='secondary.dark'>
                Mailing address
              </Typography>
              <Card variant='ghost' color='primary'>
                <CardContent className='mobileAddressCard'>
                  <Column
                    style={{
                      flexWrap: 'wrap',
                      gap: '20px',
                      alignItems: 'left',
                    }}>
                    <Column style={{ alignItems: 'left' }}>
                      <Typography sx={{ alignItems: 'left' }} variant='body1' color='info.main'>
                        Street address
                      </Typography>
                      <AddressSearchbox
                        organizationData={organizationData}
                        onSubmit={onAddressSelect}
                        googleKey={googlePlacesKey}
                        fieldName='streetAddress'
                        streetAddress={streetAddress}
                        setStreetAddress={setStreetAddress}
                        setFullAddress={setFullAddress}
                        setState={setState}
                        fullAddress={fullAddress}
                      />
                    </Column>
                    <StackedFormInput<OrganizationData>
                      fieldName='city'
                      label='City'
                      initialValue={organizationData?.city}
                      required
                    />
                    <Column style={{ alignItems: 'left' }}>
                      <Typography sx={{ alignItems: 'left' }} variant='body1' color='info.main'>
                        State
                      </Typography>
                      <DropdownMenu
                        inputStyles={{ alignItems: 'left', width: '100%' }}
                        callback={val => setState(val)}
                        selected={selectedState}
                        options={statesList}
                      />
                    </Column>
                    <StackedFormInput<OrganizationData>
                      fieldName='zip'
                      label='Zip code'
                      initialValue={organizationData?.zip}
                      formattedValue={formattedZip}
                      validate={validateZip}
                      maxLength={5}
                      required
                    />
                  </Column>
                </CardContent>
              </Card>
            </Column>
          ) : (
            <Column>
              <Row style={{ flex: '2 0 10px', justifyContent: 'flex-end', width: '70%' }}>
                <Typography
                  sx={{ flex: '2 0 10px', textAlign: 'right', paddingRight: '10px' }}
                  variant='p18SemiBold'
                  color='secondary.dark'>
                  Mailing address
                </Typography>
                <Row style={{ flex: '2 0 10px' }} />
              </Row>
              <Spacer height='xxs' />

              <Card variant='ghost' color='primary'>
                <CardContent className='addressCard'>
                  <Row>
                    <Row style={{ gap: '20px', alignItems: 'center', width: '70%' }}>
                      <Typography
                        sx={{
                          flex: '2 0 10px',
                          textAlign: 'right',
                          marginTop: '10px',
                        }}
                        variant='body1'
                        color='info.main'>
                        Street address
                      </Typography>
                      <Row style={{ flex: '2 0 10px', gap: '20px' }}>
                        <AddressSearchbox
                          organizationData={organizationData}
                          onSubmit={onAddressSelect}
                          googleKey={googlePlacesKey}
                          fieldName='streetAddress'
                          streetAddress={streetAddress}
                          setStreetAddress={setStreetAddress}
                          setFullAddress={setFullAddress}
                          setState={setState}
                          fullAddress={fullAddress}
                        />
                      </Row>
                    </Row>
                  </Row>

                  <Row>
                    <Row style={{ gap: '20px', alignItems: 'center', width: '70%' }}>
                      <Typography
                        sx={{
                          flex: '2 0 10px',
                          textAlign: 'right',
                          marginTop: '10px',
                        }}
                        variant='body1'
                        color='info.main'>
                        City
                      </Typography>
                      <Row style={{ flex: '2 0 10px', gap: '20px' }}>
                        <HorizontalFormInput<OrganizationData>
                          fieldName='city'
                          initialValue={organizationData?.city ?? fullAddress?.city}
                          inputStyle={{
                            minWidth: '90px',
                            flexShrink: 2,
                          }}
                          rightAlignedKey
                          required
                        />
                        <Row
                          style={{
                            flexWrap: 'wrap',
                            gap: '20px',
                            alignItems: 'center',
                            minWidth: '200px',
                          }}>
                          <Typography
                            style={{ flex: '2 0 10px', justifyContent: 'flex-end' }}
                            variant='body1'
                            color='info.main'>
                            State
                          </Typography>
                          <DropdownMenu
                            inputStyles={{ flex: '2 0 10px' }}
                            callback={val => setState(val)}
                            selected={selectedState}
                            options={statesList}
                          />
                        </Row>
                      </Row>
                    </Row>
                    <HorizontalFormInput<OrganizationData>
                      fieldName='zip'
                      label='Zip code'
                      initialValue={organizationData?.zip ?? fullAddress?.zip}
                      formattedValue={formattedZip}
                      inputStyle={{ minWidth: '20px', maxWidth: '110px' }}
                      labelStyle={{ marginTop: '10px' }}
                      validate={validateZip}
                      maxLength={5}
                      rightAlignedKey
                      required
                    />
                    <Spacer width='md' />
                  </Row>
                </CardContent>
              </Card>
            </Column>
          )}

          <Spacer height='sm' />
          {isMobile || isTablet ? (
            <Row style={{ justifyContent: 'center' }}>
              {isExistingUser && (
                <>
                  <Button
                    style={{ width: '50%' }}
                    className='md'
                    color='secondary'
                    variant='outlined'
                    onClick={() => onCancel()}>
                    Cancel
                  </Button>
                  <Spacer width='xs' />
                </>
              )}
              <Button
                style={{ width: `${isExistingUser ? '50%' : '100%'}` }}
                className='md'
                disabled={!isAddressFullyEntered(values) || !values.name || !values.phoneNumber}
                onClick={handleSubmit}
                color='primary'>
                Next: Invite admins
              </Button>
            </Row>
          ) : (
            <Row
              style={{
                justifyContent: `${isExistingUser ? 'space-between' : 'flex-end'}`,
              }}>
              {isExistingUser && (
                <Button
                  className='md'
                  color='secondary'
                  variant='outlined'
                  onClick={() => onCancel()}>
                  Cancel
                </Button>
              )}
              <Button
                className='md'
                disabled={!isAddressFullyEntered(values) || !values.name || !values.phoneNumber}
                onClick={handleSubmit}
                color='primary'>
                Next: Invite admins
              </Button>
            </Row>
          )}
          <Spacer height='md' />
        </Column>
      )}
    </Form>
  );
};

interface PhoneProps {
  formattedPhone?: string;
  validatePhoneNumber: (x: string) => string | undefined;
  organizationData: OrganizationData;
  validateExtension: (x: string) => string | undefined;
  formattedExt?: string;
}
const PhoneNumberRow: React.FC<PhoneProps> = ({
  formattedPhone,
  validatePhoneNumber,
  organizationData,
  validateExtension,
  formattedExt,
}) => {
  const { isMobile, isTablet } = useDeviceType();
  return (
    <>
      {isMobile || isTablet ? (
        <Column style={{ justifyContent: 'left' }}>
          <Typography
            sx={{ flex: '2 0 10px', textAlign: 'left', marginTop: '10px' }}
            variant='body1'
            color='info.main'>
            Phone number
          </Typography>
          <FormInput<OrganizationData>
            fieldName='phoneNumber'
            initialValue={organizationData?.phoneNumber}
            formattedValue={formattedPhone}
            validate={validatePhoneNumber}
            maxLength={14}
            required
          />
          <Spacer height='sm' />
          <StackedFormInput<OrganizationData>
            fieldName='phoneExtension'
            label='Ext.'
            initialValue={organizationData?.phoneExtension}
            formattedValue={formattedExt}
            validate={validateExtension}
            maxLength={6}
          />
        </Column>
      ) : (
        <Row style={{ gap: '20px', alignItems: 'center' }}>
          <Typography
            sx={{ flex: '2 0 10px', textAlign: 'right', marginTop: '10px' }}
            variant='body1'
            color='info.main'>
            Phone number
          </Typography>
          <Row style={{ flex: '2 0 10px', gap: '20px' }}>
            <FormInput<OrganizationData>
              fieldName='phoneNumber'
              initialValue={organizationData?.phoneNumber}
              formattedValue={formattedPhone}
              inputStyle={{ minWidth: '90px', flexShrink: '0' }}
              validate={validatePhoneNumber}
              maxLength={14}
              required
            />
            <HorizontalFormInput<OrganizationData>
              fieldName='phoneExtension'
              label='Ext.'
              initialValue={organizationData?.phoneExtension}
              formattedValue={formattedExt}
              labelStyle={{ marginTop: '10px' }}
              inputStyle={{ flexShrink: '5', minWidth: '30px' }}
              validate={validateExtension}
              maxLength={6}
            />
          </Row>
        </Row>
      )}
    </>
  );
};

export default AboutYourOrg;
