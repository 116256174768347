import { Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { PlanData } from '../../../models/PlanData';
import { ToastbarContext } from '../../../App';
import {
  EditableCard,
  HorizontalFormInput,
  KeyValueLabel,
  RightDrawer,
  Row,
  Spacer,
} from '../../../components';
import { RightDrawerProps } from '../../../components/RightDrawer';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { PlanCompartments, planStorageKey } from '../../../data/plan';
import { EditPlan } from '../../../data/plan/mutations';
import { formatDateMMDDYYYY, formatDateYYYYMMDD } from '../../../helpers/utilityFunctions';
import { useLoading } from '../../../hooks/useLoading';
import { Toast } from '../../../models/Toast';
import { PlanBasicsHelpText } from '../add-plan/PlanBasics';

const PlanBasicsCard = () => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <EditableCard onEditClick={() => setDrawerOpen(drawer => !drawer)} editable title='Plan basics'>
      <KeyValueLabel keyString='Plan type' value={plan?.planType} />
      <KeyValueLabel keyString='Plan name' value={plan?.planName} autoFocus />
      <KeyValueLabel keyString='Benefit name' value={plan?.employerContributionName} />
      <KeyValueLabel keyString='Recordkeeper plan ID' value={plan?.rkPlanId} />
      <KeyValueLabel
        keyString='Date created'
        value={
          plan?.planAnniversaryDate ? formatDateMMDDYYYY(plan?.planAnniversaryDate) : undefined
        }
      />

      <PlanBasicsCardEdit title='Plan basics' open={drawerOpen} setOpen={setDrawerOpen} />
    </EditableCard>
  );
};

const PlanBasicsCardEdit: React.FC<RightDrawerProps> = ({ title, open, setOpen }) => {
  const appStorage = useAppStorage();
  const planDataCache = appStorage.retrieve<PlanCompartments>(planStorageKey);
  const plan = useObservable(planDataCache.observe$<PlanData>('plan'));
  const editPlan = useMutation(new EditPlan());

  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();

  useEffect(() => {
    if (editPlan.error) {
      console.error(editPlan.error);
      setLoading(false);
      setToast(
        new Toast({
          message: 'Could not save plan',
          severity: 'error',
          open: true,
        }),
      );
    } else if (editPlan.status === MutationStatus.Complete) {
      setOpen(false);
      setLoading(false);
      setToast(new Toast({ message: 'Plan basics updated!', severity: 'success', open: true }));
      planDataCache.reload('plan');
    }
  }, [editPlan.error, editPlan.status, planDataCache, setOpen, setLoading, setToast]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await editPlan.action({
      ...plan,
      ...values,
      planAnniversaryDate: new Date().toISOString(),
    });
  };

  return (
    <RightDrawer open={open} setOpen={setOpen} title={title}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Spacer height='sm' />
            <Typography variant='p18Light' color='secondary'>
              <HorizontalFormInput
                label='Plan type'
                fieldName='planType'
                initialValue={plan?.planType}
                inputStyle={{ flex: '3 0 10px' }}
                rightAlignedKey
                readonly
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Plan name'
                fieldName='planName'
                initialValue={plan?.planName}
                maxLength={128}
                inputStyle={{ flex: '3 0 10px' }}
                autoFocus
                rightAlignedKey
                required
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Benefit name'
                fieldName='employerContributionName'
                initialValue={plan?.employerContributionName}
                inputStyle={{ flex: '3 0 10px' }}
                rightAlignedKey
                readonly
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Recordkeeper plan ID'
                fieldName='rkPlanId'
                initialValue={plan?.rkPlanId}
                inputStyle={{ flex: '3 0 10px' }}
                helpText={<PlanBasicsHelpText />}
                rightAlignedKey
              />
              <Spacer height='xxs' />
              <HorizontalFormInput
                label='Date created'
                fieldName='planAnniversaryDate'
                type='date'
                initialValue={formatDateYYYYMMDD(
                  plan?.planAnniversaryDate ?? new Date().toISOString(),
                )}
                inputStyle={{ flex: '3 0 10px' }}
                rightAlignedKey
                readonly
              />
            </Typography>

            <Spacer height='sm' />

            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button style={{ width: 127 }} color='primary' onClick={handleSubmit}>
                Save
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default PlanBasicsCard;
