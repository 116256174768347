// import { StyledContent } from '../../../components/OldLivePreview';
import {
  UnitData,
  CourseData,
  LessonData,
  PublicationStatusEnum,
  LessonContent,
  QuizQuestion,
  QuizData,
} from '../../../models/CourseData';

export function updateCourses(unit: UnitData, courses: CourseData[]) {
  const newCourses = courses.map(course => {
    course.units = course.units?.map(u => (u.unitId === unit.unitId ? unit : u));
    return course;
  });

  return newCourses;
}

export function findUnitSource(unit: UnitData, courses: CourseData[]) {
  const courseIndex = courses.findIndex(
    x =>
      x.originalCourseId === unit.sourceCourseId &&
      (x.active || x.status === PublicationStatusEnum.DRAFT),
  );

  return courseIndex;
}

export function findLessonSource(lesson: LessonData, courses: CourseData[]) {
  let unitIndex: number | undefined;
  // update the lessons in the unit, and then update the unit everywhere
  const courseIndex = courses.findIndex(
    x =>
      (x.active || x.status === PublicationStatusEnum.DRAFT) &&
      x.units?.findIndex((unit, index) => {
        if (
          (unit.active || unit.status === PublicationStatusEnum.DRAFT) &&
          unit.originalUnitId === lesson.unitId &&
          unit.sourceCourseId === x.originalCourseId
        ) {
          unitIndex = index;
          return true;
        }

        return false;
      }) !== -1,
  );

  return { courseIndex, unitIndex };
}

export function findLessonsByUnitId(unitId: number, courses: CourseData[]) {
  for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
    const course = courses[courseIndex];
    if (course.units) {
      for (let unitIndex = 0; unitIndex < course.units.length; unitIndex++) {
        const unit = course.units[unitIndex];
        if (unit.lessons && unit.unitId === unitId) {
          const lessons = unit.lessons;
          return lessons;
        }
      }
    }
  }

  return [];
}

export function findLessonContentSource(content: LessonContent, courses: CourseData[]) {
  for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
    const course = courses[courseIndex];
    if (course.active || course.status === PublicationStatusEnum.DRAFT) {
      if (course.units) {
        for (let unitIndex = 0; unitIndex < course.units.length; unitIndex++) {
          const unit = course.units[unitIndex];
          if (unit.active || unit.status === PublicationStatusEnum.DRAFT) {
            if (unit.lessons) {
              for (let lessonIndex = 0; lessonIndex < unit.lessons.length; lessonIndex++) {
                const lesson = unit.lessons[lessonIndex];
                if (lesson.lessonId === content.lessonId) {
                  return { courseIndex, unitIndex, lessonIndex };
                }
              }
            }
          }
        }
      }
    }
  }

  return {};
}

export function findQuizSource(quiz: QuizData, courses: CourseData[]) {
  for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
    const course = courses[courseIndex];
    if (course.active || course.status === PublicationStatusEnum.DRAFT) {
      if (course.units) {
        for (let unitIndex = 0; unitIndex < course.units.length; unitIndex++) {
          const unit = course.units[unitIndex];
          if (unit.active || unit.status === PublicationStatusEnum.DRAFT) {
            if (unit.lessons) {
              for (let lessonIndex = 0; lessonIndex < unit.lessons.length; lessonIndex++) {
                const lesson = unit.lessons[lessonIndex];
                if (lesson.lessonId === quiz.lessonId) {
                  return { courseIndex, unitIndex, lessonIndex };
                }
              }
            }
          }
        }
      }
    }
  }

  return {};
}

export function findQuizQuestionSource(question: QuizQuestion, courses: CourseData[]) {
  for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
    const course = courses[courseIndex];
    if (course.active || course.status === PublicationStatusEnum.DRAFT) {
      if (course.units) {
        for (let unitIndex = 0; unitIndex < course.units.length; unitIndex++) {
          const unit = course.units[unitIndex];
          if (unit.active || unit.status === PublicationStatusEnum.DRAFT) {
            if (unit.lessons) {
              for (let lessonIndex = 0; lessonIndex < unit.lessons.length; lessonIndex++) {
                const lesson = unit.lessons[lessonIndex];
                if (lesson.quiz?.quizId === question.quizId) {
                  return { courseIndex, unitIndex, lessonIndex };
                }
              }
            }
          }
        }
      }
    }
  }

  return {};
}

export function filterLessons(lessons: LessonData[]) {
  const seenOriginalIds = new Set();
  return lessons.filter(lesson => {
    const isActive = lesson.active;
    const isDraft = lesson.status === PublicationStatusEnum.DRAFT;
    const isUnique = !seenOriginalIds.has(lesson.originalLessonId);

    if ((isActive || isDraft) && isUnique) {
      if (lesson.originalLessonId) {
        seenOriginalIds.add(lesson.originalLessonId);
      }
      return true;
    }
    return false;
  });
}

export function sortByPosition(a: { position?: number }, b: { position?: number }) {
  return (a.position ?? 0) - (b.position ?? 0);
}
