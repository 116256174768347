import { TableHead, TableRow, TableCell, SortDirection } from '@mui/material';
import { ReactNode } from 'react';
import { Caret, CaretPlaceholder } from '../Caret';
import Row from '../Row';
import { DataTableHeader, LastColumnElement } from './types';

interface DataTableHeaderProps {
  headers: DataTableHeader[];
  headerRight?: ReactNode;
  sortByCol: string;
  sortDirection: SortDirection;
  hideCaret?: boolean;
  outlined?: boolean;
  atLeastOneRowIsDeletable: boolean;
  lastColumnElement?: LastColumnElement | ((row: any) => LastColumnElement);
  onSort: (header: DataTableHeader) => void;
}

export const TableHeader = ({
  headers,
  headerRight,
  sortByCol,
  sortDirection,
  hideCaret,
  outlined,
  atLeastOneRowIsDeletable,
  lastColumnElement,
  onSort,
}: DataTableHeaderProps) => (
  <TableHead>
    <TableRow>
      {atLeastOneRowIsDeletable && (
        <TableCell sx={{ border: 'none', width: '50px' }}>&nbsp;</TableCell>
      )}
      {headers.map((header, index) => (
        <TableCell
          key={index}
          className={outlined ? 'paddedHeader' : undefined}
          style={{ userSelect: 'none', cursor: 'pointer' }}
          onClick={() => onSort(header)}>
          <Row style={{ alignItems: 'center' }}>
            {header.title}
            {!hideCaret &&
              (sortByCol === header.label ? (
                <Caret sortDirection={sortDirection === 'asc'} />
              ) : (
                <CaretPlaceholder />
              ))}
          </Row>
        </TableCell>
      ))}
      <TableCell style={{ justifyContent: 'flex-end' }}>
        <Row style={{ justifyContent: 'flex-end' }}>{headerRight}</Row>
      </TableCell>
      {lastColumnElement !== 'none' && !outlined && <TableCell>&nbsp;</TableCell>}
    </TableRow>
  </TableHead>
);
