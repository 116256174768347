import { useCallback, useEffect, useMemo, useState } from 'react';
import { LessonContent, LessonData, PublicationStatusEnum } from '../../../../models/CourseData';
import { Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Circle } from '@mui/icons-material';
import { useCommands } from '../../../../helpers/useCommands';
import { CreateLessonDraft } from '../../../../data/learning/commands/CreateLessonDraft';
import { useLoading } from '../../../../hooks/useLoading';
import { useTabContext } from '../../../../components/TabContext';
import { sortByPosition } from '../../../../data/learning/commands/utils';

export interface LessonSummaryContentProps {
  lesson: LessonData;
  disabled?: boolean;
  linked?: boolean;
}

const LessonSummaryContent: React.FC<LessonSummaryContentProps> = ({
  lesson,
  disabled,
  linked,
}) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const commands = useCommands();
  const { setActiveTab } = useTabContext();
  const [orderedContents, setOrderedContents] = useState<LessonContent[]>([]);
  const contents = useMemo(() => lesson.contents ?? [], [lesson.contents]);

  useEffect(() => {
    const sortedContents = contents.sort(sortByPosition);
    setOrderedContents(sortedContents);
  }, [contents]);

  const viewContent = useCallback(() => navigate('content'), [navigate]);

  const editContent = useCallback(async () => {
    try {
      setLoading(true);
      if (lesson.status === PublicationStatusEnum.PUBLISHED) {
        await commands.execute(CreateLessonDraft, lesson);
      }
      navigate('content/edit');
      setActiveTab(1);
    } finally {
      setLoading(false);
    }
  }, [commands, lesson, navigate, setActiveTab, setLoading]);

  return (
    <Card variant='ghost' color='primary'>
      <CardContent className='pad10'>
        <Grid
          display='flex'
          flexDirection='column'
          paddingLeft='30px'
          rowGap='10px'
          paddingBottom='10px'>
          <Grid display='flex' justifyContent='space-between'>
            <Typography variant='p24SemiBold' color='secondary' paddingY='10px'>
              Content
            </Typography>
            {!disabled && !linked && (
              <Link variant='p12' color='primary' onClick={editContent}>
                Edit
              </Link>
            )}
            {linked && (
              <Link variant='p12' color='primary' onClick={viewContent}>
                View
              </Link>
            )}
          </Grid>
          {orderedContents.map(content => (
            <Typography
              key={content.contentId}
              className='lesson-content-title'
              variant='p18SemiBold'
              color='secondary'
              display='flex'
              alignItems='center'
              columnGap='10px'>
              <Circle style={{ height: '8px', width: '8px' }} />
              <div dangerouslySetInnerHTML={{ __html: content.title ?? '' }} />
            </Typography>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LessonSummaryContent;
