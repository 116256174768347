import {
  Typography,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  Grid,
  useTheme,
  Link,
  TableBody,
  SxProps,
} from '@mui/material';
import { useContext, useState, useEffect, ReactNode } from 'react';
import { ToastbarContext } from '../../App';
import { useLoading } from '../../hooks/useLoading';
import { Toast } from '../../models/Toast';
import Spacer from '../Spacer';
import { DataTableDeleteModal } from './DataTableDeleteModal';
import {
  DataTableHeader,
  DataTableRowData,
  DataTableType,
  LastColumnElement,
  ModalText,
  SortConfig,
} from './types';
import { TableHeader } from './TableHeader';
import { ActivePaymentMethodData } from '../../api/apis/AdminPaymentApi';
import { MemberRow } from '../../models/OrganizationData';
import { DataTableRow } from './DataTableRow';
import { sortByDate, sortDollars } from '../../helpers/sortUtils';
import { isDate, isDollar } from '../../helpers/utilityFunctions';
import { Theme } from '@emotion/react';
import { EllipsesItem } from '../EllipsesMenu';

export interface DataTableProps {
  title?: string;
  headers: DataTableHeader[];
  headerRight?: ReactNode;
  sortBy?: string;
  backgroundColor?: string;
  outlined?: boolean;
  hideCaret?: boolean;
  scrollable?: boolean;
  data: DataTableRowData[];
  type?: DataTableType;
  lastColumnElement?: LastColumnElement | ((row: any) => LastColumnElement);
  lastColumnStyles?: SxProps<Theme>;
  button1Text?: string;
  button2Text?: string;
  onButton1Click?: (row: any) => void;
  onButton2Click?: (row: any) => void;
  button1Disabled?: boolean;
  button2Disabled?: boolean;
  onDelete?: (row: any) => Promise<void>;
  emptyStateText?: string;
  filtered?: boolean;
  action?: (row: any) => JSX.Element;
  action2?: (entry: any) => void;
  action3?: (entry: any) => JSX.Element;
  ellipsesItems?: EllipsesItem[];
  role?: string;
  itemsPerPage?: number;
  customSort?: {
    column: string;
    function: (a: any, b: any) => number;
  }[];
  loading?: boolean;
}

const DataTable = ({
  title,
  headers,
  headerRight,
  sortBy,
  backgroundColor,
  outlined,
  hideCaret,
  scrollable,
  data,
  type,
  lastColumnElement = 'button',
  lastColumnStyles,
  ellipsesItems,
  button1Text,
  button2Text,
  onButton1Click,
  onButton2Click,
  button1Disabled,
  button2Disabled,
  onDelete,
  emptyStateText,
  filtered,
  action,
  action2,
  action3,
  role,
  itemsPerPage,
  customSort,
  loading,
}: DataTableProps) => {
  const theme = useTheme();
  const cellVariant = outlined ? 'outlined' : undefined;
  const [sortedData, setSortedData] = useState<DataTableRowData[]>(data);
  const [isInitialSort, setIsInitialSort] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeData, setActiveData] = useState<any>();
  const [activeEntry, setActiveEntry] = useState<any>();
  const [activeEntry2, setActiveEntry2] = useState<any>();
  const [visibleCount, setVisibleCount] = useState(itemsPerPage || data.length);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    column: sortBy ?? headers[0].label ?? '',
    direction: 'asc',
  });

  const [modalText, setModalText] = useState<ModalText>();

  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const atLeastOneRowIsDeletable = data.some(row => row.deletableRow);
  const showLoadMore = itemsPerPage && data.length > visibleCount;

  const firstColumnStyles =
    type === 'licenseAgreement'
      ? { typography: { textDecoration: 'underline', color: theme.palette.primary.main } }
      : undefined;

  useEffect(() => {
    setLoading(loading ?? false);
    return () => setLoading(false);
  }, [loading, setLoading]);

  useEffect(() => {
    if (!type) return;
    createContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData]);

  const createContext = () => {
    if (!activeData) return;
    if (type === 'paymentMethod') {
      setModalText({
        title: 'Remove payment method?',
        primaryText: `Are you sure you want to remove ${
          (activeData as ActivePaymentMethodData).name
        } as a payment method?`,
        secondaryText: 'This action cannot be undone.',
        toastMessage: `${(activeData as ActivePaymentMethodData).name} removed`,
      });
      return;
    }
    if (type === 'member') {
      setModalText({
        title: 'Discontinue member?',
        primaryText: `Are you sure you want to discontinue ${
          (activeData as MemberRow).firstName + ' ' + (activeData as MemberRow).lastName
        } as a member?`,
        secondaryText:
          'This will remove any association between their account and your organization.',
        toastMessage: 'Member removed',
      });
      return;
    }
  };

  useEffect(() => {
    if (!data?.length) {
      setSortedData([]);
      return;
    }

    let sorted = [...data];
    const customSortFn = customSort?.find(sort => sort.column === sortConfig.column)?.function;
    const isAscending = sortConfig.direction === 'asc';

    if (customSortFn) {
      sorted.sort((a, b) => (isAscending ? -customSortFn(a, b) : customSortFn(a, b)));
    } else {
      sorted.sort((a: any, b: any) => {
        const aVal = a[sortConfig.column];
        const bVal = b[sortConfig.column];

        // Type-specific sorting
        if (isDate(aVal) && isDate(bVal)) {
          const comparison = sortByDate(aVal, bVal);
          return isAscending ? -comparison : comparison;
        }

        if (isDollar(aVal) && isDollar(bVal)) {
          const comparison = sortDollars(aVal, bVal);
          return isAscending ? -comparison : comparison;
        }

        // String sorting
        return isAscending
          ? aVal.toLowerCase() > bVal.toLowerCase()
            ? 1
            : -1
          : aVal.toLowerCase() > bVal.toLowerCase()
          ? -1
          : 1;
      });
    }

    if (isInitialSort) {
      const pinnedItems = sorted.filter(item => item.isPinned);
      const unpinnedItems = sorted.filter(item => !item.isPinned);
      sorted = [...pinnedItems, ...unpinnedItems];
    }

    setSortedData(visibleCount > 0 ? sorted.slice(0, visibleCount) : sorted);
  }, [data, sortConfig, visibleCount, customSort, isInitialSort]);

  const onDeleteClick = (row: any) => {
    if (type !== undefined) {
      setActiveData(row);
      setModalOpen(true);
      return;
    }
    deleteRow(row);
  };

  const deleteRow = async (row: any) => {
    if (onDelete) {
      await onDelete(row);
    } else {
      sortedData.splice(sortedData.indexOf(row), 1);
      setSortedData([...sortedData]);
    }
  };

  const onHandleSave = () => {
    deleteRow(activeData);
    setToast(new Toast({ message: modalText?.toastMessage, severity: 'success', open: true }));
  };

  const onLoadMore = () => {
    setVisibleCount(prev => prev + (itemsPerPage || data.length));
  };

  const handleHeaderSort = (header: { label: string }) => {
    setIsInitialSort(false);
    setSortConfig(prevConfig => {
      // Clicking same col
      if (prevConfig.column === header.label) {
        return {
          column: header.label,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      // Clicking new col
      return {
        column: header.label,
        direction: 'asc',
      };
    });
  };

  if (!sortedData?.length) {
    return (
      <>
        {title && (
          <>
            <Typography variant='subtitle1' color='secondary.main'>
              {title}
            </Typography>
            <Spacer height='xxs' />
          </>
        )}
        <Card variant='ghost' color={backgroundColor ?? 'primary'}>
          <CardContent sx={{ textAlign: 'center' }} className='dataTable'>
            <Spacer height='xxs' />
            <Typography variant='h3' color='secondary.main'>
              {filtered ? 'No results found' : emptyStateText}
            </Typography>
            <Spacer height='xxs' />
          </CardContent>
        </Card>
        <Spacer height='lg' />
      </>
    );
  }

  return (
    <>
      {type && modalText && (
        <DataTableDeleteModal
          open={modalOpen}
          setOpen={setModalOpen}
          modalText={modalText}
          onSave={onHandleSave}
          activeData={activeData}
        />
      )}
      {type === 'licenseAgreement' && action && activeEntry ? action(activeEntry) : null}
      {type === 'licenseAgreement' && action3 && activeEntry2 ? action3(activeEntry2) : null}

      {title && (
        <>
          <Typography variant='subtitle1' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='xxs' />
        </>
      )}

      <Card variant='ghost' color={backgroundColor ?? 'primary'}>
        <CardContent className={atLeastOneRowIsDeletable ? 'deletable' : 'dataTable'}>
          <TableContainer className={scrollable ? 'scrollable' : undefined} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='data table'>
              <TableHeader
                headers={headers}
                headerRight={headerRight}
                sortByCol={sortConfig.column}
                sortDirection={sortConfig.direction}
                hideCaret={hideCaret}
                outlined={outlined}
                atLeastOneRowIsDeletable={atLeastOneRowIsDeletable}
                lastColumnElement={lastColumnElement}
                onSort={handleHeaderSort}
              />

              <TableBody>
                {sortedData.map((row, index) => (
                  <DataTableRow
                    headers={headers}
                    key={index}
                    row={row}
                    index={index}
                    cellVariant={cellVariant}
                    type={type}
                    firstColumnStyles={firstColumnStyles}
                    lastColumnStyles={lastColumnStyles}
                    onDeleteClick={onDeleteClick}
                    onButton1Click={onButton1Click}
                    onButton2Click={onButton2Click}
                    button1Text={button1Text}
                    button2Text={button2Text}
                    button1Disabled={button1Disabled}
                    button2Disabled={button2Disabled}
                    action2={action2}
                    setActiveEntry={setActiveEntry}
                    setActiveEntry2={setActiveEntry2}
                    atLeastOneRowIsDeletable={atLeastOneRowIsDeletable}
                    lastColumnElement={lastColumnElement}
                    ellipsesItems={ellipsesItems}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {showLoadMore && (
            <Grid container justifyContent='center' mt={1} mb={1}>
              <Link
                onClick={onLoadMore}
                variant='p14'
                color='secondary'
                sx={{ textDecorationColor: theme.palette.secondary.main }}>
                Load More
              </Link>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Spacer height='lg' />
    </>
  );
};

export default DataTable;
