import { inject } from '@aesop-fables/containr';
import { DataCache, IDataCommand, injectDataCache } from '@aesop-fables/scrinium';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { UserApi } from '../../../api/apis/UserApi';
import { AdminCompartments, adminStorageKey } from '..';

export class RemoveOrgAdmin implements IDataCommand<number, void> {
  constructor(
    @inject(ApiKeys.User) private readonly api: UserApi,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  async execute(targetUserId: number): Promise<void> {
    await this.api.removeOrgUser(targetUserId);
    this.cache.reload('orgAdmins');
  }
}

export class RemoveTeamAdmin implements IDataCommand<number, void> {
  constructor(
    @inject(ApiKeys.User) private readonly api: UserApi,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  async execute(targetUserId: number): Promise<void> {
    await this.api.removeTeamUser(targetUserId);
    this.cache.reload('teamAdmins');
  }
}

export class RemoveGroupAdmin implements IDataCommand<number, void> {
  constructor(
    @inject(ApiKeys.User) private readonly api: UserApi,
    @injectDataCache(adminStorageKey) private readonly cache: DataCache<AdminCompartments>,
  ) {}

  async execute(targetUserId: number): Promise<void> {
    await this.api.removeGroupUser(targetUserId);
    this.cache.reload('groupAdmins');
  }
}
