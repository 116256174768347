/* eslint-disable react-hooks/rules-of-hooks */
import { useDataCache, useScrinium } from '@aesop-fables/scrinium';
import { createContainer } from '@aesop-fables/containr';
import type { IServiceContainer, IServiceModule } from '@aesop-fables/containr';
import { withOrganizationData } from '../data/organization';
import { withSignUpWizard } from '../services/signUp';
import { withLicenseData } from '../data/license';
import { withAuthWizard } from '../services/authenticated';
import { withOffboardingWizard } from '../services/offboarding';
import { withUserData } from '../data/user';
import { withAuthContext } from '../services/authentication';
import { withPricebookData } from '../data/pricebook';
import { withSignUpData } from '../data/signUp';
import { withProductTypeData } from '../data/productType';
import { withOffboardingData } from '../data/offboarding';
import { withSubscriptionData } from '../data/subscription';
import { withPaymentData } from '../data/payment';
import { withLicenseAgreementAcceptLogData } from '../data/licenseAgreementAcceptLog';
import { useIpmApis } from '../api/useIpmApis';
import { useApi } from '../api';
import { withMemberData } from '../data/members';
import { withMendixClientData } from '../data/mendixClient';
import { withTrustFamilyData } from '../data/trustFamily';
import { withPlanData } from '../data/plan';
import { withPlanWizard } from '../services/plan';
import { withDrillDownWizard } from '../services/drillDown';
import { withAdminData } from '../data/admin';
import { withGroupData } from '../data/group';
import { withTeamData } from '../data/team';
import { withMetricsData } from '../data/metrics';
import { withGroupFeatureMapData } from '../data/groupFeatureMap';
import { withPayrollDeductionData } from '../data/deductions';
import { withRestrictionData } from '../data/restrictions';
import { withInvoiceData } from '../data/invoices';
import { withOrganizationMetadata } from '../data/organizationMetadata';
import { withCustomerOrganizationData } from '../data/customerOrganization';
import { withReportData } from '../data/report';
import { usePredicateServiceModule } from '../predicates';
import { withLearningData } from '../data/learning';
import { useLearningServiceModule } from '../data/learning/LearningServiceModule';
import { withAutoLogout } from '../services/logout';
import { useOffboardingServiceModule } from '../data/offboarding/OffboardingServiceModule';

export const domain = window.location.hostname.split('.').slice(-2).join('.');
// const domain = 'qa-guidedchoice.com';

const API_URL = `https://ipm.${domain}/api/`;

export const bootstrap = (overrides: IServiceModule[] = []): IServiceContainer => {
  return createContainer(
    [
      useApi({
        ignoredRoutes: [],
        enableCurlLogging: false,
        enableRequestLogging: false,
        enableResponseLogging: false,
        retries: { retries: 2 },
        defaults: {
          baseURL: API_URL,
          withCredentials: true,
          withXSRFToken: true,
        },
      }),
      // Order matters
      useIpmApis,
      useDataCache([]),
      withAuthContext,
      withAutoLogout,
      useScrinium({
        modules: [
          withUserData,
          withLearningData,
          withGroupData,
          withGroupFeatureMapData,
          withTeamData,
          withOrganizationData,
          withOrganizationMetadata,
          withCustomerOrganizationData,
          withSubscriptionData,
          withInvoiceData,
          withLicenseData,
          withLicenseAgreementAcceptLogData,
          withPricebookData,
          withPaymentData,
          withProductTypeData,
          withOffboardingData,
          withMemberData,
          withMendixClientData,
          withTrustFamilyData,
          withPlanData,
          withAdminData,
          withSignUpData,
          withMetricsData,
          withPayrollDeductionData,
          withRestrictionData,
        ],
      }),
      withSignUpWizard,
      withDrillDownWizard,
      withAuthWizard,
      withOffboardingWizard,
      useOffboardingServiceModule,
      withPlanWizard,
      withReportData,
      usePredicateServiceModule,
      useLearningServiceModule,
      ...overrides,
    ],
    {
      runActivators: true,
    },
  );
};
