import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

export interface INavigatedFromBulkAddContext {
  navigatedFromBulkAdd: boolean;
  setNavigatedFromBulkAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigatedFromBulkAddContext = createContext<INavigatedFromBulkAddContext>({
  navigatedFromBulkAdd: false,
  setNavigatedFromBulkAdd: () => {},
});

export const NavigatedFromBulkAddProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [navigatedFromBulkAdd, setNavigatedFromBulkAdd] = useState<boolean>(false);

  const value = useMemo(
    () => ({ navigatedFromBulkAdd, setNavigatedFromBulkAdd }),
    [navigatedFromBulkAdd, setNavigatedFromBulkAdd],
  );

  return (
    <NavigatedFromBulkAddContext.Provider value={value}>
      {children}
    </NavigatedFromBulkAddContext.Provider>
  );
};

export const useNavigatedFromBulkAdd = () => {
  const context = useContext(NavigatedFromBulkAddContext);
  if (!context) {
    throw new Error('useNavigatedFromBulkAdd must be used within NavigatedFromBulkAddProvider');
  }
  return context;
};
