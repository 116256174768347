import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';
import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import { ScriniumServices } from '@aesop-fables/scrinium';
import { inject, createServiceModule, Scopes } from '@aesop-fables/containr';
import { UserCompartments, userStorageKey } from '../../data/user';
import { UserRole } from '../../models/UserData';
import { authContextKey } from '../authentication';
import type { IAuthenticationContext } from '../authentication';
import { GroupCompartments, groupStorageKey } from '../../data/group';
import { GroupData } from '../../models/GroupData';
import { TeamCompartments, teamStorageKey } from '../../data/team';
import { TeamData } from '../../models/TeamData';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { capitalize } from 'lodash';
import { NavItemProps } from '../../components/SideNavDrawer';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';

export const drillDownWizardKey = 'services/drillDownWizard';

export enum AdminTierTypeEnum {
  Group = 'Group',
  Team = 'Team',
  Organization = 'Organization',
  Root = 'Root',
}

const tierOrder: AdminTierTypeEnum[] = [
  AdminTierTypeEnum.Group,
  AdminTierTypeEnum.Team,
  AdminTierTypeEnum.Organization,
  AdminTierTypeEnum.Root,
];

export const getNextHigherTier = (
  currentTier: AdminTierTypeEnum,
): AdminTierTypeEnum | undefined => {
  const currentIndex = tierOrder.indexOf(currentTier);
  return currentIndex > 0 ? tierOrder[currentIndex - 1] : undefined;
};

export interface TierData {
  tier: AdminTierTypeEnum;
}

export const convertTierValueBackToKey = (value: string): AdminTierTypeEnum => {
  switch (capitalize(value)) {
    case 'Group':
      return AdminTierTypeEnum.Group;
    case 'Team':
      return AdminTierTypeEnum.Team;
    case 'Organization':
      return AdminTierTypeEnum.Organization;
    default:
      return AdminTierTypeEnum.Root;
  }
};

class DrillDownWizard implements Disposable {
  private initialized = new BehaviorSubject<boolean>(false);
  private origin = new BehaviorSubject<AdminTierTypeEnum | undefined>(undefined);
  private highestAccess = new BehaviorSubject<AdminTierTypeEnum | undefined>(undefined);
  private revenueAccess = new BehaviorSubject<AdminTierTypeEnum | undefined>(undefined);
  private tier = new BehaviorSubject<AdminTierTypeEnum | undefined>(undefined);
  private sidebarItems = new BehaviorSubject<NavItemProps[] | undefined>(undefined);

  constructor(
    @inject(authContextKey)
    private readonly authContext: IAuthenticationContext,
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
  ) {
    const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);
    const groupFeatureMapDataCache =
      this.appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);
    const orgDataCache = this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

    combineLatest([
      userDataCache.findCompartment('userRoles').initialized$,
      groupDataCache.initialized$,
      groupFeatureMapDataCache.initialized$,
      teamDataCache.findCompartment('team').initialized$,
      teamDataCache.findCompartment('allTeams').initialized$,
      orgDataCache.findCompartment('organization').initialized$,
      orgDataCache.findCompartment('allOrganizations').initialized$,
    ])
      .pipe(
        filter(
          ([userInit, groupInit, , teamInit, , orgInit, ,]) =>
            userInit && groupInit && teamInit && orgInit,
        ),
      )
      .subscribe(async ([userInit, groupInit, , teamInit, , orgInit, ,]) => {
        if (userInit && groupInit && teamInit && orgInit) {
          this.initialized.next(false);
          this.init();
        }
      });
  }

  async init() {
    try {
      const userDataCache = this.appStorage.retrieve<UserCompartments>(userStorageKey);
      const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);
      const groupFeatureMapDataCache =
        this.appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
      const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);
      const orgDataCache =
        this.appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);

      const userRoles = (
        userDataCache.findCompartment('userRoles') as DataCompartment<UserRole[]>
      ).getData() as UserRole[];
      const groupFeatureMap = (
        groupFeatureMapDataCache.findCompartment(
          'groupFeatureMap',
        ) as DataCompartment<GroupFeatureMapData>
      ).getData() as GroupFeatureMapData;

      const allGroups = (
        groupDataCache.findCompartment('allGroups') as DataCompartment<GroupData[]>
      ).getData() as GroupData[];
      const allTeams = (
        teamDataCache.findCompartment('allTeams') as DataCompartment<TeamData[]>
      ).getData() as TeamData[];
      const allOrgs = (
        orgDataCache.findCompartment('allOrganizations') as DataCompartment<OrganizationData[]>
      ).getData() as OrganizationData[];

      const numGroups = allGroups?.length ?? 0;
      const numTeams = allTeams?.length ?? 0;
      const numOrgs = allOrgs?.length ?? 0;

      const selectedGroup = (
        groupDataCache.findCompartment('group') as DataCompartment<GroupData>
      ).getData() as GroupData;
      const selectedTeam = (
        teamDataCache.findCompartment('team') as DataCompartment<TeamData>
      ).getData() as TeamData;
      const selectedOrg = (
        orgDataCache.findCompartment('organization') as DataCompartment<OrganizationData>
      ).getData() as OrganizationData;

      const highestAccess = this.findHighestRoleAccess(
        userRoles,
        selectedGroup,
        selectedTeam,
        selectedOrg,
      );
      const revenueAccess = this.findHighestRoleAccess(
        userRoles,
        selectedGroup,
        selectedTeam,
        selectedOrg,
        'REVENUE',
      );

      this.highestAccess.next(highestAccess);
      this.revenueAccess.next(revenueAccess);

      if (numGroups >= 2) {
        this.origin.next(AdminTierTypeEnum.Group);
      } else if (numTeams >= 2 && highestAccess !== AdminTierTypeEnum.Group) {
        this.origin.next(AdminTierTypeEnum.Team);
      } else if (
        numOrgs >= 2 &&
        highestAccess !== AdminTierTypeEnum.Group &&
        highestAccess !== AdminTierTypeEnum.Team
      ) {
        this.origin.next(AdminTierTypeEnum.Organization);
      }

      if (groupFeatureMap?.anyoneCanCreateOrgs) {
        this.tier.next(undefined);
      } else if (!selectedGroup) {
        this.tier.next(AdminTierTypeEnum.Group);
      } else if (!selectedTeam) {
        this.tier.next(AdminTierTypeEnum.Team);
      } else if (!selectedOrg) {
        this.tier.next(AdminTierTypeEnum.Organization);
      } else {
        this.tier.next(AdminTierTypeEnum.Root);
      }

      this.initialized.next(true);
    } catch (err) {
      console.error(err);
    }
  }

  get initialized$(): Observable<boolean> {
    return this.initialized;
  }

  get origin$(): Observable<AdminTierTypeEnum | undefined> {
    return this.origin;
  }

  get highestAccess$(): Observable<AdminTierTypeEnum | undefined> {
    return this.highestAccess;
  }

  get revenueAccess$(): Observable<AdminTierTypeEnum | undefined> {
    return this.revenueAccess;
  }

  get tier$(): Observable<AdminTierTypeEnum | undefined> {
    return this.tier;
  }

  get sidebarItems$(): Observable<NavItemProps[] | undefined> {
    return this.sidebarItems;
  }

  private findHighestRoleAccess(
    roles: UserRole[],
    selectedGroup: GroupData,
    selectedTeam: TeamData,
    selectedOrg: OrganizationData,
    roleType?: string,
  ): AdminTierTypeEnum {
    let highestLevel: AdminTierTypeEnum = AdminTierTypeEnum.Organization;
    const prefix = roleType ? `${roleType}_` : '';

    roles.forEach(x => {
      if (x.role?.includes(`GROUP_${prefix}ADMIN_${selectedGroup?.id}`)) {
        highestLevel = AdminTierTypeEnum.Group;
      } else if (
        highestLevel !== AdminTierTypeEnum.Group &&
        x.role?.includes(`TEAM_${prefix}ADMIN_${selectedTeam?.id}`)
      ) {
        highestLevel = AdminTierTypeEnum.Team;
      } else if (
        highestLevel !== AdminTierTypeEnum.Group &&
        highestLevel !== AdminTierTypeEnum.Team &&
        x.role?.includes(`ORGANIZATION_${prefix}ADMIN_${selectedOrg?.id}`)
      ) {
        highestLevel = AdminTierTypeEnum.Organization;
      }
    });

    return highestLevel;
  }

  findGroupNameById(): string {
    const groupDataCache = this.appStorage.retrieve<GroupCompartments>(groupStorageKey);
    const teamDataCache = this.appStorage.retrieve<TeamCompartments>(teamStorageKey);

    const allGroups = (
      groupDataCache.findCompartment('allGroups') as DataCompartment<GroupData[]>
    ).getData() as GroupData[];
    const team = (
      teamDataCache.findCompartment('team') as DataCompartment<TeamData>
    ).getData() as TeamData;

    let groupId = 0;
    if (this.highestAccess.pipe(map(x => x === AdminTierTypeEnum.Team))) {
      groupId = team?.groupId ?? 0;
    }
    // TODO b/e may possibly return groupId in org
    //  else if (this.highestAccess.pipe(map((x) => x === AdminTierTypeEnum.Organization))) {
    //   groupId = org?.groupId ?? 0
    // }

    const targetGroup = allGroups?.find(group => group.id === groupId);
    return targetGroup?.name ?? '';
  }

  setSidebarItems(items: NavItemProps[]) {
    this.sidebarItems.next(items);
  }

  reset() {
    this.initialized.next(false);
    this.origin.next(undefined);
    this.highestAccess.next(undefined);
    this.revenueAccess.next(undefined);
    this.tier.next(undefined);
    this.sidebarItems.next(undefined);
  }

  dispose(): void {
    console.log('disposing DrillDownWizard');
  }
}

export const withDrillDownWizard = createServiceModule(drillDownWizardKey, services => {
  services.autoResolve<DrillDownWizard>(drillDownWizardKey, DrillDownWizard, Scopes.Singleton);
});

export { DrillDownWizard };
