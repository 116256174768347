import { Route, Routes, useLocation } from 'react-router-dom';
import { TopNavBar } from '../../components';
import { NavigatedFromBulkAddProvider } from '../../hooks/useNavigatedFromBulkAdd';
import { DashboardRoutes } from '../../routes/DashboardRoutes';
import { CancellationPolicy, Contract } from '../../sign-up';
import {
  AddPackage,
  CustomUrl,
  EmailTemplate,
  EmployerContributions,
  EditInvestmentLineup,
  InvestmentLineup,
  MemberContributions,
  PlanBasics,
  RestrictMemberAccess,
  UploadLogo,
} from '../';
import CongratsAddPackage from '../license/CongratsAddPackage';
import CongratsLicenses from '../license/CongratsLicenses';
import AddMembersBulk from '../members/AddMembersBulk';
import RemoveMembersBulk from '../members/RemoveMembersBulk';
import * as Style from './style';

const Main: React.FC = () => {
  const location = useLocation();
  const addPackageFlow = location.pathname.includes('addPackage');
  const hideOrgsInNav =
    location.pathname.includes('plan-info/') ||
    location.pathname.includes('uploadLogo') ||
    location.pathname.includes('customUrl');

  return (
    <Style.MainWrapper>
      <Style.MainLayout>
        <TopNavBar
          hideDashboard={addPackageFlow}
          hideOrganizations={hideOrgsInNav}
          hideCompanyLogo
          useDrillDownCrumbs
        />
        <Style.MainSection>
          <Style.MainStyle>
            <NavigatedFromBulkAddProvider>
              <Routes>
                <Route path='/addPackage' element={<AddPackage />} />
                <Route path='/addPackage/congrats' element={<CongratsAddPackage />} />
                <Route path='/addPackage/contract' element={<Contract />} />
                <Route path='/cancellation-policy' element={<CancellationPolicy />} />
                <Route path='/communications/template' element={<EmailTemplate />} />
                <Route path='/congratsPurchase' element={<CongratsLicenses />} />
                <Route path='/customUrl' element={<CustomUrl />} />
                <Route path='/members/add' element={<AddMembersBulk />} />
                <Route path='/members/remove' element={<RemoveMembersBulk />} />
                <Route
                  path='/plan-info/edit-investment-lineup'
                  element={<EditInvestmentLineup />}
                />
                <Route
                  path='/plan-info/employer-contributions'
                  element={<EmployerContributions />}
                />
                <Route path='/plan-info/investment-lineup' element={<InvestmentLineup />} />
                <Route path='/plan-info/member-contributions' element={<MemberContributions />} />
                <Route path='/plan-info/plan-basics' element={<PlanBasics />} />
                <Route path='/restrictMembers' element={<RestrictMemberAccess />} />
                <Route path='/restrictMembers/add' element={<AddMembersBulk />} />
                <Route path='/uploadLogo' element={<UploadLogo />} />
                <Route path='/*' element={<DashboardRoutes />} />
              </Routes>
            </NavigatedFromBulkAddProvider>
          </Style.MainStyle>
        </Style.MainSection>
      </Style.MainLayout>
    </Style.MainWrapper>
  );
};

export default Main;
