import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { InviteAdminsDrawer, Page, Row, Spacer } from '../../components';
import { MutatorState, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { capitalize, lowerCase } from 'lodash';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { useService } from '@aesop-fables/containr-react';
import { UserData } from '../../models/UserData';
import { AdminCompartments, adminStorageKey } from '../../data/admin';
import { AddGroupAdmin, AddOrgAdmin, AddTeamAdmin } from '../../data/admin/mutations';
import AdminDataTable from '../../components/AdminDataTable';
import { UserCompartments, userStorageKey } from '../../data/user';
import { useAdminFilters } from '../../hooks/useAdminHooks';
import { BillingAdminManager } from './BillingAdmin';

type RoleAccessInfo = {
  role: AdminTierTypeEnum;
  admins: UserData[] | undefined;
  addAdmin: MutatorState<UserData>;
};

const AdminAccess: React.FC = () => {
  const appStorage = useAppStorage();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { filterAdmins } = useAdminFilters();

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);

  const tier = useObservable(drillDownWizard.tier$);
  const groupAdmins = useObservable(adminDataCache.observe$<UserData[]>('groupAdmins'));
  const teamAdmins = useObservable(adminDataCache.observe$<UserData[]>('teamAdmins'));
  const orgAdmins = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins'));
  const currentUser = useObservable(userDataCache.observe$<UserData>('user'));

  const determineRoleAndComponents = (tier: string | undefined): RoleAccessInfo => {
    switch (tier) {
      case AdminTierTypeEnum.Team:
        return {
          role: AdminTierTypeEnum.Group,
          admins: groupAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddGroupAdmin()),
        };
      case AdminTierTypeEnum.Organization:
        return {
          role: AdminTierTypeEnum.Team,
          admins: teamAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddTeamAdmin()),
        };
      default:
        return {
          role: AdminTierTypeEnum.Organization,
          admins: orgAdmins,
          // eslint-disable-next-line react-hooks/rules-of-hooks
          addAdmin: useMutation(new AddOrgAdmin()),
        };
    }
  };

  const { role, admins, addAdmin } = determineRoleAndComponents(tier);

  const filteredAdmins = filterAdmins(admins, currentUser);

  return (
    <Page title={`${capitalize(role)} access`}>
      <Spacer height='xxs' />
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid>
          <Typography variant='p20' color='secondary.main'>
            Give access to other people {role === AdminTierTypeEnum.Team ? 'on' : 'in'} your{' '}
            {lowerCase(role)}.
          </Typography>
          {role === AdminTierTypeEnum.Organization && (
            <>
              <Spacer height='xxs' />
              <BillingAdminManager admins={filteredAdmins} currentUser={currentUser} />
            </>
          )}
        </Grid>
        <Button onClick={() => setDrawerOpen(true)} color='primary'>
          Invite to {lowerCase(role)}
        </Button>
        <InviteAdminsDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          role={role}
          addAdmin={addAdmin}
        />
      </Row>
      <Spacer height='sm' />
      <AdminDataTable
        role={role}
        usersList={filteredAdmins ?? []}
        currentUser={currentUser}
        loading={!filteredAdmins}
      />
    </Page>
  );
};

export default AdminAccess;
