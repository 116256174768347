import { IDataCommand } from '@aesop-fables/scrinium';
import { inject } from '@aesop-fables/containr';
import type { AxiosResponse } from 'axios';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { RevenueShareApi } from '../../../api/apis/RevenueShareApi';

interface DateRange {
  startDate: string;
  endDate: string;
}

export class GenerateTeamRevShare implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateTeamRevShare(startDate, endDate);
  }
}

export class GenerateTeamCharges implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateTeamCharges(startDate, endDate);
  }
}

export class GenerateOrgRevShare implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateOrgRevShare(startDate, endDate);
  }
}

export class GenerateOrgCharges implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateOrgCharges(startDate, endDate);
  }
}

export class GenerateGroupRevShare implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateGroupRevShare(startDate, endDate);
  }
}

export class GenerateGroupCharges implements IDataCommand<DateRange, AxiosResponse> {
  constructor(@inject(ApiKeys.RevenueShare) private readonly api: RevenueShareApi) {}

  async execute({ startDate, endDate }: DateRange): Promise<AxiosResponse> {
    return await this.api.generateGroupCharges(startDate, endDate);
  }
}
