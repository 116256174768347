import React, { useContext, useEffect, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Card, CardContent, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { Svgs } from '../assets/svg';
import { Row, Logo, Spacer } from '.';
import { Toast } from '../models/Toast';
import { ToastbarContext } from '../App';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../data/organization';
import { OrganizationData } from '../models/OrganizationData';
import { API_URL } from '../api/apis';
import {
  formatValidationErrors,
  ValidationMessages,
  ValidationResult,
} from '../helpers/validation';

type UploadTypes = 'logo' | 'icon' | 'background' | 'tsv' | 'csv' | 'txt';
interface UploadDropZoneProps {
  uploadTypes: UploadTypes[];
  setData: React.Dispatch<React.SetStateAction<any | undefined>>;
  dropzoneContent?: React.ReactNode;
  sx?: SxProps<Theme>;
  validate?: (file: File) => Promise<ValidationResult>;
  triggerClearUpload?: boolean;
  setTriggerClearUpload?: React.Dispatch<React.SetStateAction<boolean>>;
}

const imageFileTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
};

const fileTypesMap = {
  tsv: { 'file/tsv': ['.tsv'] },
  csv: { 'text/csv': ['.csv'] },
  txt: { 'text/plain': ['.txt'] },
  logo: imageFileTypes,
  icon: imageFileTypes,
  background: imageFileTypes,
};

const formatFileRejections = (fileRejections: readonly FileRejection[]): string[] => {
  return fileRejections.flatMap(rejection =>
    rejection.errors.map(error => {
      switch (error.code) {
        case ErrorCode.FileTooLarge:
          return 'File is larger than 1MB';
        case ErrorCode.FileInvalidType:
          return 'File type is invalid';
        case ErrorCode.FileTooSmall:
          return 'File is too small';
        default:
          return error.message;
      }
    }),
  );
};

const UploadDropZone: React.FC<UploadDropZoneProps> = ({
  uploadTypes,
  setData,
  sx,
  dropzoneContent,
  validate,
  triggerClearUpload,
  setTriggerClearUpload,
}) => {
  const theme = useTheme();
  const appStorage = useAppStorage();
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const { id, logoUri } = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  ) ?? { id: undefined, logoUri: undefined };
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { setToast } = useContext(ToastbarContext);
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: uploadTypes.reduce((acc, type) => ({ ...acc, ...fileTypesMap[type] }), {}),
    maxFiles: 1,
    maxSize: 1000000, // Maximum file size (in bytes) => 1MB
    multiple: false,
  });

  useEffect(() => {
    if (triggerClearUpload && setTriggerClearUpload) {
      clearUpload();
      setTriggerClearUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTriggerClearUpload, triggerClearUpload]);

  // initialize logo if one already exists
  useEffect(() => {
    if (logoUri) {
      setUploadedImage(`${API_URL}organization/logo/${id}`);
    }
  }, [id, logoUri, setUploadedImage]);

  useEffect(() => {
    const handleFileValidation = async () => {
      if (acceptedFiles.length + fileRejections.length > 1) {
        setToast(
          new Toast({
            message: 'You can only upload one file at a time',
            severity: 'error',
            open: true,
            autoHideDuration: 5000,
          }),
        );
        return;
      }

      const errors: string[] = [];

      if (fileRejections.length > 0) {
        errors.push(...formatFileRejections(fileRejections));
      }
      if (acceptedFiles.length > 0 && validate) {
        const validation = await validate(acceptedFiles[0]);
        if (!validation.isValid && validation.errors) {
          errors.push(...validation.errors);
        }
      }

      if (errors.length > 0) {
        setToast(
          new Toast({
            message: formatValidationErrors(errors, ValidationMessages.FILE_REQUIREMENTS),
            severity: 'error',
            open: true,
            autoHideDuration: 5000,
          }),
        );
        return;
      }

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (uploadTypes.some(type => ['logo', 'icon'].includes(type))) {
          setUploadedImage(URL.createObjectURL(file));
        }
        setFileName(file.name);
        setData(file);
      }
    };

    handleFileValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles, fileRejections]);

  const clearUpload = () => {
    if (uploadTypes.some(type => ['logo', 'icon'].includes(type))) {
      setUploadedImage('');
    }
    setFileName('');
    setData(undefined);
  };

  return (
    <>
      {uploadTypes.includes('logo') && uploadedImage.length > 0 ? (
        <Row style={{ alignItems: 'center' }}>
          <Svgs.IconDelete onClick={clearUpload} style={{ cursor: 'pointer' }} />
          <Spacer width='xxs' />
          <Logo imageUrl={uploadedImage} />
        </Row>
      ) : uploadTypes.some(type => ['tsv', 'txt', 'csv'].includes(type)) && fileName ? (
        <Row style={{ alignItems: 'center' }}>
          <Svgs.IconDelete onClick={clearUpload} style={{ cursor: 'pointer' }} />
          <Spacer width='xxs' />
          <Card variant='innerCard' sx={{ height: '50px' }}>
            <CardContent className='whoPays'>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.IconDocument />
                <Spacer width='xxs' />
                <Typography variant='p20' color='secondary.dark'>
                  {fileName}
                </Typography>
              </Row>
            </CardContent>
          </Card>
        </Row>
      ) : (
        <Card
          {...getRootProps()}
          sx={{
            cursor: 'pointer',
            width: '50%',
            height: '150px',
            boxShadow: 'none',
            borderWidth: '1px',
            borderStyle: 'dashed',
            borderColor: theme.palette.info.main,
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#e6e9ea',
            ...sx,
          }}>
          <div style={{ textAlign: 'center' }}>
            <input {...getInputProps()} />
            {dropzoneContent ? (
              dropzoneContent
            ) : (
              <>
                <Typography variant='p18Bold' color='primary' style={{ display: 'inline' }}>
                  Choose a file
                </Typography>
                <Typography variant='h2' style={{ display: 'inline' }}>
                  &nbsp;or drag it here to upload.
                </Typography>
              </>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default UploadDropZone;
