import { useContext, useEffect, useState } from 'react';
import { EditableCard, KeyValueLabel, RightDrawer, Row, Spacer } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import DeductionInfo from '../../sign-up/Adp/DeductionInfo';
import { PayrollDeductionCompartments, payrollDeductionStorageKey } from '../../data/deductions';
import {
  ContributionTypesEnum,
  DeductionTypesEnum,
  PayrollDeductionData,
  getContributionTypeEnum,
} from '../../api/apis/CompanyPayrollDeductionApi';
import { of } from 'rxjs';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { mutateFieldValue } from '../../helpers/utilityFunctions';
import { Form } from 'react-final-form';
import { AddDeductions, EditDeduction } from '../../data/deductions/mutations';
import { useLoading } from '../../hooks/useLoading';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { Toast } from '../../models/Toast';
import { ToastbarContext } from '../../App';
import { Button } from '@mui/material';

const PayrollDeductionCard: React.FC = () => {
  const appStorage = useAppStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const deductionDataCache = appStorage.retrieve<PayrollDeductionCompartments>(
    payrollDeductionStorageKey,
  );
  const deductionData = useObservable(
    deductionDataCache?.observe$<PayrollDeductionData[]>('deductions') ?? of(undefined),
  );
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  return groupFeatureMapData?.enableCompanyPayrollInfo &&
    deductionData &&
    deductionData?.length > 0 ? (
    <EditableCard
      onEditClick={() => setDrawerOpen(drawer => !drawer)}
      editable
      title='Payroll deductions'>
      {deductionData?.map(deduction => (
        <>
          <KeyValueLabel keyStyles={{ fontWeight: '600' }} keyString={deduction.deductionName} />
          <KeyValueLabel keyString='Deduction code' value={deduction.id.deductionCode} />
          <KeyValueLabel
            keyString='Deduction type'
            value={`${DeductionTypesEnum[deduction.id.gcCategory!]} ${
              deduction.catchup ? 'catch-up' : ''
            }`}
          />
          <KeyValueLabel
            keyString='Contribution type'
            value={
              ContributionTypesEnum[deduction.deductionType as keyof typeof ContributionTypesEnum]
            }
          />
          <KeyValueLabel keyString='Company name' value={deduction.companyName} />
          <KeyValueLabel keyString='Company code' value={deduction.id.companyCode} />
          <Spacer height='xxs' />
        </>
      ))}
      <PayrollDeductionCardEdit
        title='Payroll deduction info'
        smallTitle='Edit plan info'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        deductionData={deductionData}
      />
    </EditableCard>
  ) : null;
};

interface PayrollDeductionEditProps {
  deductionData: PayrollDeductionData[];
  title: string;
  smallTitle?: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayrollDeductionCardEdit: React.FC<PayrollDeductionEditProps> = ({
  deductionData,
  title,
  smallTitle,
  drawerOpen,
  setDrawerOpen,
}) => {
  const appStorage = useAppStorage();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const currentOrganization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const editDeductionData = useMutation(new EditDeduction());
  const addDeduction = useMutation(new AddDeductions());
  const [isExisting, setIsExisting] = useState({});

  useEffect(() => {
    if (!deductionData || Object.keys(deductionData).length === 0) return;
  }, [deductionData, drawerOpen]);

  const handleSaveDeduction = (index: number, isExistingDeduction: boolean) => {
    setIsExisting(prevStates => ({
      ...prevStates,
      [index]: isExistingDeduction,
    }));
  };

  const onSubmit: any = async (values: any) => {
    let addedDeductions = [];
    setLoading(true);

    for (let i = 0; values[`deductionName-${i}`] !== undefined; i++) {
      const companyName = values[`companyName-${i}`];
      const deductionName = values[`deductionName-${i}`];
      try {
        if ((isExisting as any)[i]) {
          await editDeductionData.action({
            id: {
              organizationId: currentOrganization?.id,
              companyCode: deductionData?.[i].id.companyCode,
              deductionCode: deductionData?.[i].id.deductionCode,
              gcCategory: deductionData?.[i].id.gcCategory,
            },
            companyName: companyName,
            deductionName: deductionName,
            deductionType: deductionData?.[i].deductionType,
            catchup: deductionData?.[i].catchup,
          });
        } else {
          const companyCode = values[`companyCode-${i}`];
          const deductionCode = values[`deductionCode-${i}`];
          const contributionType = values[`contributionType-${i}`];
          const deductionType = values[`deductionType-${i}`];
          const catchup = deductionType.includes('catch-up');
          const dedTypeKey = deductionType.replace(/ catch-up$/, '');
          addedDeductions.push({
            id: {
              organizationId: currentOrganization?.id!,
              companyCode: companyCode,
              deductionCode: deductionCode,
              gcCategory: DeductionTypesEnum[dedTypeKey as keyof typeof DeductionTypesEnum],
            },
            companyName: companyName,
            deductionName: deductionName,
            deductionType: getContributionTypeEnum(contributionType),
            catchup: catchup,
          });
          await addDeduction.action(addedDeductions);
        }
      } catch (err) {
        console.error(err);
        setToast(new Toast({ severity: 'error', open: true }));
      }
    }
    setDrawerOpen(false);
    setLoading(false);
    setToast(new Toast({ message: 'Payroll deductions saved!', severity: 'success', open: true }));
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen} title={title} smallTitle={smallTitle}>
      <Form onSubmit={onSubmit} mutators={{ mutateFieldValue }}>
        {({ handleSubmit }) => {
          return (
            <>
              <DeductionInfo inEditDrawer={true} onSaveDeduction={handleSaveDeduction} />
              <Spacer height='s12' />
              <Row style={{ justifyContent: 'space-between' }}>
                <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                  Cancel
                </Button>
                <Button style={{ width: 127 }} color='primary' onClick={handleSubmit}>
                  Save
                </Button>
              </Row>
            </>
          );
        }}
      </Form>
    </RightDrawer>
  );
};

export default PayrollDeductionCard;
