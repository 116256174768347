import { Button, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { Row, Spacer, Column, RightDrawer, HorizontalFormInput } from '.';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { DrillDownWizard, drillDownWizardKey } from '../services/drillDown';

interface Props {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (...values: any[]) => void;
}

const CreateEntityDrawer: React.FC<Props> = ({ drawerOpen, setDrawerOpen, onSubmit }) => {
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);

  const submitHandler = async (values: any) => {
    try {
      onSubmit(values);
      setDrawerOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const validateName = (value: string) => {
    if (value.length > 128) {
      return 'Name should not exceed 128 characters.';
    }
    return undefined;
  };

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Spacer height='xxs' />

      <Typography color='secondary.main' variant='p30'>
        Create {tier?.toLowerCase()}
      </Typography>
      <Spacer height='sm' />

      <Form onSubmit={submitHandler}>
        {({ handleSubmit }) => (
          <>
            <Column
              style={{
                width: '90%',
              }}>
              <HorizontalFormInput
                label='Name'
                fieldName='name'
                validate={validateName}
                rightAlignedKey
                required
                autoFocus
              />
              <Spacer height='sm' />
            </Column>
            <Row style={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={() => setDrawerOpen(false)}>
                Cancel
              </Button>
              <Button color='primary' onClick={handleSubmit}>
                Create {tier?.toLowerCase()}
              </Button>
            </Row>
          </>
        )}
      </Form>
    </RightDrawer>
  );
};

export default CreateEntityDrawer;
