import { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { DataTable, Page, Spacer } from '../../components';
import AddAMember from './AddAMember';
import EditAMember from './EditAMember';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { MemberCompartments, membersStorageKey } from '../../data/members';
import { RemoveAMember } from '../../data/members/mutations';
import { MemberData, MembersApi } from '../../api/apis/MembersApi';
import { useNavigate } from 'react-router-dom';
import { useService } from '@aesop-fables/containr-react';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { Svgs } from '../../assets/svg';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { sortAdminStatusThenDate } from '../../helpers/sortUtils';
import { capitalize } from 'lodash';
import MembersOffboardingHeader from './MembersOffboardingHeader';
import MembersDashboardHeader from './MembersDashboardHeader';

interface MembersProps {
  variant?: 'offboarding' | 'dashboard';
}

const Members: React.FC<MembersProps> = ({ variant = 'dashboard' }) => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const [addMemberDrawer, setAddMemberDrawer] = useState(false);
  const [editMemberDrawer, setEditMemberDrawer] = useState(false);
  const [selectedMemberForEdit, setSelectedMemberForEdit] = useState<any>({});

  const memberDataCache = appStorage.retrieve<MemberCompartments>(membersStorageKey);
  const membersData = useObservable(memberDataCache.observe$<MemberData[]>('members'));
  const membersApi = useService<MembersApi>(ApiKeys.Members);
  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(orgDataCache.observe$<OrganizationData>('organization'));

  const removeAMember = useMutation(new RemoveAMember());
  const [searchWord, setSearchWord] = useState('');
  const [filteredMembersDataForTable, setFilteredMembersDataForTable] = useState<any[]>([]);

  const onBulkAdd = () => navigate('add');
  const onBulkRemove = () => navigate('remove');

  const offboardingView = variant === 'offboarding';

  const headers = [
    { label: 'firstName', title: 'First Name' },
    {
      label: 'lastName',
      title: 'Last Name',
    },
    {
      label: 'email',
      title: 'Email',
    },
    {
      label: 'status',
      title: 'Status',
    },
  ];

  const exportButton = (
    <Button
      color='secondary'
      style={{ fontSize: '12px' }}
      onClick={async () => {
        await membersApi
          .exportMembers()
          .then(response => {
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = orgData ? `${orgData.name}_members.csv` : 'exported_members.csv';
            link.click();
          })
          .catch(error => {
            console.error('Error downloading the file', error);
            setToast(
              new Toast({
                message: 'Error downloading file.',
                severity: 'error',
                open: true,
              }),
            );
          });
      }}>
      <Svgs.IconExport />
      <Spacer width='xxxs' />
      Export CSV
    </Button>
  );

  const transformMembersData = (membersData: MemberData[]) => {
    return membersData.map(member => ({
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      status:
        capitalize(member.status) +
        ' on ' +
        new Date(member.statusTimestamp).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      id: member.id,
    }));
  };

  const membersDataForTable = useMemo(() => {
    if (!membersData) return [];
    return transformMembersData(membersData);
  }, [membersData]);

  useEffect(() => {
    const performSearch = async () => {
      if (!searchWord || searchWord.length < 3) {
        setFilteredMembersDataForTable(membersDataForTable);
        return;
      }
      try {
        const searchResults = await membersApi.searchMembers(searchWord);
        setFilteredMembersDataForTable(transformMembersData(searchResults.data));
      } catch (err) {
        console.error('Error searching members:', err);
      }
    };
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, membersDataForTable]);

  const removeMember = async (row: any) => {
    try {
      await removeAMember.action(row.id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Page
      title={offboardingView ? undefined : 'Members'}
      style={offboardingView ? { margin: 0 } : undefined}>
      <Spacer height='xxs' />
      {offboardingView ? (
        <MembersOffboardingHeader onBulkAdd={onBulkAdd} setAddMemberDrawer={setAddMemberDrawer} />
      ) : (
        <MembersDashboardHeader
          filteredMembersDataForTable={filteredMembersDataForTable}
          setSearchWord={setSearchWord}
          onBulkAdd={onBulkAdd}
          onBulkRemove={onBulkRemove}
          setAddMemberDrawer={setAddMemberDrawer}
        />
      )}
      <Spacer height='xxs' />
      <DataTable
        headers={headers}
        sortBy='firstName'
        customSort={[
          {
            column: 'status',
            function: sortAdminStatusThenDate,
          },
        ]}
        headerRight={exportButton}
        data={filteredMembersDataForTable}
        type={'member'}
        button1Text={'Edit'}
        onDelete={row => removeMember(row)}
        onButton1Click={row => {
          setEditMemberDrawer(true);
          setSelectedMemberForEdit(row);
        }}
        emptyStateText="You haven't added any members yet."
        filtered={searchWord.length > 0}
      />
      <AddAMember drawerOpen={addMemberDrawer} setDrawerOpen={setAddMemberDrawer} />
      <EditAMember
        member={selectedMemberForEdit}
        drawerOpen={editMemberDrawer}
        setDrawerOpen={setEditMemberDrawer}
      />
    </Page>
  );
};

export default Members;
