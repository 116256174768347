import { Typography } from '@mui/material';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EditDrawer, KeyValueLabel, Spacer } from '../../components';
import { MutationStatus, useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { OrganizationCard } from './OrganizationInfo';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { CustomBlurbTextBox } from '../offboarding/CustomBlurb';
import { CreateCustomBlurb, UpdateCustomBlurb } from '../../data/organizationMetadata/mutations';
import {
  OrganizationMetadataCompartments,
  organizationMetadataStorageKey,
} from '../../data/organizationMetadata';
import { OrganizationMetadata } from '../../models/OrganizationMetadata';

const CustomBlurbCard: React.FC<React.PropsWithChildren> = () => {
  const appStorage = useAppStorage();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const organizationMetadataCache = appStorage.retrieve<OrganizationMetadataCompartments>(
    organizationMetadataStorageKey,
  );
  const organizationMetadata = useObservable(
    organizationMetadataCache.observe$<OrganizationMetadata>('metadata'),
  );
  const customBlurb = organizationMetadata?.signupPageText || 'None';

  return (
    <OrganizationCard title='Member sign up page' editable={true} setDrawerOpen={setDrawerOpen}>
      <KeyValueLabel
        keyString='Custom blurb'
        value={customBlurb}
        valueStyles={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '10%',
        }}
      />
      <CustomBlurbEdit
        title='Member sign up page'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </OrganizationCard>
  );
};

interface CustomBlurbEditProps {
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomBlurbEdit: React.FC<CustomBlurbEditProps> = ({ title, drawerOpen, setDrawerOpen }) => {
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const createCustomBlurb = useMutation(new CreateCustomBlurb());
  const updateCustomBlurb = useMutation(new UpdateCustomBlurb());
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const organizationMetadataCache = appStorage.retrieve<OrganizationMetadataCompartments>(
    organizationMetadataStorageKey,
  );
  const organizationData = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );
  const organizationMetadata = useObservable(
    organizationMetadataCache.observe$<OrganizationMetadata>('metadata'),
  );

  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [customBlurb, setCustomBlurb] = useState('');
  const saveCustomBlurb = useMemo(() => {
    return !organizationMetadata ? createCustomBlurb : updateCustomBlurb;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationMetadata]);

  useEffect(() => {
    if (createCustomBlurb.error || updateCustomBlurb.error) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error saving custom blurb. Please try again.',
        }),
      );
      return;
    } else if (
      createCustomBlurb.status === MutationStatus.Complete ||
      updateCustomBlurb.status === MutationStatus.Complete
    ) {
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Custom blurb saved!',
        }),
      );
      setDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCustomBlurb.status, createCustomBlurb.status, updateCustomBlurb.status]);

  const onSubmit = async () => {
    await saveCustomBlurb.action({
      organizationId: organizationData?.id ?? 0,
      signupPageText: customBlurb,
    });
  };

  return (
    <>
      <EditDrawer
        onSubmit={onSubmit}
        title={title}
        smallTitle='Edit organization'
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        disableSave={isLimitExceeded}
        preventAutoClose={true}>
        <Spacer height='xxs' />
        <Typography variant='h2' color='info.main'>
          Want to say something to your members on your custom sign up page?
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p16' fontStyle='italic' color='info.main'>
          Example: Welcome to your gateway to financial wellness with 3Nickels, the innovative app
          for financial health. This portal unlocks access to essential tools and resources for your
          financial journey. From budgeting, investing, and saving to personalized financial advice,
          3Nickels simplifies personal finance, empowering you towards financial freedom. Start now
          and embrace your path to financial wellness.
        </Typography>
        <Spacer height='xs' />
        <CustomBlurbTextBox
          onLimitExceeded={overLimit => setIsLimitExceeded(overLimit)}
          onChange={setCustomBlurb}
          autoFocus
        />
      </EditDrawer>
    </>
  );
};

export default CustomBlurbCard;
