import { Typography, Card, CardContent, Button, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Svgs } from '../../assets/svg';
import { Column, Row, Spacer, DownloadButton, UploadDropZone, DataTable } from '../../components';
import BulletedList from '../../components/BulletedList';
import { MemberData, MembersApi } from '../../api/apis/MembersApi';
import { Toast } from '../../models/Toast';
import { useService } from '@aesop-fables/containr-react';
import { useAppStorage } from '@aesop-fables/scrinium';
import { useNavigate } from 'react-router-dom';
import { ToastbarContext } from '../../App';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { UserCompartments, userStorageKey } from '../../data/user';
import { useLoading } from '../../hooks/useLoading';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { HelpBody, HelpTitle } from '../../components/HelpText';
import { Bold } from '../../components/Bold';
import { MemberCompartments, membersStorageKey } from '../../data/members';
import ConfirmBulkAddModal from './ConfirmBulkAddModal';

interface MemberFileUploadProps {
  mode: 'add/edit' | 'remove';
  leftButtonText?: string;
  rightButtonText?: string;
  disableButton?: boolean;
  editing?: boolean;
  onSubmit?: () => void;
  leftButtonOnSubmit?: () => void;
}

const MembersFileUpload: React.FC<MemberFileUploadProps> = ({
  onSubmit,
  leftButtonOnSubmit,
  leftButtonText,
  rightButtonText,
  mode,
  disableButton,
  editing,
}) => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const membersApi = useService<MembersApi>(ApiKeys.Members);
  const memberDataCache = appStorage.retrieve<MemberCompartments>(membersStorageKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const [file, setFile] = useState<Blob | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [proceedWithBulkAdd, setProceedWithBulkAdd] = useState<boolean>(false);
  const [clearUpload, setClearUpload] = useState<boolean>(false);
  const [invalidRows, setInvalidRows] = useState<any[]>([]);
  const [invalidRowSize, setInvalidRowSize] = useState<number>(0);
  const [validRowSize, setValidRowSize] = useState<number>(0);
  const [persistedRowSize, setPersistedRowSize] = useState<number>(0);
  const [errored, setErrored] = useState(false);
  const addMode = mode === 'add/edit';

  const headers = [
    {
      label: 'row',
      title: 'Row',
    },
    { label: 'firstName', title: 'First Name' },
    {
      label: 'lastName',
      title: 'Last Name',
    },
    {
      label: 'email',
      title: 'Email',
    },
  ];

  useEffect(() => {
    if (!file) {
      setValidRowSize(0);
      setPersistedRowSize(0);
    }
  }, [file]);

  useEffect(() => {
    if (file && addMode) {
      setOpenConfirmModal(true);
      setProceedWithBulkAdd(false);
    }
  }, [file, addMode]);

  useEffect(() => {
    if (!file) return;
    if (addMode && !proceedWithBulkAdd) return;
    const uploadFile = async () => {
      try {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', file);
        const result = addMode
          ? await membersApi.bulkAddMembers(formData)
          : await membersApi.bulkRemoveMembers(formData);

        const { invalidRowSize, invalidRows, validRowSize, persistedRowSize } = result.data;
        setInvalidRows(invalidRows);
        setInvalidRowSize(invalidRowSize);
        setValidRowSize(validRowSize);
        setPersistedRowSize(persistedRowSize);
        setErrored(false);

        if (invalidRowSize === 0 && validRowSize === 0) {
          setToast(
            new Toast({
              message: 'Error parsing file. Please check your formatting and try again.',
              severity: 'error',
              open: true,
            }),
          );
          setErrored(true);
        } else if (invalidRowSize > 0) {
          const invalidRowsObject: Record<string, MemberData> = result.data.invalidRows;
          const invalidRowsArray = Object.entries(invalidRowsObject).map(([key, member]) => {
            return {
              row: key,
              firstName: member.firstName,
              lastName: member.lastName,
              email: member.email,
              deletableRow: false,
              id: member.id,
            };
          });
          setInvalidRows(invalidRowsArray);
          setToast(
            new Toast({
              message: 'Sorry, we ran into a problem! Please correct any errors and upload again.',
              severity: 'error',
              open: true,
            }),
          );
          setErrored(true);
        } else {
          await memberDataCache.reload('members');
          setToast(
            new Toast({
              message: 'File successfully uploaded!',
              severity: 'success',
              open: true,
            }),
          );
        }
      } catch (err) {
        console.error(err);
        setToast(
          new Toast({
            message: 'Error parsing file. Please check your formatting and try again.',
            severity: 'error',
            open: true,
          }),
        );
        setErrored(true);
      } finally {
        if (addMode) setProceedWithBulkAdd(false);
        setLoading(false);
      }
    };

    uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMode, file, proceedWithBulkAdd]);

  const downloadSampleFile = (fileType: 'csv' | 'tsv', delimiter: string) => {
    const sampleData = [
      ['First Name', 'Last Name', 'Email'],
      ['John', 'Doe', 'johndoe@example.com'],
      ['Jane', 'Doe', 'janedoe@example.com'],
    ];
    const fileContent = sampleData.map(e => e.join(delimiter)).join('\r\n');
    const mimeType = fileType === 'csv' ? 'text/csv' : 'text/plain';
    const fileExtension = fileType === 'csv' ? 'csv' : 'txt';

    const blob = new Blob([fileContent], { type: `${mimeType};charset=utf-8;` });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `sample-data.${fileExtension}`;
    downloadLink.click();
  };
  const downloadCSVSample = () => downloadSampleFile('csv', ',');
  const downloadTSVSample = () => downloadSampleFile('tsv', '\t');

  const handleSubmit = () => {
    userDataCache.reloadAll();
    if (typeof onSubmit === 'function') {
      onSubmit();
    }
  };

  const leftButtonHandleSubmit = () => {
    if (leftButtonOnSubmit) {
      leftButtonOnSubmit();
    }
    navigate(-1);
  };

  return (
    <Column>
      <ConfirmBulkAddModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        setProceedWithBulkAdd={setProceedWithBulkAdd}
        setClearUpload={setClearUpload}
      />
      {editing ? (
        <>
          <Row>
            <Typography style={{ flex: 4.5 }} color='secondary.main' variant='p20SemiBold'>
              Upload a CSV or tab-separated file to {mode} member information.
            </Typography>
            <Column
              style={{
                justifyContent: 'center',
                flex: 1,
              }}>
              <Tippy placement='right' content={<UploadHelpText />} theme='tippy-rounded'>
                <HelpOutline style={{ color: theme.palette.info.main }} />
              </Tippy>
            </Column>
          </Row>
          <Spacer height='xs' />
        </>
      ) : (
        <>
          <Row style={{ alignItems: 'center' }}>
            <Typography color='secondary.main' variant='p20SemiBold'>
              Upload a CSV or tab-separated file to {mode} member information.
            </Typography>
            <Spacer width='xxxs' />
            <Tippy placement='right' content={<UploadHelpText />} theme='tippy-rounded'>
              <HelpOutline style={{ color: theme.palette.info.main }} />
            </Tippy>
          </Row>
          <Spacer height='xs' />
        </>
      )}

      <Typography variant='p16Italic' color='info.main'>
        Feeling stuck? Download our sample template.
      </Typography>
      <Spacer height='xxs' />

      <Row>
        <Card variant='ghost' color='primary'>
          <CardContent className='basic'>
            <Row style={{ alignItems: 'center' }}>
              <Typography color='secondary.dark' variant='p16'>
                CSV template
              </Typography>
              <Spacer width='xxs' />
              <DownloadButton onDownload={downloadCSVSample} />
            </Row>
          </CardContent>
        </Card>

        <Spacer width='xs' />
        <Typography color='info.main' variant='p16Italic' style={{ alignSelf: 'center' }}>
          or
        </Typography>
        <Spacer width='xs' />

        <Card variant='ghost' color='primary'>
          <CardContent className='basic'>
            <Row style={{ alignItems: 'center' }}>
              <Typography color='secondary.dark' variant='p16'>
                tab-separated template
              </Typography>
              <Spacer width='xxs' />
              <DownloadButton onDownload={downloadTSVSample} />
            </Row>
          </CardContent>
        </Card>
      </Row>
      <Spacer height='sm' />

      <UploadDropZone
        triggerClearUpload={clearUpload}
        setTriggerClearUpload={setClearUpload}
        setData={setFile}
        uploadTypes={['tsv', 'csv', 'txt']}
      />
      <Spacer height='xs' />

      {validRowSize > 0 && (
        <>
          <Typography color='secondary.main' variant='p16'>
            Successfully processed <Bold>{validRowSize}</Bold> entries.
          </Typography>
          <Spacer height='xxs' />
        </>
      )}
      {persistedRowSize > 0 && (
        <Typography color='secondary.main' variant='p16'>
          Successfully {addMode ? 'added' : 'removed'} <Bold>{persistedRowSize}</Bold>{' '}
          {addMode ? 'new' : ''} members.
        </Typography>
      )}
      <Spacer height='sm' />

      {errored && invalidRowSize > 0 && (
        <>
          <Card variant='ghost' color='primary'>
            <CardContent className='basic'>
              <Row style={{ justifyContent: 'center' }}>
                <Row style={{ alignItems: 'center' }}>
                  <Svgs.IconWarning />
                  <Spacer width='xxs' />
                  <Typography color='error.dark'>
                    We couldn't save {invalidRowSize} {invalidRowSize === 1 ? 'entry' : 'entries'}.
                    Please correct {invalidRowSize === 1 ? 'it' : 'them'} and upload again.
                  </Typography>
                  <Spacer width='xxxs' />
                  <Tippy placement='right' content={<CommonErrorsHelpText />} theme='tippy-rounded'>
                    <HelpOutline style={{ color: theme.palette.info.main }} />
                  </Tippy>
                </Row>
              </Row>
            </CardContent>
          </Card>
          <Spacer height='xxs' />
          <DataTable
            headers={headers}
            sortBy='firstName'
            data={invalidRows}
            type={'member'}
            outlined
            hideCaret
            scrollable
          />
          <Spacer height='sm' />
        </>
      )}

      <Row style={{ justifyContent: 'space-between' }}>
        {leftButtonText && (
          <Button color='secondary' variant='outlined' onClick={leftButtonHandleSubmit}>
            {leftButtonText}
          </Button>
        )}
        {rightButtonText && (
          <Button color='primary' onClick={handleSubmit} disabled={disableButton ?? errored}>
            {rightButtonText}
          </Button>
        )}
      </Row>
    </Column>
  );
};

export const UploadHelpText = () => {
  return (
    <>
      <HelpTitle>Uploading a bulk file</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody variant='p16SemiBold'>Requirements</HelpBody>
      <Spacer height='xxxs' />
      <BulletedList
        children={[
          'Must be a CSV (.csv) or tab-separated file (.txt or .tsv) ',
          'Must have separate columns for “first name”, “last name”, and “email”',
          'Make sure that the first row of your file is column headers (see template)',
        ]}
      />
      <Spacer height='xs' />
      <HelpBody variant='p16SemiBold'>How to upload a file</HelpBody>
      <BulletedList
        children={[
          'Export a file from your HR platform with the required information above or create the file with Excel',
          'If using Excel, export the spreadsheet as a CSV or tab-separated file',
        ]}
      />
      <Spacer height='xxs' />
    </>
  );
};

export const CommonErrorsHelpText = () => {
  return (
    <>
      <HelpTitle>Common errors in file uploads</HelpTitle>
      <Spacer height='xxxs' />
      <BulletedList
        children={[
          'Invalid column headers',
          'More than one comma between columns',
          'More than one tab between columns',
          'Invalid row breaks',
          'Blank cells',
        ]}
      />
      <Spacer height='xxxs' />
    </>
  );
};

export default MembersFileUpload;
