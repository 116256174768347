import { inject } from '@aesop-fables/containr';
import { ISubject, DataCache, ScriniumServices } from '@aesop-fables/scrinium';
import type { IAppStorage } from '@aesop-fables/scrinium';
import { Observable, map } from 'rxjs';
import { OffboardingCompartments, offboardingStorageKey } from '..';
import {
  ToDoListData,
  MemberPaysToDoList,
  AdpToDoList,
  DefaultToDoList,
} from '../../../models/OffboardingData';
import { TemplateData } from '../../../models/SignUpData';
import { OffboardingTemplateIdEnum } from '../../../services/offboarding';

const getToDoList = (template: TemplateData | undefined): ToDoListData[] => {
  if (!template) return [];

  switch (template.id) {
    case OffboardingTemplateIdEnum.MemberPays:
      return MemberPaysToDoList;
    case OffboardingTemplateIdEnum.Adp:
      return AdpToDoList;
    default:
      return DefaultToDoList;
  }
};

export class OffboardingTemplateSubject implements ISubject<ToDoListData[]> {
  private readonly cache: DataCache<OffboardingCompartments>;

  constructor(@inject(ScriniumServices.AppStorage) private readonly storage: IAppStorage) {
    this.cache = this.storage.retrieve<OffboardingCompartments>(offboardingStorageKey);
  }

  createObservable(): Observable<ToDoListData[]> {
    return this.cache
      .observe$<TemplateData>('offboardingTemplate')
      .pipe(map(template => getToDoList(template)));
  }
}
