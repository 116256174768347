import { Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column, Row, Spacer, StepsHeader } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Form } from 'react-final-form';
import { OrganizationData } from '../../models/OrganizationData';
import { useDeviceType } from '../../hooks/useDeviceType';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { useService } from '@aesop-fables/containr-react';
import DeductionInfo from './DeductionInfo';
import { AddDeductions } from '../../data/deductions/mutations';
import { useContext } from 'react';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import {
  DeductionTypesEnum,
  getContributionTypeEnum,
} from '../../api/apis/CompanyPayrollDeductionApi';
import { mutateFieldValue } from '../../helpers/utilityFunctions';
import { CreateSignUpState } from '../../data/signUp/mutations';

const AdpOnboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const createSignUpState = useMutation(new CreateSignUpState());
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const addDeduction = useMutation(new AddDeductions());
  const { isMobile, isTablet } = useDeviceType();
  const { setToast } = useContext(ToastbarContext);
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);

  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const currentOrganization = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );

  // useEffect(() => {
  //   if (addDeduction?.error?.message.includes('400')) {
  //     setToast(
  //       new Toast({
  //         message: 'Please enter a unique deduction code',
  //         severity: 'error',
  //         open: true,
  //       }),
  //     );
  //     setLoading(false);
  //   }
  // }, [addDeduction?.error?.message, setLoading, setToast]);

  const onSubmit = async (values: any) => {
    let addedDeductions = [];
    for (let i = 0; values[`deductionName-${i}`] !== undefined; i++) {
      if (!values[`deductionType-${i}`]) {
        setToast(
          new Toast({ message: 'Please select a deduction type', severity: 'error', open: true }),
        );
        return;
      }
      const deductionName = values[`deductionName-${i}`];
      const deductionCode = values[`deductionCode-${i}`];
      const companyName = values[`companyName-${i}`];
      const companyCode = values[`companyCode-${i}`];
      const contributionType = values[`contributionType-${i}`];
      const deductionType = values[`deductionType-${i}`];
      const catchup = deductionType.includes('catch-up');
      const dedTypeKey = deductionType.replace(/ catch-up$/, '');

      addedDeductions.push({
        id: {
          organizationId: currentOrganization?.id!,
          companyCode: companyCode,
          deductionCode: deductionCode,
          gcCategory: DeductionTypesEnum[dedTypeKey as keyof typeof DeductionTypesEnum],
        },
        companyName: companyName,
        deductionName: deductionName,
        deductionType: getContributionTypeEnum(contributionType),
        catchup: catchup,
      });
    }
    await addDeduction.action(addedDeductions);
    await createSignUpState.action({ lastCompletedStep: signUpWizard.getCurrent(location) });
    const next = signUpWizard.nextStep(location);
    navigate(next.route);
  };

  return (
    <Form onSubmit={onSubmit} mutators={{ mutateFieldValue }}>
      {({ handleSubmit }) => (
        <Column
          style={{
            margin: isMobile || isTablet ? '0 20px' : '0px 100px',
          }}>
          <StepsHeader
            activeStep={activeStep}
            totalSteps={totalSteps}
            headerSteps='adpOnboarding'
          />
          <Spacer height='xs' />
          <Typography variant='h1' color='secondary'>
            Step {activeStep}: Payroll deduction info{' '}
            {currentOrganization && `for ${currentOrganization?.name}`}
          </Typography>
          <Spacer height='s15' />
          <Typography variant='body1' color='info.main'>
            To interpret the information we get from ADP, we need a little more context about
            payroll deductions in your organization.
          </Typography>
          <Column
            style={{
              justifyContent: isMobile || isTablet ? '' : 'center',
              width: '100%',
              alignSelf: 'center',
            }}>
            <Spacer height='sm' />
            <Row>
              <Typography variant='p18SemiBold' color='secondary.dark'>
                Add each available deduction type:
              </Typography>
            </Row>
            <Spacer height='xs' />
            <DeductionInfo />
            <Spacer height='xs' />
          </Column>
          <Row
            style={{
              justifyContent: 'flex-end',
            }}>
            <Button onClick={handleSubmit} color='primary' variant='tertiary'>
              Next: Restrict member access
            </Button>
          </Row>
        </Column>
      )}
    </Form>
  );
};

export default AdpOnboarding;
