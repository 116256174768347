import {
  ConfiguredDataSource,
  DataCompartment,
  DataCompartmentOptions,
  IAppStorage,
  createDataCache,
  createDataCacheModule,
} from '@aesop-fables/scrinium';
import type { IServiceContainer } from '@aesop-fables/containr';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { InvoiceApi, InvoiceData } from '../../api/apis/InvoiceApi';
import { OrganizationSelected } from '../../predicates/OrganizationSelected';
import { PaymentCompartments, paymentStorageKey } from '../payment';
import { ChargeData } from '../../api/apis/ChargeApi';

export interface InvoiceCompartments {
  invoices: DataCompartmentOptions<InvoiceData[] | undefined>;
}

export const invoiceStorageKey = 'data/invoices';

export const withInvoiceData = createDataCacheModule(
  (appStorage: IAppStorage, container: IServiceContainer) => {
    const cache = createDataCache<InvoiceCompartments>({
      invoices: {
        loadingOptions: { strategy: 'auto', predicate: container.resolve(OrganizationSelected) },
        source: new ConfiguredDataSource(async () => {
          const invoiceApi = container.get<InvoiceApi>(ApiKeys.Invoice);
          const { data } = await invoiceApi.get();

          const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);
          const paymentHistory = (
            paymentDataCache.findCompartment('paymentHistory') as DataCompartment<ChargeData[]>
          ).getData() as ChargeData[];

          if (!data) return;

          return data.map(invoice => {
            const matchingCharge = paymentHistory?.find(
              payment => payment.charge?.invoiceId === invoice.invoiceNumber,
            );
            const paidAtTimestamp = invoice.paidAtTimestamp || matchingCharge?.charge?.datePaid;

            return {
              ...invoice,
              paidAtTimestamp,
            } as InvoiceData;
          });
        }),
        defaultValue: undefined,
      },
    });

    appStorage.store(invoiceStorageKey, cache);
  },
);
