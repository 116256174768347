import { ResponseInterceptor } from '../Interceptor';
import { authContextKey } from '../../services/authentication';
import type { IAuthenticationContext } from '../../services/authentication';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';
import type { ApiSettings } from '../ApiSettings';
import { AxiosResponse } from 'axios';
import { autoLogoutKey, type IAutoLogoutService } from '../../services/logout';

export class AuthenticationResponseInterceptor implements ResponseInterceptor {
  constructor(
    @inject(ApiServices.ApiSettings) private readonly settings: ApiSettings,
    @inject(authContextKey) private readonly authContext: IAuthenticationContext,
    @inject(autoLogoutKey) private readonly autoLogoutService: IAutoLogoutService,
  ) {}

  async onFulfilled(value: AxiosResponse<any, any>): Promise<AxiosResponse<any, any>> {
    this.autoLogoutService.restartLogoutTimer();
    return value;
  }

  // TODO -- We should really put this behind an IAuthenticationFailureHandler so we can swap out the strategy
  async onRejected(error: any) {
    if (
      error.config &&
      error.response &&
      error.response.status === 401 &&
      typeof error.config.headers.useTempToken === 'undefined'
    ) {
      this.authContext.setIsAuthenticated(false);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
}
