import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../services/drillDown';
import { capitalize, lowerCase } from 'lodash';
import { formatDateMMDDYYYY } from '../helpers/utilityFunctions';
import { UserData } from '../models/UserData';
import { useObservable } from '@aesop-fables/scrinium';
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import Column from './Column';
import Searchbox from './Searchbox';
import Spacer from './Spacer';
import { useService } from '@aesop-fables/containr-react';
import { FilteredAdminData } from '../hooks/useAdminHooks';
import { sortAdminStatusThenDate, sortByTier } from '../helpers/sortUtils';
import DataTable from './DataTable/DataTable';
import { Toast } from '../models/Toast';
import { ToastbarContext } from '../App';
import { useCommands } from '../helpers/useCommands';
import {
  ResendGroupEmail,
  ResendOrgEmail,
  ResendTeamEmail,
} from '../data/admin/commands/ResendInvite';
import {
  RemoveGroupAdmin,
  RemoveOrgAdmin,
  RemoveTeamAdmin,
} from '../data/admin/commands/RemoveAdmin';
import Modal from './Modal';

interface AdminDataTableProps {
  role: AdminTierTypeEnum;
  usersList: FilteredAdminData[];
  currentUser: UserData | undefined;
  hideSearch?: boolean;
  loading?: boolean;
}

const headers = [
  { title: 'Name', label: 'name' },
  { title: 'Role', label: 'role' },
  { title: 'Email', label: 'email' },
  { title: 'Status', label: 'statusDisplay' },
  { title: '', label: '' },
];

const AdminDataTable: React.FC<AdminDataTableProps> = ({
  role,
  usersList,
  hideSearch,
  loading,
  currentUser,
}) => {
  const theme = useTheme();
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeData, setActiveData] = useState(undefined);

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);

  const [searchWord, setSearchWord] = useState('');
  const [filteredDataForTable, setFilteredDataForTable] = useState<FilteredAdminData[]>([]);

  const primaryBillingAdmin = useMemo(() => {
    return usersList.find(admin => admin.primaryBillingUser);
  }, [usersList]);

  useEffect(() => {
    if (!searchWord) {
      setFilteredDataForTable(usersList);
      return;
    }
    setFilteredDataForTable(
      usersList?.filter((item: any) =>
        Object.values(item).some(
          (val: any) => val?.toString().toLowerCase().includes(searchWord.toLowerCase()),
        ),
      ),
    );
  }, [searchWord, usersList]);

  const handleAdminRemoval = async (row: any) => {
    try {
      switch (tier) {
        case AdminTierTypeEnum.Team:
          await commands.execute(RemoveGroupAdmin, row.id);
          break;
        case AdminTierTypeEnum.Organization:
          await commands.execute(RemoveTeamAdmin, row.id);
          break;
        default:
          await commands.execute(RemoveOrgAdmin, row.id);
          break;
      }

      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Admin removed!',
        }),
      );
    } catch (err) {
      console.error('Error removing admin', err);
    }
  };

  const handleAdminResend = async (row: any) => {
    try {
      switch (tier) {
        case AdminTierTypeEnum.Team:
          await commands.execute(ResendGroupEmail, row.id);
          break;
        case AdminTierTypeEnum.Organization:
          await commands.execute(ResendTeamEmail, row.id);
          break;
        default:
          await commands.execute(ResendOrgEmail, row.id);
          break;
      }

      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Admin invite sent!',
        }),
      );
    } catch (err) {
      console.error('Error resending admin invite', err);
    }
  };

  const showRemovalFn = (x: { id: number; role: string; status: string }) => {
    const lowerLevelAdmin =
      tier === AdminTierTypeEnum.Root
        ? x.role.includes('Organization')
        : tier === AdminTierTypeEnum.Organization
        ? x.role.includes('Team') || x.role.includes('Organization')
        : true;

    return x.id !== currentUser?.id && x.id !== primaryBillingAdmin?.id && lowerLevelAdmin;
  };

  const showResendFn = (x: { id: number; role: string; status: string }) => {
    if (!x.status.includes('Invited')) return false;
    const lowerLevelAdmin =
      tier === AdminTierTypeEnum.Root
        ? x.role.includes('Organization')
        : tier === AdminTierTypeEnum.Organization
        ? x.role.includes('Team') || x.role.includes('Organization')
        : true;
    return lowerLevelAdmin;
  };

  const onRemovePress = (row: any) => {
    setModalOpen(true);
    setActiveData(row);
  };

  return (
    <>
      <AdminDeleteModal
        onSave={handleAdminRemoval}
        activeData={activeData}
        role={role}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      {!hideSearch && (
        <Card variant='ghost' color='primary'>
          <CardContent className='searchbox'>
            <Column>
              <Searchbox
                callback={value => setSearchWord(value)}
                label='Search'
                placeholder='Search by admin name'
              />
            </Column>
          </CardContent>
        </Card>
      )}

      <Spacer height='sm' />
      <DataTable
        role={role}
        data={
          filteredDataForTable?.map(admin => {
            return {
              id: admin.id,
              name:
                admin.id === currentUser?.id
                  ? `${admin.firstName} ${admin.lastName} (You)`
                  : `${admin.firstName} ${admin.lastName}`,
              role: admin.roles.map(role => capitalize(role.replace(/_/g, ' '))).join(', '),
              email: admin.email,
              status: `${capitalize(admin.status)} as of ${formatDateMMDDYYYY(
                admin.statusTimestamp ?? '',
              )}`,
              statusDisplay: (
                <Grid>
                  <Typography variant='p16'>{capitalize(admin.status)}</Typography>
                  <Typography variant='body2' color='secondary'>
                    {formatDateMMDDYYYY(admin.statusTimestamp ?? '')}
                  </Typography>
                </Grid>
              ),
              isPinned: admin.id === currentUser?.id,
            };
          }) ?? []
        }
        headers={headers}
        sortBy='role'
        customSort={[
          {
            column: 'role',
            function: (a, b) => sortByTier(a.role, b.role),
          },
          {
            column: 'statusDisplay',
            function: sortAdminStatusThenDate,
          },
        ]}
        type='admins'
        emptyStateText={`You don't have any other admins ${
          role === AdminTierTypeEnum.Team ? 'on' : 'in'
        } your ${lowerCase(role)}.`}
        filtered={searchWord.length > 0}
        ellipsesItems={[
          {
            label: 'Re-invite',
            onClick: handleAdminResend,
            showFn: showResendFn,
          },
          {
            label: 'Remove',
            onClick: onRemovePress,
            showFn: showRemovalFn,
            sx: { color: theme.palette.error.dark },
          },
        ]}
        lastColumnElement='ellipsis'
        lastColumnStyles={{ justifyContent: 'center' }}
        loading={loading}
      />
    </>
  );
};

interface DeleteModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (row: any) => void;
  activeData: any;
  role: AdminTierTypeEnum;
}
const AdminDeleteModal: React.FC<DeleteModalProps> = ({
  open,
  setOpen,
  onSave,
  activeData,
  role,
}) => {
  return (
    <Modal
      title={'Remove admin?'}
      handleSave={() => onSave(activeData)}
      open={open}
      setOpen={setOpen}
      primaryButtonText={'Yes, Remove'}
      secondaryButtonText={'No, Keep'}>
      <Typography variant='p14' color='secondary.main' gutterBottom>
        Are you sure you want to remove {activeData?.name} as an admin?
      </Typography>
      <Spacer height='sm' />
      <Typography variant='p14' color='secondary.main' gutterBottom>
        This will remove any association between their account and your {role?.toLowerCase()}.
      </Typography>
      <Spacer height='sm' />
    </Modal>
  );
};

export default AdminDataTable;
